// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  InputBase,
  Typography,
  styled,
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import { createTheme } from "@material-ui/core/styles";
import {
  imgAccess,
  imgDecline,
} from "./assets";
const config = require("./config")

import UpdatePasswordController, {
  Props,
} from "./UpdatePasswordController";
import ForgotPasswordUIContainer from "./ForgotPasswordUIContainer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

class UpdatePassword extends UpdatePasswordController {
  constructor(props: Props) {
    super(props);
  }

  getStyle = (errorType: boolean) => {
    return {
      marginBottom: errorType ? 0 : "",
      border: errorType ? "1px solid #F87171" : "",
    };
  };

  getVisibilityImg = (show: boolean) => {
    return !show ? <VisibilityOff /> : <Visibility />;
  };

  getImage = (type: boolean) => {
    return !type ? imgDecline : imgAccess;
  };

  errorToast = () => {
    if (!this.state.errorMessage && !this.state.invalidPassword && !this.state.invalidReTypePassword && !this.state.resetSuccessMsg) {
      return null;
    }

    let content: any;
    let mofifyClass: any;
    if (this.state.password === '' && this.state.reTypePassword === '') {
      content = 'Please fill your new password'
      mofifyClass = 'error';
    } else if (this.state.errorMessage) {
      content = this.state.errorMessage;
      mofifyClass = 'error';
    } else if (this.state.resetSuccessMsg) {
      content = this.state.resetSuccessMsg;
      mofifyClass = 'success'
    } else {
      return null;
    }

    return (
      <Box sx={{ width: "100%" }} className={`msgBg ${mofifyClass}`}>
        <Box className={`msgPipe ${mofifyClass}`}></Box>
        <Typography data-test="error-msg" className={`msgText ${mofifyClass}`} style={{ marginTop: 0, padding: "12px 16px" }}>
          {content}
        </Typography>
      </Box>
    )
  }
  render() {
    return (
      <ForgotPasswordUIContainer>
        <VerifyEmailContainer>
          <Box className="contentWrapper">
            <Typography className="labelTitle">
              {config.title2}
            </Typography>

            <Typography className="instructionText3">
              {config.instructionText3} {this.state.emailValue}
            </Typography>

            {this.errorToast()}

            <InputLabel style={{ fontFamily: "Arial" }}>{config.newPwLabel}</InputLabel>
            <StyleInput
              type={!this.state.enablePasswordField ? "text" : "password"}
              placeholder={"Your new password"}
              fullWidth={true}
              data-test="txtInputPassword"
              value={this.state.password}
              onChange={(e) =>
                this.txtInputPasswordProps.onChangeText(e.target.value)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    data-test="toggle-show-hide-pw"
                    onClick={this.btnPasswordShowHideProps.onPress}
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    {this.getVisibilityImg(
                      this.state.enablePasswordField
                    )}
                  </IconButton>
                </InputAdornment>
              }
              style={this.getStyle(this.state.invalidPassword)}
            />
            <InputLabel style={{ fontFamily: "Arial" }}>{config.confirmNewPwLabel}</InputLabel>
            <StyleInput
              data-test="txtInputConfirmPassword"
              type={this.state.enablePasswordField ? "password" : "text"}
              placeholder={"Confirm your new password"}
              fullWidth={true}
              value={this.state.reTypePassword}
              onChange={(e) =>
                this.txtInputConfirmPasswordProps.onChangeText(
                  e.target.value
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.btnPasswordShowHideProps.onPress}
                    edge="end"
                  >
                    {this.getVisibilityImg(
                      this.state.enablePasswordField
                    )}
                  </IconButton>
                </InputAdornment>
              }
              style={{...this.getStyle(this.state.invalidReTypePassword), marginBottom: 0}}
            />
            {this.state.password !== '' && this.state.invalidReTypePassword && (
              <Box
                sx={{
                  color: "#DC2626",
                  fontSize: "12px",
                  lineHeight: "18px",
                  marginBottom: "16px",
                }}
              >
                {config.passwordNotMatch}
              </Box>
            )}
            <Box className="pwRules">
              {this.state.password && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                      src={this.getImage(
                        !this.state.invalidCapitalLetter
                      )}
                      alt="icon"
                    />
                    {config.capitalLetter}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                      src={this.getImage(
                        !this.state.invalidLowercaseLetter
                      )}
                      alt="icon"
                    />
                    {config.lowercaseLetter}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                      src={this.getImage(!this.state.invalidNumber)}
                      alt="icon"
                    />
                    {config.oneNumber}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                      src={this.getImage(!this.state.invalidLength)}
                      alt="icon"
                    />
                    {config.minimumChar}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "6px",
                      }}
                      src={this.getImage(!this.state.invalidSym)}
                      alt="icon"
                    />
                    {config.oneSym}
                  </Box>
                </>
              )}
            </Box>

            <SetNewPwBtn
              data-test={"reset-btn"}
              onClick={this.onSubmitReset}
              disabled={this.state.isLoading}
            >
              {config.setNewPwBtn}
            </SetNewPwBtn>

            <Box className="linksGroup">
              <Box sx={{ display: "flex" }}>
                <Typography className="normalText" style={{ marginRight: 10 }}>
                  {config.backTo}
                </Typography>
                <Typography
                  data-test="go-to-login"
                  onClick={this.gotoLogIn}
                  className="normalText"
                  style={{
                    cursor: "pointer",
                    fontWeight: 700, color: config.colorPrimary
                  }}>
                  {config.logIn}
                </Typography>
              </Box>
            </Box>
          </Box>
        </VerifyEmailContainer>
      </ForgotPasswordUIContainer>
    );
  }
}

const InputLabel = styled(Typography)({
  marginBottom: 4,
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 700,
  fontFamily: "Arial, sans-serif !important",
  color: "#334155",
});

const StyleInput = styled(InputBase)({
  width: "100%",
  height: "56px",
  marginBottom: 16,
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  padding: "0 8px",
  fontSize: 16,
  fontFamily: "Arial",
  lineHeight: "24px",
  fontWeight: 400,
  color: "#0F172A",
  "&:before": {
    borderBottom: "none",
    outline: "none",
  },
  "&:focus, &:focus-within": {
    borderColor: "#6200EA",
    boxShadow: "0 0 0 3px #E1CCFF",
  },
  '& input[type="password"]': {
    fontSize: 8,
    "&::placeholder": {
      fontSize: 16,
      color: "#94A3B8",
    },
  },
  '& input[type="text"]': {
    "&::placeholder": {
      fontSize: 16,
      color: "#94A3B8",
    },
  },
});

const SetNewPwBtn = styled(Button)({
  marginTop: 40,
  marginBottom: 40,
  width: "100%",
  height: 56,
  backgroundColor: "#004E9E",
  borderRadius: "6px",
  fontFamily: "Arial, sans-serif",
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 700,
  color: "#ffffff",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#4B7FC2",
  },
  "&:disabled": {
    backgroundColor: "#A9A9A9",
    color: "#ffffff",
    cursor: "not-allowed",
  },
});

const VerifyEmailContainer = styled(Box)(({ theme }) => ({
  '& .contentWrapper': {
    marginTop: 120,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    margin: "0 auto",
    width: "50%",

    [theme.breakpoints.down(960)]: {
      top: 0,
      transform: "none",
      marginBottom: 20
    }
  },
  '& .labelTitle': {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 40,
    fontWeight: 700,
    lineHeight: "32px",
  },
  '& .instructionText3': {
    alignSelf: "start",
    fontSize: 16,
    color: "#0F172A",
    fontWeight: 400,
    lineHeight: "24px", /* 150% */
    marginBottom: 40,
  },
  '& .linksGroup': {
    width: "100%",
    display: "flex",
  },
  '& .normalText': {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "22px"
  },
  '& .pwRules': {
    marginTop: 32,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  '& .msgBg': {
    marginTop: 10, position: "relative", borderRadius: 8,
    '&.error': {
      backgroundColor: "#FEE2E2",
    },
    '&.success': {
      backgroundColor: "#E2E5FE",
    },
  },
  '& .msgPipe': {
    width: 5, height: "100%", position: "absolute", borderRadius: "8px 0 0 8px",
    '&.error': {
      background: config.colorError
    },
    '&.success': {
      background: config.colorPrimary
    },
  },
  '& .msgText': {
    fontSize: 12,
    marginTop: 5,
    '&.error': {
      color: config.colorError,
    },
    '&.success': {
      color: config.colorPrimary,
    },
  },

}))

export default UpdatePassword;
// Customizable Area End
