import React from "react";
import {
  Box,
  Typography,
  styled,
} from "@material-ui/core";

const ToastWrapper = styled(Box)(({ theme }) => ({
  '& .msgPipe': {
    position: "absolute",
    width: 5,
    height: "100%",
    borderRadius: "8px 0 0 8px",
    '&.error': {
      background: "#DC2626",
    },
    '&.success': {
      background: "#004E9E",
    },
  },
  '& .msgBg': {
    borderRadius: 8,
    marginTop: 10,
    position: "relative",
    marginBottom: 10,
    '&.error': {
      backgroundColor: "#FEE2E2",
    },
    '&.success': {
      backgroundColor: "#E2E5FE",
    },
  },
  '& .msgText': {
    fontSize: 12,
    '&.error': {
      color: "#DC2626",
    },
    '&.success': {
      color: "#004E9E",
    },
    marginTop: 5,
  },
}));

const Toast = ({ content, modifyClass }: {content: string; modifyClass: string;}) => {
  return (
    <ToastWrapper>
      <Box
        sx={{ width: "100%" }}
        className={`msgBg ${modifyClass}`}
      >
        <Box className={`msgPipe ${modifyClass}`}></Box>
        <Typography
          data-test="error-msg"
          style={{ marginTop: 0, padding: "12px 16px" }}
          className={`msgText ${modifyClass}`}
        >
          {content}
        </Typography>
      </Box>
    </ToastWrapper>
  );
}

export default Toast;