// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Input,
  Typography,
  styled,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
const config = require("./config")

import ResetPasswordController, {
  Props,
} from "./ResetPasswordController";
import ForgotPasswordUIContainer from "./ForgotPasswordUIContainer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
  }

  errorToast = () => {
    return this.state.emailError &&
      <Box sx={{ width: "100%" }} style={{ backgroundColor: "#FEE2E2", marginTop: 10, position: "relative", borderRadius: 8 }}>
        <Box style={{ width: 5, height: "100%", background: config.colorError, position: "absolute", borderRadius: "8px 0 0 8px" }}></Box>
        <Typography data-test="error-msg" className="errorText" style={{ marginTop: 0, padding: "12px 16px" }}>
          {this.state.errorMessage}
        </Typography>
      </Box>
  }
  render() {
    return (
      <ForgotPasswordUIContainer>
        <ResetPasswordContainer>
          <Box className="contentWrapper">
            <Typography className="labelTitle">
              {config.title}
            </Typography>

            <Typography className="subTitle">
              {config.subTitle}
            </Typography>

            <Typography className="instructionText">
              {config.instructionText}
            </Typography>

            {this.errorToast()}

            <Box sx={{ width: "100%" }}>
              <Box sx={{ padding: "20px 0px" }}>
                <Typography className="formLabel">{config.firstInputPlaceholder}</Typography>
                <Input
                  data-test="input-email"
                  placeholder={"Tu correo electrónico"}
                  fullWidth={true}
                  value={this.state.emailValue}
                  onChange={(e) => { this.handleChangeEmail(e.target.value) }}
                  className="inputStyle"
                  disableUnderline
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                  marginBottom: 40
                }}
              >
                <Button
                  data-test={"email-send-link-btn"}
                  variant="contained"
                  className="btnEmailSendLink"
                  fullWidth
                  onClick={() => this.emailSendLink()}
                >
                  {config.btnEmailSendLink}
                </Button>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Typography className="normalText" style={{ marginRight: 10 }}>
                  {config.backTo}
                </Typography>
                <Typography
                  data-test="go-to-login"
                  onClick={this.gotoLogIn}
                  className="normalText"
                  style={{
                    cursor: "pointer",
                    fontWeight: 700, color: config.colorPrimary
                  }}>
                  {config.logIn}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ResetPasswordContainer>
      </ForgotPasswordUIContainer>
    );
  }
}

const ResetPasswordContainer = styled(Box)(({ theme }) => ({
  '& .contentWrapper': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0px",
    margin: "0 auto",
    marginTop: 120,
    width: "50%",
    position: "relative",

    [theme.breakpoints.down(960)]: {
      top: 0,
      transform: "none",
      marginBottom: 20
    }
  },
  '& .labelTitle': {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: 40
  },
  '& .subTitle': {
    alignSelf: "start",
    color: "#0F172A",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "26px", /* 144.444% */
    marginBottom: 8,
  },
  '& .instructionText': {
    alignSelf: "start",
    color: "#0F172A",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px", /* 150% */
    marginBottom: 32,
  },
  '& .formLabel': {
    fontFamily: "Arial, sans-serif",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: 16,
    color: "#334155",
  },
  '& .normalText': {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "22px"
  },
  '& .btnEmailSendLink': {
    backgroundColor: config.colorPrimary,
    padding: 16,
    borderRadius: 8,
    textTransform: "none",
    fontWeight: 700,
    color: "#fff",
    fontSize: 16
  },
  '& .inputStyle': {
    border: "1px solid #CBD5E1",
    padding: 10,
    borderRadius: 8,
    marginTop: 10
  },
  '& .errorText': {
    color: config.colorError,
    fontSize: 12,
    marginTop: 5
  }
}))
// Customizable Area End
