import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Grid,
  Typography,
  Checkbox
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { imgDefaultProduct, imgSuccess } from "./assets";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CloseIcon from '@material-ui/icons/Close';
import FooterWeb from "../../../components/src/Footer.web";
const config = require("./config")

const styles = {
  pe0: {
    paddingRight: 0
  },
  ps0: {
    paddingLeft: 0
  },
  container: {
    display: "flex",
  },
  labelTitle: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "40px",
    fontFamily: 'Arial, sans-serif'
  },
  remove: {
    fontSize: '16px',
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: 'Arial, sans-serif'
  },
  checkBox: {
    padding: 0,
    color: '#000000',
    width: '20px',
    height: '20px',
  },
  cardFont: {
    fontSize: "14px", 
    lineHeight: '22px', 
    fontWeight: 700, 
    fontFamily: "Arial",
  },
  labelTotal: {
    fontSize: "24px", 
    lineHeight: '32px', 
    fontWeight: 700, 
    fontFamily: "Arial",
    marginBottom: "30px"
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: config.colorWithe,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: "column"
  },
  labelCheckout: {
    fontSize: '16px',
    fontFamily: 'Arial',
    fontWeight: 700,
    lineHeight: '24px',
    marginLeft: '10px'
  },
}

const ButtonCounter = styled(Button)({
  width: "44px",
  height: '44px',
  borderRadius: '8px',
  fontSize: '14px',
  fontFamily: 'Arial, sans-serif',
  color: config.colorWithe,
  backgroundColor: config.bluePrimary,
  padding: '0',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: 'pointer',
  minWidth: '44px',
  "&:hover": {
    opacity: '0.8',
    backgroundColor: config.bluePrimary,
  }
})

const ButtonRemove = styled(Button)({
  width: "134px",
  height: '56px',
  borderRadius: '8px',
  fontSize: '16px',
  fontFamily: 'Arial',
  color: config.colorWithe,
  backgroundColor: config.bluePrimary,
  padding: '0',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: 'pointer',
  minWidth: '44px',
  marginBottom: '100px',
  textTransform: 'none',
  "&:hover": {
    opacity: '0.8',
    backgroundColor: config.bluePrimary,
  },
  "&:disabled": {
    backgroundColor: config.buttonBluDisable,
    color: config.colorWithe,
    opacity: '0.6',
  }
})

const ButtonCheckout = styled(Button)({
  width: "362px",
  height: '56px',
  borderRadius: '8px',
  fontSize: '16px',
  fontFamily: 'Arial',
  color: config.colorWithe,
  backgroundColor: config.bluePrimary,
  padding: '0',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: 'pointer',
  minWidth: '44px',
  textTransform: 'none',
  "&:hover": {
    opacity: '0.8',
    backgroundColor: config.bluePrimary,
  },
  "@media (max-width: 767px)": {
    width: '100%',
    fontSize: '14px'  
  },
})

const ButtonClose = styled(Button)({
  width: "14px",
  height: '14px',
  color: config.colorWithe,
  backgroundColor: "transparent",
  padding: '0',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  minWidth: '14px',
  textTransform: 'none',
  "&:hover": {
    opacity: '0.8',
    backgroundColor: "transparent",
  },
})

const ButtonKeep = styled(Button)({
  borderRadius: '8px',
  fontSize: '16px',
  fontFamily: 'Arial, sans-serif',
  lineHeight: "24px",
  fontWeight: 700,
  color: config.colorWithe,
  backgroundColor: config.buttonKeep,
  padding: '16px',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: 'pointer',
  minWidth: '126px',
  textTransform: 'none',
  "&:hover": {
    opacity: '0.8',
    backgroundColor: config.buttonKeep,
  },
})

const ButtonRemoveModal = styled(Button)({
  borderRadius: '8px',
  fontSize: '16px',
  fontFamily: 'Arial, sans-serif',
  lineHeight: "24px",
  fontWeight: 700,
  color: config.colorWithe,
  backgroundColor: config.bluePrimary,
  padding: '16px',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: 'pointer',
  minWidth: '44px',
  textTransform: 'none',
  marginLeft: '24px',
  "&:hover": {
    opacity: '0.8',
    backgroundColor: config.bluePrimary,
  },
})

const ButtonOkModal = styled(Button)({
  width: "120px",
  height: "56px",
  borderRadius: '8px',
  fontSize: '16px',
  fontFamily: 'Arial, sans-serif',
  lineHeight: "24px",
  fontWeight: 700,
  color: config.colorWithe,
  backgroundColor: config.bluePrimary,
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: 'pointer',
  minWidth: '44px',
  textTransform: 'none',
  "&:hover": {
    opacity: '0.8',
    backgroundColor: config.bluePrimary,
  },
});

const CardItem = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: 'center',
  marginBottom: '40px'
});

const Card = styled(Box)({
  backgroundColor: config.backgroundCard, 
  width: '100%',
  padding: '40px 50px 40px 40px',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: '23px',
  "@media (max-width: 960px)": {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  "@media (max-width: 767px)": {
    marginLeft: '8px',
    padding: '16px',  
  },
});

const CardContent = styled(Box)({
  display: "flex",
  marginRight: '15px',
  "@media (max-width: 960px)": {
    marginBottom: '20px',
  },
  "@media (max-width: 767px)": {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '0'
  },
});

const TextCardContent = styled(Box)({
  "@media (max-width: 767px)": {
    marginTop: '23px'
  },
});

const MainContainer = styled(Container)({
  flex: 1,
  display: "flex",
  backgroundColor: config.backgroundColor,
  paddingRight: "100px",
  paddingLeft: "40px",
  paddingBottom: '213px',
  "@media (max-width: 960px)": {
    paddingRight: "30px",
    paddingLeft: "20px",
  },
  "@media (max-width: 767px)": {
    paddingRight: "20px",
    paddingLeft: "20px",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
  ShoppingCartOrder,
  ShoppingCartOrderItem,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu id="setting-navigation-menu" navigation={this.props.navigation} />
        <MainContainer maxWidth={false}>
          <Box
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box sx={{paddingTop: '70px', marginBottom: '48px'}}>
                <Typography style={styles.labelTitle} data-test-id="labelTitle">
                  {this.labelTitle}
                </Typography>
              </Box>
              <Grid
                spacing={2} 
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{marginBottom: '72px'}}
              >
                {this.state.itemCard.map((item, index) => (
                  <CardItem 
                    key={[item.id, index].join("_")}
                  >
                    <Box>
                      <Checkbox
                        data-test-id="btnChecked"
                        color="primary"
                        onClick={() =>
                          this.handleSelectedItem(item.id)
                        }
                        checked={this.state.selectedItems.findIndex((el) => el === item.id) !== -1}
                        inputProps={{ color: "transparent" }}
                        style={{color: '#000000'}}
                      />{" "}
                    </Box>
                    <Card>
                      <CardContent>
                        <img src={item.attributes.productdescription.data.attributes.images[0].url || imgDefaultProduct} alt="" style={{marginRight: "32px", width: "130", height: '160px', }} />
                        <TextCardContent>
                          <Typography style={{marginBottom: "8px", ...styles.cardFont}}>
                              {item.attributes.productdescription.data.attributes.name}
                          </Typography>
                          <Typography style={{marginBottom: "8px", ...styles.cardFont, color: config.colorRemove}}>
                              {item.attributes.productdescription.data.attributes.brand}
                          </Typography>
                          <Typography style={{marginBottom: "8px", ...styles.cardFont, color: config.colorRemove, fontWeight: 400, maxWidth: '600px', textTransform: 'capitalize'}}>
                              {`${config.description}: ${item.attributes.productdescription.data.attributes.description}`}
                          </Typography>
                          <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "284px", padding: "4px 0"}}>
                            <Typography style={{...styles.cardFont}}>
                                {config.quantity}
                            </Typography>
                            <Box style={{display: 'flex', alignItems: 'center'}}>
                              <ButtonCounter
                                data-test-id={"btn-plus"}
                                onClick={()=> this.handleCountDecrease(item.id)}
                              >
                                <RemoveIcon />
                              </ButtonCounter>
                              <Typography style={{...styles.cardFont, margin: '0 11px'}}>
                                {this.formattedQuantity(item.attributes.quantity)}
                              </Typography>
                              <ButtonCounter
                                data-test-id={"btn-minus"}
                                onClick={()=> this.handleCountIncrease(item.id)}
                              >
                                <AddIcon />
                              </ButtonCounter>
                            </Box>
                          </Box>
                        </TextCardContent>
                      </CardContent>
                      <Typography 
                          data-test-id="btnRemoveItem"
                          onClick={()=> this.handleRemoveItem(item.id)} 
                          style={{
                          ...styles.remove,
                          cursor: "pointer",
                          color: config.colorRemove
                        }}>
                          {config.remove}
                        </Typography>
                    </Card> 
                  </CardItem>
                ))}
              </Grid>
              <Box>
                <ButtonRemove 
                  data-test-id={"btn-remove-bulk"} 
                  disabled={this.state.selectedItems.length < 1}
                  onClick={()=> this.handleRemoveAllSelectedItems()}
                  >
                  {config.buttonRemoveLabel}
                </ButtonRemove>
                <Typography style={styles.labelTotal}>
                  {`${config.labelTotal}${this.formattedQuantity(this.state.itemCard.length)}`}
                </Typography>
                <ButtonCheckout onClick={() => this.goShipmentAddress()} data-test-id={"btn-checkoutBtn"}>
                  <ShoppingCartIcon />
                  <Typography style={styles.labelCheckout}>
                    {config.labelCheckoutBtn}
                  </Typography>
                </ButtonCheckout>
              </Box>
          </Box>
          <Modal
            data-test-id="btnModal"
            open={this.state.isVisible}
            onClose={this.hideModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{...styles.modalStyle}}>
              <Box style={{padding: "16px 16px 0", display: "flex", justifyContent: "flex-end"}}>
                <ButtonClose 
                  data-test-id="btnClose"
                  onClick={() => this.hideModal()}>
                  <CloseIcon
                   style={{ color: "#334155" }}
                  />
                </ButtonClose>
              </Box>
              <Box style={{padding: "12px 40px 24px", borderBottom: '1px solid #E2E8F0'}}>
                <Typography 
                  data-test-id="modalLabel"
                  style={{
                    fontSize: "24px", 
                    lineHeight: '32px', 
                    fontWeight: 700, 
                    fontFamily: "Arial, sans-serif",
                    maxWidth: "520px",
                  }}>
                  {config.modalLabel}
                </Typography>
              </Box>
              <Box 
                style={{display: "flex", justifyContent: 'flex-end', padding: "24px"}}>
                  <ButtonKeep onClick={() => this.hideModal()}  data-test-id="btnKeep">
                    {config.modalBtnLabelKeep}
                  </ButtonKeep>
                  <ButtonRemoveModal 
                    data-test-id="btnRemoveModal"
                    onClick={() => this.handleConfirmRemoveItem()}>
                    {config.modalBtnLabelRemove}
                  </ButtonRemoveModal>
              </Box>
            </Box>
          </Modal>
          <Modal
            data-test-id="modalSuccess"
            open={this.state.isVisibleSuccess}
            onClose={this.hideSuccesModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{...styles.modalStyle}}>
              <Box style={{borderBottom: '1px solid #E2E8F0', display: 'flex', flexDirection: "column", alignItems: "center", padding: "60px 40px 24px"}}>
                <img src={imgSuccess} alt="" style={{width: "56px", height: '56px', marginBottom: "24px"}} />
                <Typography
                  data-test-id="labelSuccess"
                  style={{
                    fontSize: "24px", 
                    lineHeight: '32px', 
                    fontWeight: 700, 
                    fontFamily: "Arial, sans-serif",
                    maxWidth: "520px",
                    textAlign: "center"
                  }}>
                  {config.successRemove}
                </Typography>
              </Box>
              <Box style={{display: "flex", justifyContent: 'flex-end', padding: "24px"}}>
                  <ButtonOkModal
                    data-test-id="btnModalOk"
                    onClick={() => this.hideSuccesModal()}
                    >
                      {config.labelOkButton}
                  </ButtonOkModal>
              </Box>
            </Box>
          </Modal>
        </MainContainer>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
