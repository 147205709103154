// Customizable Area Start
import React from "react";
import { addIcon, subtractIcon, cartIcon } from "./assets";
import {
  Box,
  Typography,
  styled,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import OrderReviewController, {
  Props,
} from "./OrderReviewController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { truncateTextToTenWords, ConfirmModal } from "../../../components/src/utils";
import FooterWeb from "../../../components/src/Footer.web";
import { ThemeProvider } from "react-native-elements";

const config = require("./config");

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
})

const OrderReviewWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  margin: '0 auto',
  width: '100%',
  maxWidth: 1440,
  padding: '70px 40px 175px 40px',
  [theme.breakpoints.down('md')]: {
    padding: '50px 25px 135px 25px',
  },
  '& button': {
    cursor: 'pointer !important',
    '&:hover': {
      cursor: 'pointer !important',
    }
  },
  '& .btn': {
    border: 0,
    cursor: 'pointer',
  },
  '& .review-section': {
    marginBottom: 67,
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  '& .title': {
    color: '#0F172A',
    fontFamily: "Arial, sans-serif",
    '&.main-title': {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: "40px",
      letterSpacing: "-0.15px",
      marginBottom: 48,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        marginBottom: 26,
      },
    },
    '&.sub-title': {
      fontFamily: "'Arial', sans-serif",
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
  },
  '& .product-wrapper': {
    padding: 40,
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 20,
      alignItems: 'start',
      gap: 18,
    },
  },
  '& .product-photo': {
    width: "160px",
    height: "160px",
    backgroundRepeat: " no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    marginRight: 32,
    backgroundColor: '#e6e6e6',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  '& .product-details': {
    flex: 1,
    alignSelf: "start"
  },
  '& .product-name': {
    color: "#0F172A",
    fontFamily: "'Arial', sans-serif",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: 8,
  },
  '& .product-type': {
    color: "#64748B",
    fontFamily: "'Arial', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px",
    marginBottom: 8,
  },
  '& .product-description': {
    color: "#64748B",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "22px",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 12,
  },
  '& .product-volume': {
    fontFamily: "'Arial', sans-serif",
    color: "#0F172A",
    fontSize: 14,
    lineHeight: "22px",
    fontWeight: 400,
    marginBottom: 12,
  },
  '& .quantity-container': {
    fontFamily: "'Arial', sans-serif",
    color: "#0F172A",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "22px",
    display: 'flex',
    gap: 78,
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      gap: 50,
    },
  },
  '& .quantity-text': {

  },
  '& .quantity-panel': {
    display: 'flex',
    alignItems: 'center',
    gap: 13,
  },
  '& .quantity-btn': {
    padding: 10,
    backgroundColor: "#004E9E",
    borderRadius: "8px",
  },
  '& .quantity-number': {

  },
  '& .remove-btn': {
    background: "none",
    color: "#64748B",
    fontFamily: "Arial, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
  },
  '& .address-section': {
    marginBottom: 67,
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  '& .change-address-btn': {
    color: "#004E9E",
    fontFamily: "Arial, sans-serif",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    background: "none",
  },
  '& .address-detail': {
    padding: '12px 16px',
    fontFamily: "'Arial', sans-serif",
    fontSize: 16,
    color: '#0F172A',
    fontWeight: 400,
    lineHeight: "24px",
    backgroundColor: '#fff',
  },
  '& .total-section': {
    marginBottom: 67,
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  '& .go-to-checkout-btn': {
    borderRadius: 8,
    backgroundColor: "#004E9E",
    fontFamily: "Arial, sans-serif",
    fontSize: 16,
    color: '#fff',
    fontWeight: 700,
    padding: '16px 85px',
    lineHeight: "24px",
    [theme.breakpoints.down('md')]: {
      padding: '16px 55px',
      fontSize: 13,
    },
  },
  '& .msgPipe': {
    width: 5, height: "100%", position: "absolute", borderRadius: "8px 0 0 8px",
    '&.error': {
      background: config.colorError
    },
    '&.success': {
      background: config.colorPrimary
    },
  },
  '& .msgBg': {
    marginTop: 10, 
    position: "relative", 
    marginBottom: 10,
    borderRadius: 8,
    '&.error': {
      backgroundColor: "#FEE2E2",
    },
    '&.success': {
      backgroundColor: "#E2E5FE",
    },
  },
  '& .msgText': {
    fontSize: 12,
    '&.error': {
      color: config.colorError,
    },
    '&.success': {
      color: config.colorPrimary,
    },
    marginTop: 5,
  },
}));

export default class OrderReview extends OrderReviewController {
  constructor(props: Props) {
    super(props);
  }

  errorToast = () => {
    if (
      !this.state.errorMessage && 
      !this.state.updateSuccessMsg &&
      !this.state.removeSuccessMsg
    ) {
      return null;
    }

    let content: any;
    let mofifyClass: any;
    if (this.state.errorMessage) {
      content = this.state.errorMessage;
      mofifyClass = 'error';
    } else if (this.state.updateSuccessMsg) {
      content = this.state.updateSuccessMsg;
      mofifyClass = 'success'
    } else if (this.state.removeSuccessMsg) {
      content = this.state.removeSuccessMsg;
      mofifyClass = 'success'
    } else {
      return null;
    }

    return (
      <Box 
        sx={{ width: "100%" }} 
        className={`msgBg ${mofifyClass}`}
      >
        <Box className={`msgPipe ${mofifyClass}`}></Box>
        <Typography 
          data-test="error-msg" 
          style={{ marginTop: 0, padding: "12px 16px" }}
          className={`msgText ${mofifyClass}`} 
        >
          {content}
        </Typography>
      </Box>
    )
  }

  render() {
    let content;
    const minimumVolumeMessage = this.state.minimumVolumeInLitresMessage;

    content = (
      <OrderReviewWrapper>
        <section className="review-section">
          <div className="title main-title">{window.location.pathname === '/order/review' ? 'Revisar tu pedido' : 'Carrito de compras' }</div>
          {this.errorToast()}
          <div className="product-list">
            {this.state.cartItemList.map(item => item && (
              <div key={item.cartItemId} className="product-wrapper">
                <div className="product-photo" style={{ backgroundImage: `url(${item.imageUrl})` }}></div>
                <div className="product-details">
                  <div className="product-name">{item.productName}</div>
                  <p className="product-type">{item.brand}</p>
                  <p className="product-description">Descripción: {truncateTextToTenWords(item.description)}</p>
                  <p className="product-volume">{item.volume.toLocaleString()} litros x {item.quantity} = {item.totalVolumeByProduct.toLocaleString()} litros</p>
                  <div className="quantity-container">
                    <div className="quantity-text">Cantidad</div>
                    <div className="quantity-panel">
                      <button 
                        data-test="add-btn"
                        className="btn quantity-btn" 
                        onClick={() => this.handleAdd(item.cartItemId, 'decrease')}>
                        <img src={subtractIcon} alt="decrease icon" />
                      </button>
                      <div className="quantity-number">{item.quantity}</div>
                      <button className="btn quantity-btn" onClick={() => this.handleAdd(item.cartItemId, 'increase')}>
                        <img src={addIcon} alt="increase icon" />
                      </button>
                    </div>
                  </div>
                </div>
                <button 
                  data-test="delete-btn"
                  onClick={() => this.handleRemove(item.cartItemId)}
                  className="btn remove-btn">Quitar</button>
              </div>))}
          </div>
        </section>

        {window.location.pathname === '/order/review' && <section className="address-section">
          <div style={{ marginBottom: 14, display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <div className="title sub-title">Dirección de envío</div>
            <button 
              data-test="change-address-btn"
              onClick={this.handleChangeAddress}
              className="btn change-address-btn">Cambiar dirección</button>
          </div>
          <div className="address-detail">
            {this.state.defaultAddress || 'No tienes una dirección predeterminada' }
          </div>
        </section>}

        <section className="total-section">
          <div className="title sub-title" style={{marginBottom: 10,}}><span style={{fontWeight: 400,}}>Volumen total del pedido =</span> {this.state.thisOrderVolume.toLocaleString()} litros</div>
          <div className="title sub-title">Número total de productos : {this.state.cartTotal} productos</div>
        </section>

        <button 
          disabled={this.state.cartItemList.length === 0}
          data-test="go-to-checkout-btn"
          onClick={this.handleProceedToCheckout}
          className="btn go-to-checkout-btn">
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <img src={cartIcon} alt="cart icon" />
            <div>{window.location.pathname === '/order/review' ? 'Enviar pedido' : 'Proceder al pago'}</div>
          </div>
        </button>
      </OrderReviewWrapper>
    );

    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu isAddtoCart={this.state.cartItemList.length > 0} navigation={this.props.navigation} id="navigation-menu-address" />
        <div style={{ background: '#F7F7F7', flex: 1,}}>
          {content}
        </div>
        <FooterWeb />
        <ConfirmModal
          data-test="confirm-modal"
          customStyles={{text: {textAlign: 'left', color: '#0F172A', fontSize: 20, lineHeight: '28px', letterSpacing: '-0.12px'}}} 
          open={this.state.showModal} confirmText={minimumVolumeMessage} 
          onClose={() => this.setState({ showModal: false, })} />
      </ThemeProvider>
    );
  }
}



// Customizable Area End
