Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Ingresar texto";
exports.labelTitleText = "Configuraciones de cuenta";
exports.labelBodyText = "Cuerpo de ajustes2";
exports.subTitle = 'Dirección de entrega';
exports.addNewAddress = 'Agregar nueva dirección';
exports.edit = "Editar";
exports.setDefault = "Establecer como predeterminado";
exports.delete = "Eliminar";
exports.authToken = "authToken";
exports.getListAddressMethod = "GET"
exports.getListAddressApiEndPoint = "bx_block_order_management/addresses"
exports.default = "Predeterminado";
exports.btnExampleTitle = "CLICK ME";
exports.methodPatch = "PATCH"
exports.methodDelete = "DELETE"
// Customizable Area End