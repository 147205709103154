// Customizable Area Start
import React from "react";
import { fyiIcon } from "./assets";
import {
  Box,
  styled,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import OrderErrorController, {
  Props,
} from "./OrderErrorController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import FooterWeb from "../../../components/src/Footer.web";

const theme = createTheme({});

const OrderErrorWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  boxSizing: 'border-box',
  margin: '0 auto',
  width: '100%',
  maxWidth: 1440,
  padding: '0 54px',
  paddingBottom: 50,
  [theme.breakpoints.down("md")]: {
    padding: "0 20px",
    paddingBottom: 30,
  },
  
  '& .sorry-content': {
    display: 'flex',
    gap: 33,
    alignItems: 'center',
    marginBottom: 57,
    marginTop: 160,
    [theme.breakpoints.down("md")]: {
      marginTop: 80,
      gap: 20,
    },
  },
  '& .fyi-icon': {
    width: 48,
    [theme.breakpoints.down("md")]: {
      width: 36,
    },
  },
  '& .sorry-text': {
    margin: 0,
    color: '#0F172A',
    fontFamily: '"Arial", sans-serif',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      lineHeight: "20px",
    },
  },
  '& .back-to-home': {
    borderWidth: 0,
    padding: "16px 125px",
    borderRadius: "8px",
    color: "#FFF",
    fontFamily: 'Arial, sans-serif',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
    backgroundColor: "#004E9E",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      padding: "16px 60px",
    },
  },
}));

export default class OrderError extends OrderErrorController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <NavigationMenu navigation={this.props.navigation} id="navigation-menu-address" />
        <Box style={{
          backgroundColor: '#f7f7f7',
          minHeight: "calc(100vh - 457px)"
        }}>
          <OrderErrorWrapper>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box className="sorry-content">
                  <img className="fyi-icon" src={fyiIcon} alt="For your information icon" />
                  <p className="sorry-text">
                    Lo siento, tu orden no se puede realizar en este momento.<br />
                    Es posible que tu dirección de envío no esté asociada a una cuenta de SAP. 
                    Por favor, contacta a tu administrador de FUCHS.
                  </p>
                </Box>
                <button data-test-id="btn-go-home" className="back-to-home" onClick={this.goHome}>
                  Regresar al Inicio
                </button>
              </Box>
            </Box>
          </OrderErrorWrapper>
        </Box>
        <FooterWeb />
      </>

    );
  }
}



// Customizable Area End
