//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Dialog, 
  DialogContent, 
  DialogActions
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const theme = createTheme({
  palette: {
    primary: {
      main: "#004E9E",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
});
import FooterWeb from "../../../components/src/Footer.web";
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu isAddtoCart={this.state.isAddtoCart} id="product-description" navigation={this.props.navigation} />
        <Container style={{flex: 1, padding: 30, marginBottom: 30}} maxWidth={"xl"}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography data-test-id="btn-home" style={{cursor: "pointer"}} onClick={this.goHome}>Inicio</Typography>
            <Typography >{this.state.productData.brand} {this.state.productData.name}</Typography>
          </Breadcrumbs>
          <Container maxWidth={"lg"} style={{ marginTop: 60 }}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={6} style={{...webStyle.imageContainer, ...webStyle.flexBox}} >
                  <img
                    src={this.state.productData.images?.[0].url}
                    alt=""
                    width="80%"
                  />
              </Grid >
              <Grid item xs={12} sm={12} md={5} style={{ alignItems: "center" }}>
                <Box style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}>
                  <Typography style={{ color: "#0F172A", fontSize: "24px", lineHeight: "32px", letterSpacing: "-0.12px" }}>
                    SKU : {this.state.productData.sku_no}
                  </Typography>

                  <Typography style={{ letterSpacing: "0.12px", fontWeight: "700", lineHeight: "32px", fontSize: "24px" }}>
                    Nombre del producto : {this.state.productData.brand} {this.state.productData.name}
                  </Typography>

                  <Box>
                    <Box>
                      <Typography style={webStyle.subtitleText}>Descripción</Typography>
                      <ul>
                        <li>
                          <Typography style={webStyle.commonText}>
                            {this.state.productData.description}
                          </Typography>
                        </li>
                      </ul>
                    </Box>
                    <Box>
                      <Typography style={webStyle.subtitleText}>Empaque / UdM</Typography>
                      <ul>
                        <li>
                          <Typography style={webStyle.commonText}>
                            {this.state.productData.packaging}  
                          </Typography>
                        </li>
                      </ul>
                    </Box>
                    <Grid xs={12} md={9} style={{ ...webStyle.flexBox, justifyContent: "space-between", marginTop: 40, marginBottom: 40 }}>
                      <Typography style={webStyle.subtitleText}>
                        Cantidad
                      </Typography>
                      <Box sx={{ ...webStyle.flexBox, gap: "12px", }}>
                        <IconButton data-test-id="btn-decrease-quantity" 
                           onClick={() => {this.onChangeQuantity("reduce")}} variant="contained" style={webStyle.buttonStyle}>
                          <RemoveIcon style={{ width: 20 }} />
                        </IconButton>
                        <Typography style={{...webStyle.subtitleText, width: 30, textAlign: "center"}}>
                          {this.state.quantity < 10 && this.state.quantity > 0 ? `0${this.state.quantity}` : this.state.quantity}
                        </Typography>
                        <IconButton data-test-id="btn-increase-quantity" 
                           onClick={() => {this.onChangeQuantity("add")}} variant="contained" style={webStyle.buttonStyle} >
                          <AddIcon style={{ width: 20 }} />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={11} marginTop={2}>
                      <Button onClick={this.handleClickAddToCart} fullWidth
                        variant="contained" color="primary"
                        data-test-id="btn-add-to-cart"
                        disabled={this.state.isLoading}
                        style={{
                          ...webStyle.buttonStyle, padding: 15,
                          backgroundColor: this.state.isLoading ? "#4B7FC2" : "#004E9E",
                          opacity: this.state.isLoading ? 0.6 : 1
                        }}>
                        <Typography style={{ ...webStyle.subtitleText, fontSize: 16, color: "#FFF" }}>
                          Agregar al Carrito
                        </Typography>
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={1}></Grid>
            </Grid>
          </Container>
        </Container>
        <Dialog fullWidth open={this.state.openDialogSuccess}>
          <DialogContent dividers>
            <Box style={{ ...webStyle.flexBox, flexDirection: "column", marginTop: 20 }}>
              <CheckCircleIcon style={{ fontSize: 48, color: "#34D399" }} />
              <Typography align="center" style={{ ...webStyle.subtitleText, fontSize: 18, marginTop: 10 }}>{configJSON.successMsg}</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-test-id="btn-ok"
              onClick={this.handleToggleSuccess}
              style={{ ...webStyle.buttonStyle, padding: "10px 40px" }}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <FooterWeb />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: { 
    borderRadius: "8px", 
    backgroundColor: "#004E9E",
    color: "#FFF",
    padding: 10 ,
    textTransform: "none"
  },
  subtitleText: {
    fontWeight: 700, 
    fontSize: 14, 
    lineHeight: "22px", 
    color: "#0F172A"
  },
  flexBox: {
    display: "flex", 
    alignItems: "center"
  },
  imageContainer: { 
    borderRadius: 8, 
    padding: 0,
    justifyContent: "center" 
  },
  commonText: { 
    fontSize: "16px", 
    color: "#0F172A", 
    lineHeight: "24px", 
    fontWeight: "400" 
  }
};
// Customizable Area End
