// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { isEmail } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  emailValue: any;
  emailError: boolean;
  errorMessage: string;
}

interface SS {
  navigation: any;
}


export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  verifyEmailExistId = '';

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      emailValue: "",
      emailError: false,
      errorMessage: "",
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
 
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any
  ) => {
    const successCallbackMap = {
      [this.verifyEmailExistId]: this.handleVerifyEmailExistResponse,
    }
 
    if (apiRequestCallId) {
      const successCallback: (responseJSON: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleVerifyEmailExistResponse = (responseJSON: any) => {
    if (responseJSON?.errors) {
      this.setState({ emailError: true, errorMessage: responseJSON?.errors })
      return;
    }
    localStorage.setItem("forgotPwEmail", this.state.emailValue);

    this.props.navigation.navigate("VerifyEmail");
  }

  handleChangeEmail = (value: string) => {
    this.setState({ emailValue: value, emailError: false, errorMessage: "" });
  }

  validateInput = () => {
    if (!this.state.emailValue || this.state.emailValue?.length === 0) {
      this.setState({ emailError: true, errorMessage: configJSON.emptyEmailPassword })
      return false;
    }

    const validEmail = isEmail("", this.state.emailValue)?.status

    if (!validEmail) {
      this.setState({ errorMessage: configJSON.invalidAccount, emailError: true });
      return false;
    }

    return true
  }

  emailSendLink = () => {
    const isValidLoginValue = this.validateInput();
    if (isValidLoginValue) this.doEmailSendLink();
  }

  doEmailSendLink = () => {
    this.callAPIVerifyEmail();
  }

  gotoLogIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  callAPIVerifyEmail = () => {
    const body = {
      "data": {
        "attributes": {
            "email": this.state.emailValue
        }
      }
    }
 
    this.verifyEmailExistId = sendAPIRequest(
      `/bx_block_forgot_password/password_recovery`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }
    )
  }
}
// Customizable Area End
