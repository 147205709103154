export const dropdownIcon = require("../assets/dropdown-arrow.png");
export const icSuccess = require("../assets/ic-success.svg");


export const propStyle = {
    button: {
        textTransform: "none", 
        color: "#FFF",
        borderRadius: 8,
        fontWeight: 700
    },
    bgColorPrimary: {
        backgroundColor: "#004E9E"
    },
    commonText: {
        fontWeight: 400, 
        fontSize: 16, 
        color: "#0F172A", 
      }
}