// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { sendAPIRequest, navigateTo } from "../../../components/src/utils";
import { getStorageData,logoutUser } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
type ErrorType = string | { token: string }[];

type CartItem = {
  id: string;
  type: string;
  attributes: {
    id: number;
    quantity: number;
    productdescription: {
      data: ProductDescription | null;
    };
  };
};

interface ProductDescription {
  type: string;
  id: string;
  attributes: {
    product_id: null;
    description: string;
    sku_no: string;
    name: string;
    brand: string;
    packaging: string;
    images: Array<{
      url: string;
      file_name: string;
      content_type: string;
      id: number;
    }>;
    review_count: number;
    review: {
      data: Array<unknown>;
    };
  };
}

interface PastAllOrder {
  orderId: string;
  orderPlacedDate: string;
  orderDeliveredDate: string;
  addressName: string;
}

interface Image {
  url: string;
}

interface Item {
  product_id: number;
  name: string;
  brand: string;
  description: string;
  quantity: number;
  sku: string;
  itemNumber: number;
  uom: string;
  image: Image[];
}

interface DeliveryDetails {
  deliveryNumber: string;
  items: Item;
  carrierInformation: string | null; 
  trackingInformation: string | null; 
  shippedDate: Date | null;
}

interface Data {
  sapOrderNumber: string;
  deliveryDetails: DeliveryDetails[];
}

interface ApiResponseData {
  data: Data;
  message: string;
  status_code: string;
}

export interface Product {
  productId: number;
  productName: string;
  productBrand: string;
  productDescription: string;
  productQty: number;
  productPhoto: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: string;
  cartProductIds: number[];
  pastOrders: PastAllOrder[];
  errorMessage: string;
  successMessage: string;
  singleOrder: Product[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchOrdersHistoryId = '';
  fetchOrdersDetailsId = '';
  reorderSingleProductsId = '';
  fetchOneProductData = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isLoading: false,
      token: '',
      errorMessage: '',
      pastOrders: [],
      successMessage: '',
      cartProductIds: [],
      singleOrder: []
      // Customizable Area End
    };
  }

  isAlreadyDataInCart(productId: number) {
    return this.state.cartProductIds.includes(productId);
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await getStorageData('authToken');
    this.setState({ token,isLoading:true },()=> {
      this.fetchOrdersDetails();
      this.fetchOrdersHistory();
      this.fetchProductData();
    });
  }

  fetchOrdersDetails = () => {
      this.fetchOrdersDetailsId = sendAPIRequest(
        configJSON.cartAPi,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': this.state.token
          }
        }
      )
   
  }

  fetchOrdersHistory = () => {
      this.fetchOrdersHistoryId = sendAPIRequest(
      configJSON.mulesoftApi,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        }
      }
    )
  }

  fetchProductData = () => {
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ isLoading: true });

    this.fetchOneProductData = sendAPIRequest(
      `bx_block_order_management/mulesoft_orders/${idData}/order_details`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        }
      }
    )
  }

  reorderSingleProduct = (productId: number, productQty: number) => {
        if (this.state.isLoading === false) {
      this.reorderSingleProductsId = sendAPIRequest(
        configJSON.postShoppingCart,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token': this.state.token
          },
          body: {
            "data":{
              "attributes":{
                  "productdescription_id": productId,
                  "quantity": productQty
              }
            }
          }
        }
      )
    }
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiAllSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiAllSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.fetchOrdersDetailsId]: this.handleFetchOrderDetailsResponses,
      [this.reorderSingleProductsId]: this.handleReorderSingleProductResponses,
      [this.fetchOneProductData]: this.handleFetchSingleProduct,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
  
    const { errors: possibleErrors } = responseJSON;
    this.setState((prevState)=>({ isLoading: responseJSON?.status_code === 404 ? false : prevState.isLoading }));
    if (possibleErrors) {
      const errors = possibleErrors as ErrorType;
      if (Array.isArray(errors) && errors[0].token) {
        logoutUser()
        navigateTo({screenName:"EmailAccountLoginBlock",props:this.props})
      } else {
        this.setState({ errorMessage: typeof errors === 'string' ? errors : 'Something went wrong', isLoading: false });
      }
      return true; 
    }
  
    return false; 
  }

  handleFetchOrderDetailsResponses = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState({ errorMessage: '' });

    let cartIds: (number | null)[] = [];
    const resData  = responseJSON.data as CartItem[];
    cartIds = resData.map((item: {
      id: string;
      type: string;
      attributes: {
        id: number;
        quantity: number;
        productdescription: {
          data: ProductDescription | null;
        };
      };
    }) => {
      
      if (!item.attributes.productdescription?.data) {
        return null; 
      }
      return +item.attributes.productdescription.data.id;
    });
    
    const filteredCartItemsDatas = (cartIds.filter(items => items !== null)) as number[];
    filteredCartItemsDatas.sort((idOnes, idTwos) => idOnes - idTwos);

    this.setState({
      cartProductIds: filteredCartItemsDatas
    });
  }

  handleReorderSingleProductResponses = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState({ errorMessage: '', successMessage: configJSON.addedToCartSuccess});
    this.fetchOrdersDetails(); 
  }

  handleFetchSingleProduct = (responseJSON: Record<string, unknown>) => {
    const responses = responseJSON as unknown as ApiResponseData;
    if (this.handleErrorResponse(responseJSON)) return;

    const singleOrder = responses.data.deliveryDetails.map((apiResponse: DeliveryDetails) => { 
      const orderDatas = apiResponse.items;
      return {
        productId: orderDatas.product_id,
        productName: orderDatas.name,
        productBrand: orderDatas.brand,
        productDescription: orderDatas.description,
        productQty: orderDatas.quantity,
        productPhoto: orderDatas.image[0]?.url || '',
      };
    });
    this.setState({ singleOrder: singleOrder,isLoading:false})
  }

  handleClose = () => {
    this.setState({ successMessage: '' })
  }
  getProductTitle = (productId: number) => {
    let title = this.isAlreadyDataInCart(productId) ? "Este producto ya está en tu carrito" : undefined
    return title
  }
  
}

// Customizable Area End