import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  imgLogo,
  imgMain,
  imgAccess,
  imgDecline,
} from "./assets";
import { removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  imgMain: any;
  imgLogo: any;
  imgAccess: any;
  imgDecline: any;
  invalidCapitalLetter: boolean;
  invalidLowercaseLetter: boolean;
  invalidNumber: boolean;
  invalidEmail: boolean;
  checkedRememberMe: boolean;
  invalidLength: boolean;
  invalidName: boolean;
  invalidReTypePassword: boolean;
  invalidPassword: boolean;
  invalidSym: boolean;
  btnChecked: boolean;
  isSignupSuccess: boolean;
  errorMsg: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  signUp: string;
  labelFullName: string;
  labelInvalidName: string;
  labelInvalidEmail: string;
  labelTypePassword: string;
  labelPasswordsNotMatch: string;
  labelConfirmPassword: string;
  capitalLetter: string;
  lowercaseLetter: string;
  oneNumber: string;
  minimumChar: string;
  invalidEmailAddres: string;
  haveAccount: string;
  logIn: string;
  oneSym: string;

  currentCountryCode: any;
  labelTitleImage: string = "";
  labelDescImage: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      errorMsg: "",
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      imgLogo: imgLogo,
      imgMain: imgMain,
      imgAccess: imgAccess,
      imgDecline: imgDecline,
      invalidCapitalLetter: false,
      invalidLowercaseLetter: false,
      invalidNumber: false,
      invalidEmail: false,
      checkedRememberMe: false,
      invalidLength: false,
      invalidName: false,
      invalidReTypePassword: false,
      invalidPassword: false,
      invalidSym: false,
      btnChecked: false,
      isSignupSuccess: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.labelTitleImage = configJSON.labelTitleImage;
    this.labelDescImage = configJSON.labelDescImage;
    this.signUp = configJSON.signUp;
    this.labelFullName = configJSON.labelFullName;
    this.labelInvalidName = configJSON.labelInvalidName;
    this.labelInvalidEmail = configJSON.labelInvalidEmail;
    this.labelTypePassword = configJSON.labelTypePassword;
    this.labelPasswordsNotMatch = configJSON.labelPasswordsNotMatch;
    this.labelConfirmPassword = configJSON.labelConfirmPassword;
    this.capitalLetter = configJSON.capitalLetter;
    this.lowercaseLetter = configJSON.lowercaseLetter;
    this.oneNumber = configJSON.oneNumber;
    this.minimumChar = configJSON.minimumChar;
    this.invalidEmailAddres = configJSON.invalidEmailAddres;
    this.haveAccount = configJSON.haveAccount;
    this.logIn = configJSON.logIn;
    this.oneSym = configJSON.oneSym;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
         
          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules,
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors && responseJson.meta.token) {
              this.confirmEmail(responseJson.meta.token);
              this.setState({isSignupSuccess: true})
          } else {
            //Check Error Response
            this.handleErrorsFromServer(responseJson.errors);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await removeStorageData("authToken")
  }

  handleErrorsFromServer(errors: any) {
    if (Array.isArray(errors) && errors[0]?.account) {
      this.setState({ errorMsg: errors[0]?.account });
    }
  }

  confirmEmail(token: string) {
    const httpBody = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.confirmEmail}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } 

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (this.isStringNullOrBlank(this.state.fullName)) {
      this.setState({ invalidName: true });
      return false;
    }
    if (this.isStringNullOrBlank(this.state.email)) {
      this.setState({ invalidEmail: true });
      return false;
    }
    if (this.isStringNullOrBlank(this.state.password)) {
      this.setState({ invalidPassword: true });
      return false;
    }
    if (this.isStringNullOrBlank(this.state.reTypePassword)) {
      this.setState({ invalidReTypePassword: true });
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.setState({ invalidEmail: true });
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.setState({ invalidReTypePassword: true });
      this.setState({ invalidPassword: true });
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      full_name: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry =
        !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
      if (text) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const validation = re.test(String(text).toLowerCase());
        this.setState({ invalidEmail: !validation });
      } else {
        this.setState({ invalidEmail: false });
      }
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputFullNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ fullName: text });

      //@ts-ignore
      this.txtInputFullNamePrpos.value = text;

      if (text) {
        this.setState({ invalidName: false });
      }
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
      this.setState({ invalidReTypePassword: false });
      this.setState({ invalidPassword: false });
    },
    secureTextEntry: true,
  };

  setRememberMe = (value: boolean) => {
    this.setState({ btnChecked: value });
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;

      this.setState({ invalidPassword: false });
      this.setState({ invalidReTypePassword: false });

      if (text) {
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        const checkLength = text.length >= 8;
        const checkExistNumber = /\d/.test(text);
        const checkOneCapital = /[A-Z]/.test(text);
        const checkOneLowercase = /[a-z]/.test(text);
        const checkSym = regex.test(text);

        this.setState({ invalidCapitalLetter: !checkOneCapital });
        this.setState({ invalidLowercaseLetter: !checkOneLowercase });
        this.setState({ invalidNumber: !checkExistNumber });
        this.setState({ invalidLength: !checkLength });
        this.setState({ invalidSym: !checkSym });
      }
    },
    secureTextEntry: true,
  };
  handleButtonConfirm = () => {
    this.setState({isSignupSuccess: !this.state.isSignupSuccess})
    this.goToLogin()
  }
  // Customizable Area End
}
