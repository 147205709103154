import * as React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import { configJSON } from "./OrderManagement";
import OrderManagementController, {Props} from "./OrderManagementController"
import CircularProgress from '@material-ui/core/CircularProgress';
import FooterWeb from "../../../components/src/Footer.web";

export default class OrderManagementView extends OrderManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
  render(){
  return (
    <ThemeProvider theme={theme}>
      <NavigationMenu id="order-management-navigation" navigation={this.props.navigation} />
      <Box style={{ backgroundColor: "#F7F7F7" }}>
        <Container
          style={webStyle.mainWrapper}
          maxWidth={"xl"}
        >
          {!this.state.isLoading ?
            <Box style={webStyle.contentContainer}>
              <Typography style={webStyle.title}>{configJSON.thankyouText}</Typography>
              <Box style={webStyle.orderInfo as React.CSSProperties}>
                <Typography style={webStyle.commonText}>{configJSON.orderNo} : {this.state.orders.attributes.order_number}</Typography>
                <Typography style={webStyle.commonText}>{configJSON.deliveryAddress} : {this.state.orders.attributes.delivery_addresses}</Typography>
              </Box>
              <Button data-test-id="btn-go-home" onClick={this.goHome} style={webStyle.buttonBackHome as React.CSSProperties}>
                {configJSON.buttonBackToHome}
              </Button>
            </Box>
            : <Box style={webStyle.contentContainer}>
              <Typography style={webStyle.title}>{configJSON.processingText}</Typography>
              <Box style={{ ...webStyle.orderInfo, backgroundColor: "transparent" } as React.CSSProperties}>
                <CircularProgress style={{ color: "#004E9E", width: 50, height: 50 }} />
              </Box>
            </Box>
          }
        </Container>
      </Box>
      
      <FooterWeb />
    </ThemeProvider>
  );}
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
});

const webStyle = {
  mainWrapper: {
    paddingBottom: 32,
    background: "#F7F7F7",
    height: "auto",
    minHeight: "calc(100vh - 457px)"
  },
  contentContainer : {
    marginTop: 50,
    marginBottom: 30,
    borderRadius: 5
  },
  title: {
    fontWeight: 700, 
    fontSize: 30,
    lineHeight: "40px",
    color: "#0F172A"
  },
  orderInfo: {
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: "#fff", 
    padding: 20,
    display: "flex",
    flexDirection :"column",
    gap: 20
  },
  buttonBackHome: {
    width: 360,
    textTransform: "none", 
    padding: 15, 
    borderRadius: 8, 
    fontWeight: 700,
    fontSize: 16, 
    lineHeight: "24px",
    backgroundColor: "#004E9E",
    color: "#fff"
  },
  commonText: {
    fontWeight: 700, 
    fontSize: 14, 
    color: "#0F172A"
  }
};
// Customizable Area End

