// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

type Address = {
  id: string | null;
  type: 'addresses' | null;
  attributes: {
    first_name: string | null;
    last_name: string | null;
    building_number: number | null;
    address: string | null;
    street: string | null;
    city: string | null;
    country: string | null;
    zip_code: string | null;
    is_default: boolean | null;
    mulesoft_id: string;
    mulesoft_address: string;
  };
};

// Define the structure of the cart item attributes
interface CartItemAttributes {
  cartItemId: string;
  productName: string;
  quantity: number;
  brand: string;
  description: string;
  imageUrl: string | null;
  volume: number;
  totalVolumeByProduct: number;
}

// Define the structure of the product description
interface ProductDescription {
  id: string;
  type: string;
  attributes: {
    liters: number;
    product_id: null;
    name: string;
    brand: string;
    description: string;
    packaging: string;
    sku_no: string;
    images: Array<{
      file_name: string;
      content_type: string;
      id: number;
      url: string;
    }>;
    review_count: number;
    review: {
      data: Array<unknown>;
    };
  };
}

// Define the structure of the cart response
interface CartResponse {
  data: Array<{
    id: string;
    type: string;
    attributes: {
      id: number;
      quantity: number;
      productdescription: {
        data: ProductDescription;
      };
    };
  }>;
  meta: string;
}

interface S {
  isLoading: any;
  token: any;
  errorMessage: string;
  updateSuccessMsg: any;
  removeSuccessMsg: any;
  cartItemList: CartItemAttributes[];
  cartTotal: number;
  defaultAddress: string;
  thisOrderVolume: number;
  showModal: boolean;
  minimumVolumeInLitresMessage: string
}

interface SS {
  id: any;
}

export default class OrderReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchOrderDetailsId = '';
  fetchDefaultAddressId = '';
  addMoreOrLessId = '';
  removeCartItemId = '';

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      token: '',
      errorMessage: '',
      updateSuccessMsg: '',
      removeSuccessMsg: '',
      cartItemList: [],
      cartTotal: 0,
      defaultAddress: '',
      thisOrderVolume: 0,
      showModal: false,
      minimumVolumeInLitresMessage: ""
    };
  }

  async componentWillMount() {
    const storedToken = localStorage.getItem("authToken");
    this.setState({ token: storedToken });
    await this.handleOrderError()
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchOrderDetails();
    this.fetchDefaultAddress();
  }

  fetchOrderDetails = () => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true });

      this.fetchOrderDetailsId = sendAPIRequest(
        `/bx_block_shopping_cart/shopping_cart`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': this.state.token
          }
        }
      )
    }
  }

  fetchDefaultAddress = () => {
    this.setState({ isLoading: true });

    this.fetchDefaultAddressId = sendAPIRequest(
      `/bx_block_order_management/addresses`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        }
      }
    )

  }

  handleAdd = (cartItemId: any, type: any) => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true });

      this.addMoreOrLessId = sendAPIRequest(
        `/bx_block_shopping_cart/shopping_cart/${cartItemId}?quantity=${type}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'token': this.state.token
          }
        }
      )
    }
  }

  handleRemove = (cartItemId: any) => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true });

      this.removeCartItemId = sendAPIRequest(
        `/bx_block_shopping_cart/shopping_cart/${cartItemId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'token': this.state.token
          }
        }
      )
    }
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any
  ) => {
    const successCallbackMap = {
      [this.fetchOrderDetailsId]: this.handleFetchOrderDetailsResponse,
      [this.addMoreOrLessId]: this.handleAddMoreOrLessResponse,
      [this.removeCartItemId]: this.handleRemoveResponse,
      [this.fetchDefaultAddressId]: this.handleFetchDefaultAddressResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleFetchOrderDetailsResponse = (responseJSON: any) => {
    this.setState({ isLoading: false });
    if (responseJSON?.errors) {
      if (responseJSON?.errors[0].token) {
        localStorage.clear();
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }

      this.setState({ errorMessage: responseJSON?.errors || 'Algo salió mal' });
      return;
    }

    this.setState({ errorMessage: '' });

    let totalQuantity = 0;
    let sumVolume = 0;
    let cartItems: CartItemAttributes[] = [];
    cartItems = responseJSON?.data.map((item: {
      id: string;
      type: string;
      attributes: {
        id: number;
        quantity: number;
        productdescription: {
          data: ProductDescription | null;
        };
      };
    }) => {
      if (!item.attributes.productdescription?.data) {
        return null; 
      }

      const product = item.attributes.productdescription.data.attributes;
      const selfVolume = product.liters;
      const totalVolumeByProduct = selfVolume * item.attributes.quantity;
        totalQuantity += item.attributes.quantity;
        sumVolume += totalVolumeByProduct;
        return {
          cartItemId: item.id,
          sku: product.sku_no,
          volume: selfVolume,
          totalVolumeByProduct: totalVolumeByProduct,
          productName: product?.name ?? 'Producto fue eliminado',
          quantity: item.attributes.quantity,
          brand: product?.brand ?? 'Producto fue eliminado',
          description: product?.description ?? 'Producto fue eliminado',
          imageUrl: product?.images?.length ? product.images[0].url : 'https://placehold.co/130x130/png',
        };
    });
    // Filter out any null values from the cartItems array
    const filteredCartItems = cartItems.filter(item => item !== null);
    filteredCartItems.sort((a, b) => {
      const idA = parseInt(a.cartItemId);
      const idB = parseInt(b.cartItemId);
      return idA - idB;
    });
    
    this.setState({
      cartItemList: filteredCartItems,
      cartTotal: totalQuantity,
      thisOrderVolume: sumVolume,
    });
  }

  handleFetchDefaultAddressResponse = (responseJSON: any) => {
    this.setState({ isLoading: false });
    if (responseJSON?.errors) {
      if (responseJSON?.errors[0].token) {
        localStorage.clear();
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }

      this.setState({ errorMessage: responseJSON?.errors || 'Algo salió mal' });
      return;
    }

    this.setState({ errorMessage: '' });

    
    if (responseJSON.data?.length) {
      const defaultAddressObj: Address = responseJSON.data.find((addressObj: Address) => addressObj?.attributes?.is_default);
      
      if (defaultAddressObj?.attributes?.mulesoft_address) {
        this.setState({
          defaultAddress: defaultAddressObj.attributes.mulesoft_address
        });
      } else if (defaultAddressObj?.attributes?.street) {
        const { building_number, street, city, country, zip_code } = defaultAddressObj.attributes;
        this.setState({
          defaultAddress: `${building_number}, ${street}, ${city}, ${country} - ${zip_code}`
        });
      } else if (defaultAddressObj && !defaultAddressObj?.attributes?.street) {
        this.setState({
          defaultAddress: 'The default address is empty!'
        });
      }
    }
  }

  handleAddMoreOrLessResponse = (responseJSON: any) => {
    this.setState({ isLoading: false });
    if (responseJSON?.errors) {
      if (responseJSON?.errors[0].token) {
        localStorage.clear();
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }

      this.setState({ errorMessage: responseJSON?.errors || 'Algo salió mal', updateSuccessMsg: '', removeSuccessMsg: '' });
      return;
    }

    this.setState({ errorMessage: '', updateSuccessMsg: 'Tu cantidad se actualizó exitosamente!', removeSuccessMsg: '' });

    this.fetchOrderDetails();
  }

  handleRemoveResponse = (responseJSON: any) => {
    this.setState({ isLoading: false });
    if (responseJSON?.errors) {
      if (responseJSON?.errors[0].token) {
        localStorage.clear();
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }

      this.setState({ errorMessage: responseJSON?.errors || 'Algo salió mal', updateSuccessMsg: '', removeSuccessMsg: '', });
      return;
    }

    this.setState({ errorMessage: '', updateSuccessMsg: '', removeSuccessMsg: 'Los productos eliminados exitosamente!' });

    this.fetchOrderDetails();
  }

  handleChangeAddress = () => {
    this.props.navigation.navigate('AddressManagement');
  }

  handleOrderError = async () => {
    const orderQuantity =  await getStorageData(configJSON.minVolume)
    if(orderQuantity) {
      this.setState({
        minimumVolumeInLitresMessage:`No podemos procesar tu solicitud en este momento.\nEl volumen de la orden es menor a ${orderQuantity} litros.`
      })
    }
  }

  handleProceedToCheckout = async () => {
    const minimumCartVolume = Number(await getStorageData(configJSON.minVolume))
    if (this.state.thisOrderVolume < minimumCartVolume ) {
      this.setState({ showModal: true });
      return;
    }
    if (window.location.pathname === '/order/review') {
      this.props.navigation.navigate('OrderManagement');
    } else {
      this.props.navigation.navigate('AddressManagement');
    }
  }
}

// Customizable Area End