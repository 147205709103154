// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  styled,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
const config = require("./config")

import VerifyEmailController, {
  Props,
} from "./VerifyEmailController";
import ForgotPasswordUIContainer from "./ForgotPasswordUIContainer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class VerifyEmail extends VerifyEmailController {
  constructor(props: Props) {
    super(props);
  }

  errorToast = () => {
    if (!this.state.emailError && !this.state.resendSuccessMsg) {
      return null;
    }

    let content: any;
    let mofifyClass: any;
    if (this.state.emailError) {
      content = this.state.errorMessage;
      mofifyClass = 'error';
    } else {
      content = this.state.resendSuccessMsg;
      mofifyClass = 'success'
    }

    return (
      <Box sx={{ width: "100%" }} className={`msgBg ${mofifyClass}`}>
        <Box className={`msgPipe ${mofifyClass}`}></Box>
        <Typography data-test="error-msg" className={`msgText ${mofifyClass}`} style={{ marginTop: 0, padding: "12px 16px" }}>
          {content}
        </Typography>
      </Box>
    )
  }
  render() {
    return (
      <ForgotPasswordUIContainer>
        <VerifyEmailContainer>
          <Box className="contentWrapper">
            <Typography className="labelTitle">
              {config.title}
            </Typography>

            {this.errorToast()}

            <Typography className="subTitle2">
              {config.subTitle2}
            </Typography>

            <Typography className="instructionText2">
              {config.instructionText2} {this.state.emailValue}
            </Typography>

            <Box className="links">
              <Box sx={{ display: "flex" }}>
                <Typography
                  onClick={this.resendLink}
                  className="normalText"
                  style={{
                    fontWeight: 700, color: config.colorPrimary,
                    cursor: "pointer",
                  }}
                  data-test="resend-email"
                >
                  {config.resendEmail}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography 
                  style={{ marginRight: 10 }}
                  className="normalText" 
                >
                  {config.backTo}
                </Typography>
                <Typography
                  className="normalText"
                  data-test="go-to-login"
                  onClick={this.gotoLogIn}
                  style={{
                    fontWeight: 700, 
                    color: config.colorPrimary,
                    cursor: "pointer",
                  }}>
                  {config.logIn}
                </Typography>
              </Box>
            </Box>
          </Box>
        </VerifyEmailContainer>
      </ForgotPasswordUIContainer>
    );
  }
}

const VerifyEmailContainer = styled(Box)(({ theme }) => ({
  '& .contentWrapper': {
    marginTop: 120,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0px",
    margin: "0 auto",
    width: "50%",

    [theme.breakpoints.down(960)]: {
      top: 0,
      transform: "none",
      marginBottom: 20
    }
  },
  '& .labelTitle': {
    fontSize: 24,
    marginBottom: 40,
    fontWeight: 700,
    lineHeight: "32px",
  },
  '& .subTitle2': {
    marginBottom: 8,
    alignSelf: "start",
    color: "#0F172A",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "26px", /* 144.444% */
  },
  '& .instructionText2': {
    alignSelf: "start",
    fontSize: 16,
    color: "#0F172A",
    fontWeight: 400,
    lineHeight: "24px", /* 150% */
    marginBottom: 40,
  },
  '& .links': { width: "100%", display: "flex", justifyContent: "space-between" },
  '& .normalText': {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "22px"
  },
  '& .msgPipe': {
    position: "absolute", 
    width: 5, 
    height: "100%", 
    borderRadius: "8px 0 0 8px",
    '&.error': {
      background: config.colorError
    },
    '&.success': {
      background: config.colorPrimary
    },
  },
  '& .msgBg': {
    '&.error': {
      backgroundColor: "#FEE2E2",
    },
    '&.success': {
      backgroundColor: "#E2E5FE",
    },
    marginTop: 10, 
    borderRadius: 8,
    position: "relative", 
  },
  '& .msgText': {
    fontSize: 12,
    marginTop: 5,
    '&.error': {
      color: config.colorError,
    },
    '&.success': {
      color: config.colorPrimary,
    },
  },
  
}))
// Customizable Area End
