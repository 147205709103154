import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface ShoppingCartOrderItem {
  id: string | number;
  type?: string;
  attributes: {
    price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    other_charges?: number;
    catalogue: {
      data?: object & {
        attributes?: object & {
          name?: string;
        };
      };
    };
  };
}

export interface ShoppingCartOrderCustomer {
  id: string | number;
  type: string;
  attributes: {
    activated?: boolean;
    country_code?: string | null;
    email?: string;
    first_name?: string;
    full_phone_number?: string;
    last_name?: string;
    phone_number?: string | null;
    type?: string;
    created_at?: string;
    updated_at?: string;
  };
}

export interface ShoppingCartOrder {
  id: string | number;
  type: string;
  attributes: {
    status?: string;
    total_fees?: number;
    total_items?: number;
    total_tax?: number;
    customer?: {
      data?: ShoppingCartOrderCustomer | null;
    };
    address?: {
      data?:
        | (object & {
            attributes?: object & {
              name?: string;
            };
          })
        | null;
    };
    order_items?: {
      data?: ShoppingCartOrderItem[] | null;
    };
  };
}

export interface ICard {
  id: string;
  type: string;
  attributes: {
    id: number;
    quantity: number;
    productdescription: {
      data: {
        id: string;
        type: string;
        attributes: {
          description: string;
          name: string;
          brand: string;
          images: [{
            url: string;
          }]
        }
      }
    }
  }
}
// Customizable Area End

interface S {
  // Customizable Area Start
  order_id: number;
  catalogue_id: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  token: string;
  orderList: ShoppingCartOrder[];
  orderItems: ShoppingCartOrderItem[];
  isVisible: boolean;
  isVisibleSuccess: boolean;
  isRefreshing: boolean;

  id: number;
  name: string;
  description: string;
  price: number;
  currency: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  account_id: number;

  itemCard: ICard[];
  selectedItems: string[];
  removeIdItem: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId?: string;
  showOrderApiCallId?: string;
  getItemsApiCallId?: string;
  getItemApiCall? : string;
  removeItemApiCallId? : string;
  removeSelectedItemsApiCallId? : string;
  createOrderItemApiCallId?: string;
  deleteOrderItemApiCallId?: string;
  labelTitle: string = "";
  imgDefaultProduct: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      order_id: 0,
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      orderItems: [],
      isVisible: false,
      isVisibleSuccess: false,
      isRefreshing: false,

      id: 0,
      name: "",
      description: "",
      price: 0,
      currency: "",
      category_id: 0,
      created_at: "",
      updated_at: "",
      account_id: 0,
      itemCard: [],
      selectedItems: [],
      removeIdItem: '',

      // Customizable Area End
    };

    // Customizable Area Start
    this.labelTitle = configJSON.labelTitle;
    this.imgDefaultProduct = configJSON.imgDefaultProduct;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token});
      this.handleGetCartItems(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getItemsApiCallId != null 
    ) {
      this.setState({ isRefreshing: false });
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ itemCard: responseJson?.data });
      } else {
        this.setState({ itemCard: [] });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getItemApiCall != null &&
      this.getItemApiCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ isRefreshing: false });
      if (responseJson && !responseJson.errors && responseJson.data) {
        const items = [...this.state.itemCard];
        const index = items.findIndex((el) => el.id === responseJson.data.id);
        if(index !== -1) {
          items[index] = responseJson.data;
          this.setState({ itemCard: items });
        }
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeItemApiCallId != null &&
      this.removeItemApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ isRefreshing: false });
      if (responseJson && !responseJson.errors && responseJson.message) {
        this.setState({ isVisibleSuccess: true });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeSelectedItemsApiCallId != null &&
      this.removeSelectedItemsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ isRefreshing: false });
      if (responseJson && !responseJson.errors && responseJson.message) {
        this.setState({ isVisibleSuccess: true });
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  isNumberNull(number_value?: number) {
    return (
      number_value === null ||
      Number.isNaN(number_value) ||
      number_value === undefined
    );
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  hideSuccesModal = () => {
    this.setState({ isVisibleSuccess: !this.state.isVisibleSuccess });
  }

  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };

  handleSelectedItem = (id: string) => {
    const arraySelectedItem = [...this.state.selectedItems];
    const index = arraySelectedItem.findIndex((item) => item === id);
    if(index !== -1) {
      arraySelectedItem.splice(index, 1)
    } else {
      arraySelectedItem.push(id);
    }
    this.setState({selectedItems: arraySelectedItem})
  };

  handleRemoveItem = (id: string) => {
    this.setState({removeIdItem: id, isVisible: true});
  }

  handleRemoveAllSelectedItems = () => {
    this.setState({isVisible: true});
  }

  removeSelectedItems = () => {
    const arraySelectedItems = [...this.state.selectedItems];
    const arrayItemCard = [...this.state.itemCard];
    
    if(arraySelectedItems.length > 0) {
      arraySelectedItems.forEach((item) => {
        const index = arrayItemCard.findIndex((el) => el.id === item);
        if(index !== -1) {
          arrayItemCard.splice(index, 1);
        }
      });
      this.handleRemoveSelectedCartItems(this.state.token, this.state.selectedItems);
    }
    this.setState({selectedItems: [], itemCard: arrayItemCard, isVisible: false});
  }

  removeItem = () => {
    const id = this.state.removeIdItem;
    const arraySelectedItem = [...this.state.selectedItems];
    const arrayItemCard = [...this.state.itemCard];
    const index = arraySelectedItem.findIndex((item) => item === id);
    const indexCard = arrayItemCard.findIndex((item) => item.id === id);
    if(index !== -1){
      arraySelectedItem.splice(index, 1)
    }
    if(indexCard !== -1){
      arrayItemCard.splice(indexCard, 1)
      this.handleRemoveCartItem(this.state.token, id);
    }
    this.setState({selectedItems: arraySelectedItem, itemCard: arrayItemCard, isVisible: false, removeIdItem: ''});
  }

  handleConfirmRemoveItem = () => {
    if(!this.state.removeIdItem){
      this.removeSelectedItems();
    } else {
      this.removeItem();
    }
  }

  handleCountIncrease = (id: string) => {
    const arrayItemCard = [...this.state.itemCard];
    const indexCard = arrayItemCard.findIndex((item) => item.id === id);

    if(indexCard !== -1) {
      this.handleUpdateCartItem(this.state.token, configJSON.increase, id);
    }
  }

  handleCountDecrease = (id: string) => {
    const arrayItemCard = [...this.state.itemCard];
    const indexCard = arrayItemCard.findIndex((item) => item.id === id);
    if(indexCard !== -1 && arrayItemCard[indexCard].attributes.quantity > 1) {
      this.handleUpdateCartItem(this.state.token, configJSON.decrease, id);
    }
  }

  handleUpdateCartItem = (token: string, typeAction: string, idItem: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCartItems}/${idItem}?quantity=${typeAction}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGetCartItems = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCartItems
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemoveCartItem = (token: string, idItem: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCartItems}/${idItem}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemoveSelectedCartItems = (token: string, idItems: string[]) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeSelectedItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSelectedCartItems}[${idItems}]`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goShipmentAddress = () => {
    this.props.navigation.navigate("AddressManagement")
  }

  formattedQuantity = (quantity: number) => quantity < 10 ? `0${quantity}` : `${quantity}`;

  // Customizable Area End
}
