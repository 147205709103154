Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.getOrderEndpoint = "bx_block_order_management/mulesoft_orders"
exports.apiContentType = "application/json";
exports.loginAlertMsg = "Por favor inicia sesión primero";
exports.listOfOrdersEndPoints = "order_management/orders";
exports.listOfOrdersMethod = "GET";
exports.createOrderEndPoint = "order_management/orders";
exports.createOrderItemInOrderEndPoint = "order_management/orders/:id/add_order_items";
exports.createOrderMethod = "POST";
exports.getOrderByIdEndPoint = "order_management/orders/:id";
exports.getOrderByIdMethod = "GET";
exports.deleteOrderItemEndPoint = "order_management/orders/:id/remove_order_items";
exports.deleteOrderItemMethod = "DELETE";
exports.appplyCouponCodeEndpoint = "order_management/orders/:id/apply_coupon";
exports.appplyCouponCodeMethod = "POST";
exports.getAddressListEndPoint = "order_management/addresses";
exports.getAddressListMethod = "GET";
exports.createAddressEntPoint = "order_management/addresses";
exports.createAddressMethod = "POST";
exports.addAddressToAddressEndPoint = "order_management/orders/:id/add_address_to_order";
exports.addAddressToAddressMethod = "PUT";
exports.deleteOrderEndPoint = "order_management/orders/:id";
exports.deleteOrderMethod = "DELETE";
exports.cencelOrderEndPoint = "order_management/orders/:id/cancel_order";
exports.cencelOrderMethod = "PUT";
exports.orderCreatedAlertMsg = "¡Tu orden se ha creado exitosamente!";
exports.orderNumberText = "Número de Orden";
exports.quantityText = "Cantidad";
exports.orderedOnText = "Ordenado el";
exports.cancelOrderText = "Cancelar Orden";
exports.deleteOrderText = "Eliminar Orden";
exports.cancelledText = "Cancelado";
exports.descriptionText = "Descripción: ";
exports.categoryText = "Categoría: ";
exports.createNewOrderText = "Crear Nueva Orden";
exports.createNewOrderItemText = "Crear Nuevo Ítem de Orden";
exports.addNewOrderItem = "Agregar Nuevo Ítem de Orden";
exports.viewDetailBtn = "Ver Detalles";
exports.loadingText = "Cargando...";
exports.cancelBtnLabel = "Cancelar";
exports.createBtnLabel = "Crear";
exports.deleteItemBtnLabel = "Eliminar este ítem";
exports.currencySymbole = "$";
exports.subTotalText = "Subtotal";
exports.shippingChargeText = "Cargos de Envío";
exports.totalTaxText = "Impuesto Total";
exports.discountText = "Descuento";
exports.totalText = "Total";
exports.couponCodePlaceholder = "Por favor ingresa el código de cupón";
exports.applyBtnLabel = "Aplicar";
exports.addressLabel = "Dirección:";
exports.selectAddress = "Seleccionar";
exports.changeAddress = "Cambiar";
exports.addressNameLabel = "Nombre";
exports.addressTypeLabel = "Tipo de Dirección";
exports.flatNoLabel = "Número de Departamento";
exports.landmarkLabel = "Punto de Referencia";
exports.addressLabel = "Dirección";
exports.addressLine2Label = "Dirección Línea 2";
exports.cityLabel = "Ciudad";
exports.stateLabel = "Estado";
exports.countryLabel = "País";
exports.zipcodeLabel = "Código Postal";
exports.createNewAddressText = "Crear Nueva Dirección";
exports.addressTypePlaceholder = "Por favor ingresa el tipo de dirección*";
exports.namePlaceholder = "Por favor ingresa el nombre de la dirección*";
exports.flatNoPlaceholder = "Por favor ingresa el número de departamento*";
exports.zipCodePlaceholder = "Por favor ingresa el código postal*";
exports.addressPlaceholder = "Por favor ingresa la dirección*";
exports.addressLine2Placeholder = "Por favor ingresa la dirección línea 2";
exports.landmarkPlaceholder = "Por favor ingresa el punto de referencia";
exports.cityPlaceholder = "Por favor ingresa la ciudad";
exports.statePlaceholder = "Por favor ingresa el estado";
exports.countryPlaceholder = "Por favor ingresa el país";
exports.phoneNoPlaceholder = "Por favor ingresa el número de teléfono*";
exports.selectThisAddressBtn = "Seleccionar esta dirección";
exports.addressNavigationAlert = "ID de Orden no presente";
exports.emptyOrderListMsg = "La lista de órdenes está vacía";
exports.catalogueIdPlaceholder = "Por favor ingresa el ID del catálogo";
exports.catalogueVariantIdPlaceholder = "Por favor ingresa el ID de la variante del catálogo";
exports.quantityPlaceholder = "Por favor ingresa la cantidad";
exports.createNewOrderTitle = "Crear Nueva Orden";
exports.orderManagementLabel = "Administración de Órdenes";

exports.cartAPi = "/bx_block_shopping_cart/shopping_cart";
exports.mulesoftApi = "/bx_block_order_management/mulesoft_orders";
exports.postShoppingCart = "/bx_block_shopping_cart/shopping_cart";
exports.cartTotalSample = 30;
exports.colorPrimary="#004E9E";
exports.colorError="#DC2626";
exports.thankyouText = "Gracias por comprar";
exports.buttonBackToHome = "Regresar a Inicio";
exports.orderNo = "Número de Orden";
exports.deliveryDate = "Fecha de Entrega";
exports.deliveryAddress = "Dirección de Entrega";
exports.processingText = "Procesando tu orden";
exports.addedToCartSuccess = "Ítem agregado al carrito exitosamente";
exports.minVolume = "minimum_value";
exports.orderApi = "bx_block_order_management/mulesoft_orders"

// Customizable Area End
