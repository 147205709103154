Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.deleteApiMethod = 'DELETE';
exports.putApiMethod = 'PUT';

exports.getOrdersApiEndPoint = 'shopping_cart/orders';
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items';
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders';
exports.increase = 'increase';
exports.decrease = 'decrease';
exports.getCartItems = 'bx_block_shopping_cart/shopping_cart';
exports.deleteSelectedCartItems = 'bx_block_shopping_cart/shopping_cart/batch_delete?ids=';

exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'Todos los campos son obligatorios.';
exports.modalLabel = "¿Estás seguro de que quieres eliminar el “nombre del artículo” del carrito?";
exports.modalBtnLabelRemove = "Eliminar artículo";
exports.modalBtnLabelKeep = "Mantener en el carrito";
exports.imgDefaultProduct = "assets/product.png";
exports.imgSuccess = "assets/successRemove.png";
exports.labelTitle = 'Carrito de compras';
exports.remove = "Eliminar";
exports.description = 'Descripción';
exports.quantity = 'Cantidad';
exports.buttonRemoveLabel = "Eliminar en bloque";
exports.labelTotal = "Total de productos: ";
exports.labelCheckoutBtn = "Ir a pagar";
exports.itemString = "Artículos";
exports.successRemove = "Tus artículos se han eliminado correctamente";
exports.labelOkButton = 'Aceptar';
exports.backgroundColor = "#F6F6F6";
exports.backgroundCard = "#ffffff";
exports.colorRemove = "#64748B";
exports.colorWithe = "#ffffff";
exports.bluePrimary = "#004E9E";
exports.hoverBlueButton = "#0812a0";
exports.buttonBluDisable = "#3B82F6";
exports.buttonKeep = "#4B7FC2"
// Customizable Area End
