// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
} from "@material-ui/core";
// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import OrderDetailsController, {
  Product,
  Props,
} from "./OrderDetailsController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import FooterWeb from "../../../components/src/Footer.web";
import { ThemeProvider } from "react-native-elements";
import SuccessModal from "../../../components/src/SuccessModal";
import { truncateTextToTenWords } from "../../../components/src/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

const config = require("./config");

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
})

const OrderDetailsWrapper = styled(Box)(({ theme }) => ({
  padding: '70px 40px 175px 40px',
  width: '100%',
  maxWidth: 1440,
  boxSizing: 'border-box',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    padding: '50px 25px 135px 25px',
  },
  '& .btn': {
    border: 0,
    cursor: 'pointer',
  },
  '& .order-history-sections': {
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
    marginBottom: 67,
  },
  '& .title': {
    color: '#0F172A',
    fontFamily: "Arial, sans-serif",
    '&.main-titles': {
      fontSize: 30,
      letterSpacing: "-0.15px",
      marginBottom: 48,
      fontWeight: 700,
      lineHeight: "40px",
      [theme.breakpoints.down('sm')]: {
        marginBottom: 26,
        fontSize: 24,
      },
    },
    '&.sub-title': {
      fontFamily: "'Arial', sans-serif",
      fontSize: 24,
      letterSpacing: "-0.12px",
      fontWeight: 700,
      lineHeight: "32px",
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
  },
  '& .order': {
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 25,
    },
  },
  '& .orders-header': {
    display: 'flex',
    alignItems: 'center',
    padding: '40px 20px',
    justifyContent: 'space-between',
    background: '#fff',
    '@media(max-width: 550px)': {
      alignItems: 'stretch',
      gap: 15,
      flexDirection: 'column',
    },
  },
  '& .order-metadatas': {
    gap: '48px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '0',
      flexDirection: 'column',
    },
  },
  '& .order-info': {
    display: 'flex',
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 8,
    flexDirection: 'column',
    color: "#0F172A",
    fontFamily: "'Arial', sans-serif",
    width: "204px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    '&.order-info-address-data': {
      flexBasis: '33%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'auto',
      },
    },
  },
  '& .order-info-title-data': {
    flexShrink: 0,
    textWrap: 'nowrap',
    fontWeight: 700,
  },
  '& .order-info-value-data': {
    fontWeight: 400,
  },
  '& .order-reorder-btn': {
    marginTop: 'auto',
    alignSelf: 'start',
    fontWeight: 700,
    padding: '16px 26px',
    backgroundColor: "#004E9E",
    color: "#fff",
    fontSize: 16,
    borderRadius: '8px',
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: '#9f9f9f',
      '&:hover': {
        backgroundColor: '#9f9f9f',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: '10px 18px',
      flexBasis: 'auto',
    },
  },
  '& .product-wrapper-data': {
    borderTop: '1px #dedede solid',
    display: 'flex',
    padding: 40,
    background: '#fff',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 18,
      padding: 20,
      alignItems: 'start',
    },
  },
  '& .product-photo-data': {
    width: "180px",
    height: "180px",
    backgroundRepeat: " no-repeat",
    marginRight: 32,
    backgroundColor: '#e6e6e6',
    backgroundPosition: "center center",
    backgroundSize: "cover",
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  '& .product-details-data': {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
  },
  '& .product-name-data': {
    lineHeight: "22px",
    marginBottom: 8,
    color: "#0F172A",
    fontFamily: "'Arial', sans-serif",
    fontSize: 14,
    fontWeight: 700,
  },
  '& .product-type-data': {
    fontWeight: 400,
    lineHeight: "22px",
    marginBottom: 8,
    color: "#64748B",
    fontFamily: "'Arial', sans-serif",
    fontSize: 14,
  },
  '& .product-description-data': {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 12,
    color: "#64748B",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "22px",
  },
  '& .quantity-container-data': {
    fontFamily: "'Arial', sans-serif",
    lineHeight: "22px",
    marginBottom: 12,
    color: "#0F172A",
    display: 'flex',
    alignItems: "center",
    fontSize: 14,
    fontWeight: 700,
  },
  '& .not-found-text-data': {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "'Arial', sans-serif",
    color: "#0F172A",
  },
  '& .loader-data': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default class OrderHistory extends OrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { isLoading, singleOrder } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu isAddtoCart={!!this.state.cartProductIds.length} navigation={this.props.navigation} id="navigation-menu-address" data-testId="product-ids" />
        <div style={{ background: '#F7F7F7', flex: 1, }}>
          <OrderDetailsWrapper>
            <section className="order-history-sections" data-testId="order-history-section">
              <div className="title main-titles" data-test="title">Detalles del pedido</div>
              {!isLoading ?
                <div className="order-list" data-testId="order-list">
                  {(Array.isArray(singleOrder) && singleOrder.length === 0) ? <div className="not-found-text-data">No se encontraron pedidos anteriores.</div> : 
                  <div className="order" data-testId="order-test">
                    {singleOrder.map((product:Product) => {
                      return (<div className="product-wrapper-data" key={product.productId}>
                        <div className="product-photo-data" style={{ backgroundImage: `url(${product.productPhoto})` }}></div>
                        <div className="product-details-data">
                          <div className="product-name-data">{product.productName}</div>
                          <div className="product-type-data">{product.productBrand}</div>
                          <div className="product-description-data">Descripción: {truncateTextToTenWords(product.productDescription)}</div>
                          <div className="quantity-container-data">
                            <div className="quantity-text">Cantidad :&nbsp;</div>
                            <div className="quantity-number">{product.productQty}</div>
                          </div>
                          <button
                            title={this.getProductTitle(product.productId)}
                            onClick={() => {this.reorderSingleProduct(product.productId, product.productQty)}}
                            className="order-reorder-btn btn"
                            data-testId="order-reorder-btns"
                          >Volver a Ordenar</button>
                        </div>
                      </div>)
                    }
                    )}
                  </div>}
                </div>
                : <div className="loader-data"><CircularProgress /></div>}
            </section>
          </OrderDetailsWrapper>
          </div>
        <FooterWeb />
        <SuccessModal data-testId="close-btn" open={!!this.state.successMessage} onClose={() => this.handleClose()} confirmText={this.state.successMessage} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area End
