Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Correo electrónico no válido.";
exports.errorPasswordNotValid = "Contraseña no válida.";
exports.placeHolderEmail = "Correo electrónico";
exports.placeHolderPassword = "Contraseña";
exports.labelHeader = "La razón por la que requerimos inicio de sesión";
exports.btnTxtLogin = "INICIAR SESIÓN";
exports.labelRememberMe = "Recuérdame";
exports.btnTxtSocialLogin = "INICIAR SESIÓN CON REDES SOCIALES";
exports.labelOr = "O";
exports.labelTitle = "Iniciar Sesión";
exports.newMember= "¿Nuevo miembro?"
exports.signUp="Regístrate"
exports.forgotPassword="¿Olvidaste tu contraseña?"
exports.btnLogin = "Iniciar Sesión"
exports.wrongPassword="Contraseña incorrecta"
exports.noAccount = "No hay una cuenta registrada con este correo electrónico"
exports.invalidAccount = "El correo electrónico es inválido"
exports.isRemember = "remember_me"
exports.refreshToken = "refresh_token"
exports.emptyEmailPassword = "Por favor, completa todos los campos requeridos antes de enviar el formulario"
exports.colorPrimary="#004E9E"
exports.colorError="#DC2626"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.sloganTitle = "Estamos donde tú estás";
exports.sloganContent= "Nuestras 4 plantas de producción y 15 depósitos están <br/> estratégicamente ubicados en todo Estados Unidos <br/> asegurando a nuestros clientes proximidad de suministro."
exports.title3 = "Confirmación de correo electrónico";
exports.subTitle3 = "¡Tu correo electrónico ha sido activado exitosamente! Por favor, inicia sesión para continuar.";
exports.backTo = "Volver a";
exports.logIn = "Iniciar sesión";
exports.resendEmail = "Reenviar correo electrónico";
exports.minimumValue = "minimum_value"
// Customizable Area End