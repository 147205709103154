// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  userName: string;
}

interface S {}

interface SS {
  id: any;
}

export default class Emailnotifications2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  
  constructor(props: Props) {
    super(props);
  }

}

// Customizable Area End