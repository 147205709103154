import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Typography,
  InputAdornment,
  Grid,
  ButtonBase,
  IconButton,
  SwipeableDrawer,
  Paper,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { icCart, icSettings, logo } from "./assets";
import MenuIcon from '@material-ui/icons/Menu';
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  list = () => (
    <Box
      data-test-id="tst-drawer"
      className="left"
      style={{
        width: 250,
      }}
    >
      <Box style={webStyle.userProfileWrapper}>
        <ButtonBase data-test-id="ic-logo" onClick={this.goHome}>
          <img src={logo} style={{ objectFit: "contain", marginBottom: 15 }} alt="" />
        </ButtonBase>
        {this.searchBox({ defaultWidth: "90%" })}
        {this.groupHome({ ...webStyle.drawerStyle, marginTop: 15, marginBottom: 15 })}
        <Box style={{...webStyle.gridStyle, marginBottom: 15, marginTop: 15, }}>
          <ButtonBase data-test-id="ic-settings" onClick={this.gotoSettings}>
            <Typography style={webStyle.navItemMobile}>{configJSON.settings}</Typography>
          </ButtonBase>
        </Box>
        <Box style={{...webStyle.gridStyle, position: "relative", marginBottom: 15, marginTop: 15, }}>
          <ButtonBase data-test-id="ic-cart" onClick={this.gotoCart}>
            <Typography style={webStyle.navItemMobile}>{configJSON.cart}</Typography>
          </ButtonBase>
        </Box>
        <Box style={{...webStyle.gridStyle, marginBottom: 15, position: "relative", marginTop: 15, }}>
          <ButtonBase onClick={this.goOrderHistory} data-test-id="ic-order-history" >
            <Typography style={webStyle.navItemMobile}>{configJSON.orderHistory}</Typography>
          </ButtonBase>
        </Box>
        <Box style={{ ...webStyle.gridStyle, margin: "15px 0" }}>
          <ButtonBase data-test-id="btn-logout" onClick={this.handleToggleDialog}>
            <Typography style={webStyle.navItemMobile}>{configJSON.logout}</Typography>
          </ButtonBase>
        </Box>
      </Box>

    </Box>
  );

  groupHome = (styles: any) => {
    return <Box style={styles}>
      <ButtonBase data-test-id="btn-home" onClick={this.goHome}>
        <Typography
          style={{
            ...webStyle.navItem,
            marginLeft: this.state.width > 960 ? 30 : 20,
            marginRight: 20,
            marginTop: styles.marginTop ? styles.marginTop : "auto",
            marginBottom: styles.marginBottom ? styles.marginBottom : "auto"
          }}>
          {configJSON.home}
        </Typography>
      </ButtonBase>
    </Box>
  }
  CustomPaper = (props: any) => {
    return <Paper {...props} style={{borderRadius: 16, padding: "0 20px"}} elevation={3} />
  }

  searchBox = (settings: any) => {
    return <Autocomplete
      id="search-autocomplete"
      freeSolo
      disableClearable
      onChange={(event, value) => { this.selectSearchItem(value) }}
      onInputChange={(event, value) => { this.onChangeSearchValue(value) }}
      options={this.state.listSuggestions}
      renderOption={(option, state) => {
        return <Box style={{ width: "100%", borderBottom: this.isLastOption(option) ? "none" : "1px solid #E2E8F0", padding: "7px 0" }}>
          <Typography style={webStyle.option}>{option}</Typography>
        </Box>
      }}
      style={{
        display: "flex",
        justifyContent: this.state.width > 600 ? "end" : "center",
        marginRight: this.state.width > 600 ? 10 : 0
      }}
      getOptionLabel={(option) => option}
      PaperComponent={this.CustomPaper}
      onKeyDown={(event) => { this.handleEnterSearch(event) }}
      renderInput={(params) => {
        return <Input data-test-id="txtSearch"
          placeholder={"Buscar"}
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          disableUnderline
          style={{
            border: "1px solid #CBD5E1",
            padding: "5px 0",
            borderRadius: 98,
            width: this.state.width > 960 ? 360 : settings.defaultWidth,
            fontSize: 16
          }}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ color: "#94A3B8", padding: "0 5px" }} />
            </InputAdornment>
          }
        />
      }}
    />
  }

  DialogConfirmLogout = () => {
    return <Dialog fullWidth open={this.state.openDialog} onClose={this.handleToggleDialog}>
      <DialogContent dividers>
        <IconButton style={{ position: "absolute", right: 0, top: 0 }} aria-label="close" onClick={this.handleToggleDialog}>
          <CloseIcon />
        </IconButton>
        <Box style={{ marginTop: 20, marginBottom: 20 }}>
          <Typography align="center" style={{ ...webStyle.navItem, fontSize: 18 }}>
            {configJSON.confirmLogoutText}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button data-test-id="btn-no" style={{ ...webStyle.dialogButton as React.CSSProperties, backgroundColor: "#4B7FC2", opacity: 0.6 }}
          onClick={this.handleToggleDialog} >
          No
        </Button>
        <Button data-test-id="btn-yes" style={{ ...webStyle.dialogButton as React.CSSProperties, backgroundColor: "#004E9E" }}
          onClick={this.handleLogout} >
          Sí
        </Button>
      </DialogActions>
    </Dialog>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            { 
            this.state.width < 640 ?
                <Box style={{display: "flex", alignItems: "center", width: "100%"}}>
                  <ButtonBase data-test-id="ic-logo" onClick={this.goHome}>
                    <img src={logo} style={{ objectFit: "contain" }} alt="" />
                  </ButtonBase>
                  <Box style={{...webStyle.gridStyle, position: "relative", marginLeft: "auto", marginRight: 25 }}>
                    <ButtonBase onClick={this.gotoCart} data-test-id="ic-cart">
                      <img src={icCart} style={webStyle.iconStyle as React.CSSProperties} alt="" />
                    </ButtonBase>
                    {!this.state.isEmptyCart && <Box style={webStyle.dotIcon as React.CSSProperties}></Box>}
                  </Box>
                  <IconButton data-test-id={"btnToggleDraw"}
                    onClick={this.toggleDrawer} edge="start" color="inherit" aria-label="menu">
                    <MenuIcon />
                  </IconButton>
                </Box>
            :
            <Grid container>
                  <Grid item sm={3} md={3} style={webStyle.gridStyle}>
                    <ButtonBase data-test-id="ic-logo" onClick={this.goHome}>
                      <img src={logo} style={{ objectFit: "contain" }} alt="" />
                    </ButtonBase>
                    {this.groupHome(webStyle.gridStyle)}
                    <ButtonBase
                      data-test-id="btn-order-history"
                      onClick={this.goOrderHistory}
                      style={{}}
                    >
                      <Typography
                        style={{
                          whiteSpace: 'nowrap',
                          ...webStyle.navItem,
                          marginRight: 20,
                          marginLeft: this.state.width > 960 ? 30 : 20,
                          marginBottom: "auto",
                          marginTop: "auto",
                        }}>
                        {configJSON.orderHistory}
                      </Typography>
                    </ButtonBase>
                  </Grid>
              <Grid item sm={6} md={7} style={{ ...webStyle.gridStyle, justifyContent: "end" }}>
                {this.searchBox({defaultWidth: "80%"})}
              </Grid>
              <Grid item sm={3} md={2} style={{ ...webStyle.gridStyle, justifyContent: "end" }}>
                <ButtonBase data-test-id="ic-settings" onClick={this.gotoSettings}>
                  <img src={icSettings} style={webStyle.iconStyle as React.CSSProperties} alt="" />
                </ButtonBase>
                <ButtonBase data-test-id="ic-cart" onClick={this.gotoCart} style={{marginRight: 20, marginLeft: 20, position: "relative"}}>
                  {!this.state.isEmptyCart && <Box style={webStyle.dotIcon as React.CSSProperties}></Box>}                 
                  <img src={icCart} style={webStyle.iconStyle as React.CSSProperties} alt="" />
                </ButtonBase>
                <ButtonBase data-test-id="btn-logout" onClick={this.handleToggleDialog}>
                  <Typography style={webStyle.navItem}>{configJSON.logout}</Typography>
                </ButtonBase>
              </Grid>

            </Grid>}
          </Box>
          <SwipeableDrawer
            anchor={"right"}
            open={this.state.webDrawer}
            onClose={this.toggleDrawer}
            onOpen={this.toggleDrawer}
          >
            {this.list()}
          </SwipeableDrawer>
        </Container>
        <Divider style={{ backgroundColor: "#E2E8F0"}} />
        {this.DialogConfirmLogout()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontFamily: 'Arial, sans-serif'
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiPaper: {
      "root": {
        "& .MuiAutocomplete-option": {
          padding: 0
        }
      }
    },
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  dialogButton: {
    textTransform: "none", 
    color: "#FFF",
    borderRadius: 8,
    fontWeight: 700,
    fontFamily: "Arial, sans-serif",
    padding: 10, 
    width: 120,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  option: {
    fontFamily: "Arial, sans-serif",
    fontSize: 16, 
    color: "#334155",
    lineHeight: "18px"
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
  iconStyle: {
    objectFit: "none",
  },
  gridStyle: {
    display: "flex",
    alignItems: "center"
  },
  drawerStyle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  navItem: {
    fontWeight: 700,
    fontSize: 12, 
    lineHeight: "18px",
    color: configJSON.colorNeutral,
    cursor: "pointer",
    fontFamily: "Arial, sans-serif"
  },
  navItemMobile: {
    fontSize: 12, 
    fontWeight: 700,
    color: configJSON.colorNeutral,
    lineHeight: "18px",
    fontFamily: "Arial, sans-serif",
    cursor: "pointer",
    margin: "15px 0",
  },
  dotIcon: {
    width: 8, 
    height: 8,
    borderRadius: 100, 
    backgroundColor: "#DC2626",
    position: "absolute", 
    top: -3, 
    right: -5
  }
};
// Customizable Area End
