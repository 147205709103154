// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import VerifyEmail from "../../blocks/forgot-password/src/VerifyEmail.web";
import UpdatePassword from "../../blocks/forgot-password/src/UpdatePassword.web";
import EmailConfirmation from "../../blocks/email-account-login/src/EmailConfirmation.web"; 
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import AddressManagement from "../../blocks/addressmanagement/src/AddressManagement.web";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import WelcomeEmailTemplate from "../../blocks/emailnotifications2/src/WelcomeEmailTemplate.web";
import OrderEmailTemplate from "../../blocks/emailnotifications2/src/OrderEmailTemplate.web";
import Cfmulesoftapiintegrationviewhistory from "../../blocks/cfmulesoftapiintegrationviewhistory/src/Cfmulesoftapiintegrationviewhistory";
import Cfmulesoftapiintegrationordercreation from "../../blocks/cfmulesoftapiintegrationordercreation/src/Cfmulesoftapiintegrationordercreation";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Settings2 from "../../blocks/settings2/src/Settings2.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OrderManagementWeb from "../../blocks/ordermanagement/src/OrderManagementWeb.web"
import OrderError from "../../blocks/ordermanagement/src/OrderError.web";
import OrderReview from "../../blocks/ordermanagement/src/OrderReview.web";
import OrderHistory from "../../blocks/ordermanagement/src/OrderHistory.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails.web";



const routeMap = {
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ResetPassword,
path:"/forgotten-password"},
VerifyEmail:{
  component:VerifyEmail,
 path:"/verify-recovery-email"},
EmailConfirmation:{
  component:EmailConfirmation,
  path:"/verify-email"},
UpdatePassword:{
  component:UpdatePassword,
 path:"/password-reset"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
AddressManagement:{
 component:AddressManagement,
path:"/order/shipping-address"},
OrderError:{
  component:OrderError,
 path:"/order/error"},
OrderReview:{
  component:OrderReview,
 path:"/order/review"},
OrderHistory:{
  component:OrderHistory,
 path:"/order/history"},
 OrderDetails:{
  component:OrderDetails,
 path:"/order/details/:navigationBarTitleText"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Catalogue:{
 component:Catalogue,
path:"/",
exact: true},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
WelcomeEmailTemplate:{
  component:WelcomeEmailTemplate,
 path:"/templates/welcome"},
OrderEmailTemplate:{
  component:OrderEmailTemplate,
 path:"/templates/order"},
Cfmulesoftapiintegrationviewhistory:{
 component:Cfmulesoftapiintegrationviewhistory,
path:"/Cfmulesoftapiintegrationviewhistory"},
Cfmulesoftapiintegrationordercreation:{
 component:Cfmulesoftapiintegrationordercreation,
path:"/Cfmulesoftapiintegrationordercreation"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Settings2:{
 component:Settings2,
path:"/settings"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/signup"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:OrderReview,
path:"/cart"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
OrderManagement:{
 component:OrderManagementWeb,
path:"/order/process"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ProductDescription:{
  component:ProductDescription,
 path:"/product/:id"},
Search:{
  component:Catalogue,
 path:"/search"},


  Home: {
    component: HomeScreen,
    path: '/home',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

  
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: 'auto', minHeight: "100vh", width: '100vw', overflowX: "hidden" }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;