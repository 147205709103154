import React from "react";

import {
  Container,
  Box,
  Typography,
  IconButton,
  // Customizable Area Start
  Divider,
  Menu,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddAddress from "../../addressmanagement/src/AddAddress.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
});
import FooterWeb from "../../../components/src/Footer.web";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddress = (address: any) => {
    return address.attributes.mulesoft_id ?
      <Typography style={{ ...webStyle.commonText }}>{address.attributes.mulesoft_address}</Typography>
      :
      <Box>
        <Typography style={{ ...webStyle.commonText }}>
          {address.attributes.building_number}, {address.attributes.street}, {address.attributes.city}
        </Typography>
        <Typography style={{ ...webStyle.commonText }}>
          {address.attributes.country} - {address.attributes.zip_code}
        </Typography>
      </Box>
  }
  renderInformation = (address: any) => {
    return <Box key={address.id} position={"relative"} style={{ marginTop: 30 }}>
      <Box style={{ paddingLeft: 20 }}>
        <Box style={{ ...webStyle.flexBox }}>
          <Typography style={{ ...webStyle.commonText, ...webStyle.fontWeightBold }}>
            {address.attributes.first_name} {address.attributes.last_name}
          </Typography>
          {address.attributes.is_default && 
          <Box style={webStyle.btnDefault as React.CSSProperties}>
            <Typography style={{fontWeight: 500, fontSize: 12}}>{configJSON.default}</Typography>
          </Box>}
        </Box>
        {this.renderAddress(address)}
      </Box>
      <IconButton data-test-id="btn-menu" onClick={(event) => this.handleClickMenu(event, address.id)} 
        style={webStyle.menuIcon as React.CSSProperties}>
        <MoreVertIcon />
      </IconButton>
      <Divider style={{ marginTop: 20 }} />
    </Box>
  }
  renderMenu = () => {
    return <Menu
      TransitionProps={{ style: { width: 192, padding: "0 4px", marginTop: 5, borderRadius: 8 } }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorEl={this.state.menuAnchol}
      keepMounted
      onClose={this.handleCloseMenu}
      id="menu-test"
      open={Boolean(this.state.menuAnchol)}>
      {!this.state.isMulsoftUser && 
      <MenuItem data-test-id="btn-edit" onClick={this.handleClickAdd} style={{...webStyle.flexBox}}>
        <EditOutlinedIcon style={webStyle.menuOptionIcon} />
        <Typography style={{ ...webStyle.commonText, ...webStyle.menuOptionText }}>{configJSON.edit}</Typography>
      </MenuItem>}
      <MenuItem data-test-id="btn-set-as-default" onClick={this.setAsDefault} style={{...webStyle.flexBox}}>
        <CheckCircleOutlineOutlinedIcon style={webStyle.menuOptionIcon} />
        <Typography style={{ ...webStyle.commonText, ...webStyle.menuOptionText }}>{configJSON.setDefault}</Typography>
      </MenuItem>
      {!this.state.isMulsoftUser && 
      <MenuItem data-test-id="btn-delete" onClick={this.handleDeleteAddress} style={{ ...webStyle.flexBox, color: "#DC2626" }}>
        <DeleteOutlineOutlinedIcon style={webStyle.menuOptionIcon} />
        <Typography style={{ ...webStyle.commonText, ...webStyle.menuOptionText, color: "#DC2626" }}>
          {configJSON.delete}</Typography>
      </MenuItem>}
    </Menu>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu id="setting-navigation-menu" navigation={this.props.navigation} />
        <Container maxWidth={"xl"} style={{flex: 1}}>
            <Box style={webStyle.contentContainer}>
              <Typography style={webStyle.pageTitle}>{configJSON.labelTitleText}</Typography>
              <Box style={{marginTop: 30}}>
                <Box style={{...webStyle.flexBox, justifyContent: "space-between"}}>
                  <Typography style={{...webStyle.commonText,...webStyle.fontWeightBold, color: "#64748B"}}>{configJSON.subTitle}</Typography>
                  {!this.state.isMulsoftUser &&
                  <Typography 
                   data-test-id = "btn-add-address"
                   onClick = {this.handleClickAdd}
                   style={{...webStyle.commonText,
                    ...webStyle.fontWeightBold,
                     color: "#004E9E",
                     fontSize: 14,
                     cursor: "pointer",
                     textAlign: "end"
                    }}>{configJSON.addNewAddress}</Typography>}
                </Box>
               {this.state.listAddress.map((address:any) => this.renderInformation(address)) }
              </Box>
            </Box>
            {this.renderMenu()}
        </Container>
        <AddAddress
          id="add-address"
          editId={this.state.selectedId}
          navigation={this.props.navigation}
          openDialog={this.state.openAddAddress}
          handleCloseDialog={this.handleCloseAdd}
          loadAddresses={this.getListAddress} />
        <FooterWeb />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
 contentContainer : {
  padding: "70px 10px",
 },
 pageTitle: {
  fontWeight: 700, 
  lineHeight: "32px", 
  fontSize: 24,
  color: "#0F172A"
 },
 flexBox: {
  display: "flex", 
  alignItems: "center",
 },
 commonText: {
  fontSize: 16, 
  fontWeight: 400,
  color: "#0F172A",
  paddingTop: 5,
  paddingBottom: 5
 },
 fontWeightBold: {
  fontWeight: 700
 },
 menuIcon: {
  position: "absolute",
  top: 0,
  right: 0,
 },
 menuOptionIcon: {
  marginRight: 10,
  width: 16
},
menuOptionText: {
  fontSize: 14,
  padding: 0
},
btnDefault:{
  backgroundColor: "#004E9E",
  textTransform: "uppercase",
  color: "#FFF",
  borderRadius: 40, 
  marginLeft: 15,
  padding: "3px 10px"
}
};
// Customizable Area End
