// Customizable Area Start
import React from "react";
import { facebookIcon, twitterIcon, instagramIcon, linkedInIcon, welcomeEmailBanner } from "./assets";
import WelcomeEmailTemplateController, {
  Props,
} from "./WelcomeEmailTemplateController";

export default class WelcomeEmailTemplate extends WelcomeEmailTemplateController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { userName = 'John Doe' } = this.props;

    return (
      <div>
        <style type="text/css">
          {`
            @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')

            @media only screen and (max-width: 600px) {
              .email-container {
                width: 100% !important;
                max-width: none !important;
              }
              .button {
                padding: 16px 10% !important; /* Adjust the padding for smaller screens */
              }
              .social-icon {
                margin-right: 25px !important;
              }
              .social-icon:last-child {
                margin-right: 0 !important;
              }
              .instagram-icon img {
                width: 19px !important;
                padding: 3 !important;
              }
            }
          `}
        </style>
        <table className="email-container" style={{ width: '100%', padding: '0', borderSpacing: '0', borderCollapse: 'collapse' }}>
          <tr>
            <td align="center">
              {/* Image Banner */}
              <table className="email-container" style={{ width: '100%', maxWidth: "600px", padding: '0', borderSpacing: '0' }}>
                <tr>
                  <td style={{ padding: 0 }}>
                    <img src={welcomeEmailBanner} alt="FUCHS Banner" style={{ display: 'block', width: '100%', maxWidth: '600px' }} />
                  </td>
                </tr>
              </table>

              {/* Welcome Text & Button */}
              <table className="email-container" style={{ width: '100%', maxWidth: "600px", padding: '0', borderSpacing: '0', borderCollapse: 'collapse', marginBottom: 38, backgroundColor: '#ffffff' }}>
                <tr>
                  <td align="center" style={{ padding: '25px' }}>
                    <p style={{ fontFamily: 'Arial, sans-serif', color: '#525F7F', fontSize: '16px', fontWeight: 400, lineHeight: "24px", marginBottom: '25px' }}>
                      Hi {userName}, You have successfully created your account.
                    </p>
                    <p style={{ letterSpacing: "-0.15px", fontWeight: 700, color: '#1C1E23', fontFamily: 'Arial, sans-serif', lineHeight: "40px", marginBottom: '25px', fontSize: '30px' }}>
                      Thanks for Joining
                    </p>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 400, lineHeight: "24px", color: '#525F7F', fontSize: '16px', marginBottom: '25px' }}>
                      Now you can explore our products.
                    </p>
                    <a
                      href="#"
                      target="_blank"
                      className="button"
                      style={{ lineHeight: "24px", fontWeight: 700, fontFamily: '"Arial", sans-serif', display: 'inline-block', padding: '16px 120px', color: '#ffffff', backgroundColor: '#004E9E', textDecoration: 'none', borderRadius: '8px', fontSize: '16px' }}
                    >
                      Start Exploring
                    </a>
                  </td>
                </tr>
              </table>

              {/* Company Info & Social Media Links */}
              <table className="email-container" style={{ width: '100%', maxWidth: "600px", padding: '0', borderSpacing: '0', borderCollapse: 'collapse', backgroundColor: '#376BAE' }}>
                <tr>
                  <td align="center" style={{ padding: '24px' }}>
                    <p style={{ fontFamily: 'Arial, sans-serif', color: '#FFF', fontSize: '12px', fontWeight: 400, lineHeight: "20px", marginBottom: '15px' }}>
                      &copy; FUCHS. All rights reserved.
                    </p>
                    <p style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Arial, sans-serif', color: '#FFF', lineHeight: "20px", marginBottom: '15px' }}>
                      If you have any questions please contact us <a style={{ fontWeight: "bold", textDecoration: "none", color: "#fff" }} href="mailto:Inquiry@fuchs.com" target="_blank">Inquiry@fuchs.com</a>
                    </p>
                    {/* Social Media Icons */}
                    <p>
                      <a style={{ display: 'inline-block', marginRight: '24px' }} href="https://www.facebook.com/fuchslubricantsco" className="social-icon" target="_blank">
                        <img src={facebookIcon} alt="Facebook Icon" width="24" />
                      </a>
                      <a href="https://www.linkedin.com/company/fuchs-lubricants/" className="social-icon" style={{ display: 'inline-block', marginRight: '24px' }} target="_blank">
                        <img src={linkedInIcon} alt="LinkedIn Icon" width="24" />
                      </a>
                      <a target="_blank" href="https://twitter.com/fuchs_us" className="social-icon" style={{ display: 'inline-block', marginRight: '24px' }}>
                        <img src={twitterIcon} alt="Twitter Icon" width="24" />
                      </a>
                      <a href="https://www.instagram.com/fuchslubricantsus/" className="social-icon instagram-icon" style={{ display: 'inline-block' }} target="_blank">
                        <img src={instagramIcon} alt="Instagram Icon" width="18" style={{ display: 'inline-block', padding: '3px' }} />
                      </a>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

// Customizable Area End
