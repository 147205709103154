import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// ScrollToTopProps type will extend RouteComponentProps to get the router props
type ScrollToTopProps = RouteComponentProps;

class ScrollToTop extends React.Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);