// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import OrderHistoryController, {
  Props,
} from "./OrderHistoryController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import FooterWeb from "../../../components/src/Footer.web";
import Toast from "../../../components/src/Toast";
import { ThemeProvider } from "react-native-elements";
import CircularProgress from '@material-ui/core/CircularProgress';

const config = require("./config");

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
})

const OrderHistoryWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  margin: '0 auto',
  padding: '70px 40px 175px 40px',
  width: '100%',
  maxWidth: 1440,
  [theme.breakpoints.down('md')]: {
    padding: '50px 25px 135px 25px',
  },
  '& .btn': {
    border: 0,
    cursor: 'pointer',
  },
  '& .order-history-section': {
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
    marginBottom: 67,
  },
  '& .title': {
    color: '#0F172A',
    fontFamily: "Arial, sans-serif",
    '&.main-title': {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: "40px",
      letterSpacing: "-0.15px",
      marginBottom: 48,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        marginBottom: 26,
      },
    },
    '&.sub-title': {
      fontFamily: "'Arial', sans-serif",
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
  },
  '& .order': {
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 25,
    },
  },
  '& .order-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '40px 20px',
    background: '#fff',
    borderBottom: '1px #dedede solid',
    '@media(max-width: 550px)': {
      alignItems: 'stretch',
      flexDirection: 'column',
      gap: 15,
    },
  },
  '& .order-metadata': {
    display: 'flex',
    gap: '48px',
    [theme.breakpoints.down('sm')]: {
      gap: '0',
      flexDirection: 'column',
    },
  },
  '& .order-info': {
    display: 'flex',
    flexDirection: 'column',
    color: "#0F172A",
    fontFamily: "'Arial', sans-serif",
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 8,
    width: "204px",
    gap: "10px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    '&.order-info-address': {
      flexBasis: '33%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'auto',
      },
    },
  },
  '& .order-info-title': {
    fontWeight: 700,
    flexShrink: 0,
    textWrap: 'nowrap',
  },
  '& .order-info-value': {
    fontWeight: 400,
  },
  '& .order-reorder-btn': {
    marginTop: 'auto',
    alignSelf: 'start',
    backgroundColor: "#004E9E",
    color: "#fff",
    fontSize: 16,
    fontWeight: 700,
    padding: '16px 26px',
    borderRadius: '8px',
    '&:disabled': {
      backgroundColor: '#9f9f9f',
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: '#9f9f9f',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'auto',
      fontSize: 14,
      padding: '10px 18px',
    },
  },
  '& .product-wrapper': {
    padding: 40,
    background: '#fff',
    borderTop: '1px #dedede solid',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 20,
      alignItems: 'start',
      gap: 18,
    },
  },
  '& .product-photo': {
    width: "180px",
    height: "180px",
    backgroundRepeat: " no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    marginRight: 32,
    backgroundColor: '#e6e6e6',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  '& .product-details': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .product-name': {
    color: "#0F172A",
    fontFamily: "'Arial', sans-serif",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: 8,
  },
  '& .product-type': {
    color: "#64748B",
    fontFamily: "'Arial', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px",
    marginBottom: 8,
  },
  '& .product-description': {
    color: "#64748B",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "22px",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 12,
  },
  '& .quantity-container': {
    fontFamily: "'Arial', sans-serif",
    color: "#0F172A",
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    alignItems: "center",
    lineHeight: "22px",
    marginBottom: 12,
  },
  '& .not-found-text': {
    fontFamily: "'Arial', sans-serif",
    color: "#0F172A",
    fontSize: 20,
    fontWeight: 700,
  },
  '& .loader': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default class OrderHistory extends OrderHistoryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { pastOrders, isLoading } = this.state;
    let content;

    content = (
      <OrderHistoryWrapper>
        <section className="order-history-section">
          <div className="title main-title" data-test="title">Historial de Pedidos</div>
          {this.showToast && <Toast content={this.toastMessage} modifyClass={this.toastModifyClass} />}
          {!isLoading ? (
               <div className="order-list">
               {!isLoading && pastOrders.length === 0 && <div className="not-found-text">No se encontraron pedidos anteriores.</div>}
               {pastOrders.map(order => (
                 <div className="order" key={order.orderId} data-test="order">
                   <div className="order-header">
                     <div className="order-metadata">
                       <div className="order-info placed-date">
                         <div className="order-info-title">Pedido Realizado :&nbsp;</div>
                         <div className="order-info-value">{order.orderPlacedDate}</div>
                       </div>
                       <div className="order-info delivered-date">
                         <div className="order-info-title">Pedido Entregado :&nbsp;</div>
                         <div className="order-info-value">{order.orderDeliveredDate}</div>
                       </div>
                       <div className="order-info id">
                         <div className="order-info-title">ID del Pedido :&nbsp;</div>
                         <div className="order-info-value">{order.orderId}</div>
                       </div>
                       <div className="order-info order-info-address">
                         <div className="order-info-title">Dirección de Entrega :&nbsp;</div>
                         <div className="order-info-value">{order.addressName}</div>
                       </div>
                     </div>
                     <div className="order-info">
                       <button
                          onClick={() => this.navigateTo({id:order.sapNumber, props:this.props, screenName:"OrderDetails"})}
                          className="order-reorder-btn btn"
                          data-test="order-reorder-btn"
                        >Ver Detalles</button>
                     </div>
                   </div>
                 </div>
               ))}
             </div>
          ) : (<div className="loader"><CircularProgress /></div>)}
        </section>
      </OrderHistoryWrapper>
    );

    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu isAddtoCart={!!this.state.cartProductIds.length} navigation={this.props.navigation} id="navigation-menu-address" />
        <div style={{ background: '#F7F7F7', flex: 1, }}>
          {content}
        </div>
        <FooterWeb />
      </ThemeProvider>
    );
  }
}



// Customizable Area End
