import React, { PropsWithChildren } from "react";

import {
  Container,
  Box,
  Typography,
  styled,
  Grid,
} from "@material-ui/core";
import { backgroundImg, logo } from "./assets";
const config = require("./config");

export default ({ children }: PropsWithChildren<{}>) => {
  return (
    <ResetPasswordContainer>
      <Container maxWidth={false} className="pe0 ps0 container">
        <Grid container className="container">
          <Grid item md={6} sm={12} style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center"
          }}>
            <Box sx={{ width: "100%" }}>
              <img src={logo} alt="logo" className="logo" />
            </Box>
            {children}
          </Grid>
          <Grid item md={6} sm={12} className="container">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%"
              }}
            >
              <div className="bgImg"></div>
              <Box sx={{
                width: "100%",
                height: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column"
              }}
                style={{ backgroundColor: "#376BAE" }} >
                <Typography gutterBottom className="sloganTitle">{config.sloganTitle}</Typography>
                <Typography dangerouslySetInnerHTML={{ __html: config.sloganContent }} className="sloganContent"></Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

      </Container>
    </ResetPasswordContainer>
  );
}

export const ResetPasswordContainer = styled(Box)(({ theme }) => ({
  height: "100vh",
  '& *': {
    fontFamily: 'Arial, sans-serif !important',
  },
  '& .bgImg': {
    width: "100%", height: "50%", backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: " no-repeat",
    backgroundColor: "rgb(55, 107, 174)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  '& .pe0': {
    paddingRight: 0
  },
  '& .ps0': {
    paddingLeft: 0
  },
  '& .container': {
    display: "flex",
    height: "auto",
    minHeight: "100%"
  },
  '& .logo': {
    position: "relative",
    top: 10,
    left: 20
  },

  '& .sloganTitle': {
    fontSize: 36,
    lineHeight: "44px",
    fontWeight: 700,
    color: "#fff",
    textTransform: "uppercase",
    textAlign: "center"
  },
  '& .sloganContent': {
    fontFamily: 'Arial, sans-serif !important',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "26px",
    color: "#fff",
    textAlign: "center"
  },
}))