import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  ThemeProvider,
  Typography,
  createTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {components} from "react-select";
import { icSuccess, propStyle } from "./assets";
import { defaultCountryIndex } from './AddAddressController';
// Customizable Area End

import Select from "react-select";

import AddAddressController, {
  Props,
  configJSON,
  AdressTypeData
} from "./AddAddressController";

export default class AddAddress extends AddAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTextError = (isRender: boolean, content: string) => {
    return isRender &&  <Typography style={styles.errorText as React.CSSProperties}>{content}</Typography>
  }
  DialogSuccess = () => {
    return <Dialog fullWidth open={this.state.openSuccess}>
        <DialogContent dividers>
            <Box style={{display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20}}>
                <img src={icSuccess} alt="icon success" />
                <Typography align="center" style={{ ...styles.title, fontSize: 18, marginTop: 10 }}>
                  {!this.state.isEdit ? configJSON.successMsg : configJSON.updateSuccessMsg}</Typography>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={this.handleCreateSuccess} style={{ ...propStyle.button as React.CSSProperties, ...styles.btnOk }}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
}
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Dialog fullWidth open={this.props.openDialog} onClose={this.handleCloseDialog}>
          <DialogTitle >
           <Typography style={styles.title}>{configJSON.title}</Typography> 
            <IconButton onClick={this.handleCloseDialog} style={{position: 'absolute', top: 5, right: 0} }>
              <CloseIcon />
            </IconButton>
            </DialogTitle>
          <DialogContent style={{ overflowY: 'visible' }} dividers>
            <Box>
              <Box style={styles.bgInputContainer as React.CSSProperties}>
                <Typography style={styles.inputTitle}>
                  {configJSON.firstName}
                </Typography>
                <Input data-test-id="txtFirstName"
                  value={this.state.addressInfo.firstName}
                  placeholder={configJSON.firstName}
                  disableUnderline
                  style={styles.inputStyle as React.CSSProperties}
                  onChange={(e)=>{this.onChangeInfo("firstName", e.target.value)}}
                />
              </Box>
              <Box style={styles.bgInputContainer as React.CSSProperties}>
                <Typography style={styles.inputTitle}>
                  {configJSON.lastName}
                </Typography>
                <Input data-test-id="txtLastName"
                  value={this.state.addressInfo.lastName}
                  placeholder={configJSON.lastName}
                  disableUnderline
                  style={styles.inputStyle as React.CSSProperties}
                  onChange={(e)=>{this.onChangeInfo("lastName", e.target.value)}}
                />
              </Box>
              <Box style={styles.bgInputContainer as React.CSSProperties}>
                <Typography style={styles.inputTitle}>
                  {configJSON.country}
                </Typography>
                <Select
                  data-test-id="txtCountry"
                  options={AdressTypeData}
                  defaultValue={AdressTypeData[defaultCountryIndex]}
                  onChange={item =>
                    item && this.onChangeInfo("country", item.value)
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    Option: ({ children, ...props }) => <components.Option {...props}>
                      <Typography component={"div"} style={styles.commonText}>{children}</Typography>
                    </components.Option>,
                  }}
                  formatOptionLabel={({ value, label }) =>
                    <Typography component={"div"} style={{ ...styles.commonText }}>{value}</Typography>
                  }
                  styles={{
                    control: (propStyle) => ({ ...propStyle, ...styles.inputStyle }),
                    valueContainer: (propStyle) => ({ ...propStyle, padding: 0 }),
                    option: (propStyle, { isFocused }) => (
                      {
                        ...propStyle, ...styles.inputTitle,
                        backgroundColor: isFocused ? "#73A7EA" : "transparent"
                      }),
                  }}
                />
              </Box>
              <Box style={styles.bgInputContainer as React.CSSProperties}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} style={{position: "relative"}}>
                    <Typography style={styles.inputTitle}>
                      {configJSON.city}
                    </Typography>
                    <Input data-test-id="txtCity"
                      value={this.state.addressInfo.city}
                      placeholder={configJSON.city}
                      disableUnderline
                      style={{...styles.inputStyle as React.CSSProperties,  
                        borderColor: this.state.unavailableService ? "#DC2626" : "#CBD5E1"}}
                      onChange={(e)=>{this.onChangeInfo("city", e.target.value)}}
                    />
                    {this.renderTextError(this.state.unavailableService, configJSON.serviceNotAvailable)}
                  </Grid>
                  <Grid item xs={12} sm={4} style={{position: "relative"}}>
                    <Typography style={styles.inputTitle}>
                      {configJSON.zipCode}
                    </Typography>
                    <Input data-test-id="txtZipcode"
                      value={this.state.addressInfo.zipCode}
                      placeholder={configJSON.zipCode}
                      disableUnderline
                      style={{...styles.inputStyle as React.CSSProperties, 
                        borderColor: this.state.invalidZipCode ? "#DC2626" : "#CBD5E1"}}
                      onChange={(e)=>{this.onChangeInfo("zipCode", e.target.value)}}
                    />
                    {this.renderTextError(this.state.invalidZipCode, configJSON.invalidCZipode)}
                  </Grid>
                </Grid>
              </Box>
              <Box style={styles.bgInputContainer as React.CSSProperties}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Typography style={styles.inputTitle}>
                      {configJSON.street}
                    </Typography>
                    <Input data-test-id="txtStreet"
                      value={this.state.addressInfo.street}
                      placeholder={configJSON.street}
                      disableUnderline
                      style={styles.inputStyle as React.CSSProperties}
                      onChange={(e)=>{this.onChangeInfo("street", e.target.value)}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography style={styles.inputTitle}>
                      {configJSON.buildingNum}
                    </Typography>
                    <Input data-test-id="txtBuildingNum"
                      value={this.state.addressInfo.buildingNumber}
                      placeholder={configJSON.buildingNum}
                      disableUnderline
                      style={styles.inputStyle as React.CSSProperties}
                      onChange={(e)=>{this.onChangeInfo("buildingNumber", e.target.value)}}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box style={{...styles.bgInputContainer as React.CSSProperties, display: "flex", alignItems: "center", marginBottom: 0}}>
                <Checkbox data-test-id="checkbox-default" checked={this.state.isDefaultAddress} style={{color: "#004E9E", padding: 0 }} onClick={this.onChangeDefaultAddress} />
                <Typography style={{...styles.commonText, marginLeft: 10}}>
                  {configJSON.setDefault}
                </Typography>
                
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}
              style={{ ...styles.button as React.CSSProperties, backgroundColor: "#4B7FC2", opacity: 0.6 }}>Cancelar</Button>
            <Button disabled={this.state.disabledButton}
              data-test-id="btn-save"
               onClick={this.handleSavePressed} 
               style={{ ...styles.button as React.CSSProperties, backgroundColor: this.state.disabledButton ? "gray" : "#004E9E" }}>Guardar</Button>
          </DialogActions>

        </Dialog>
        {this.DialogSuccess()}
      </ThemeProvider>
      
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 8,
        "& .MuiDialogActions-root": {
          padding: "15px 8px"
        },
        "& .MuiDialogTitle-root": {
          display: "flex", 
          justifyContent: "space-between"
        }
      }
    },
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
})

const styles = {
  inputStyle: {
    border: "1px solid #CBD5E1",
    padding: 5,
    borderRadius: 8,
    width: "100%"
  },
  title: {
    fontWeight: 700, 
    fontSize: 20,
    color: "#0F172A",
  },
  inputTitle: {
    fontWeight: 700, 
    fontSize: 14,
    color: "#64748B",
    marginBottom: 5
  },
  button: {
    width: 132, 
    padding: "12px 0",
    textTransform: "none", 
    color: "#FFF",
    borderRadius: 8,
    fontWeight: 700
  },
  titleInput: {
    color: "#000"
  },
  bgInputContainer: {
    marginBottom: 15
  },
  commonText: {
    fontWeight: 400, 
    fontSize: 16, 
    color: "#0F172A", 
  },
  errorText: {
    position: "absolute", 
    color: "#DC2626",
    fontSize: 12, 
    fontWeight: 400
  },
  btnOk: {
    backgroundColor: "#004E9E",
    marginTop: 10, 
    marginBottom: 10, 
    padding: 10, 
    width: 120
  }
}
// Customizable Area End
