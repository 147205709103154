//@ts-nocheck

import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  InputBase,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
const config = require("./config")

const ContainerBox = styled(Container)({
  width: "100%",
  padding: "0",
});

const MainWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 960px)": {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
});

const TitleText = styled(Typography)({
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 700,
  textAlign: "center",
});

const LogoBox = styled(Box)({
  padding: "5px 40px",
  width: "140px",
  height: "67px",
});

const LabelInput = styled(Typography)({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 700,
  marginBottom: "4px",
  color: "#334155",
});

const StyleInput = styled(InputBase)({
  width: "100%",
  height: "56px",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  padding: "0 8px",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: "#0F172A",
  marginBottom: "16px",
  "&:before": {
    borderBottom: "none",
    outline: "none",
  },
  "&:focus, &:focus-within": {
    borderColor: "#6200EA",
    boxShadow: "0 0 0 3px #E1CCFF",
  },
  '& input[type="password"]': {
    fontSize: "8px",
    "&::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
    },
  },
  '& input[type="text"]': {
    "&::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
    },
  },
});

const CheckboxContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "8px",
});

const CheckboxInput = styled(Checkbox)({
  padding: 0,
  color: "#64748B",
  width: "20px",
  height: "20px",
  borderRadius: "6px",
  svg: {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
});

const ButtonSignUp = styled(Button)({
  width: "100%",
  height: "56px",
  borderRadius: "6px",
  backgroundColor: "#004E9E",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 700,
  color: "#ffffff",
  marginTop: "23px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#4B7FC2",
  },
  "&:disabled": {
    backgroundColor: "#A9A9A9",
    color: "#ffffff",
    cursor: "not-allowed",
  },
});

const BottomContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginTop: "40px",
  fontSize: "16px",
  lineHeight: "24px",
});

const ContainerBlock = styled(Box)({
  width: "50%",
  "@media (max-width: 960px)": {
    width: "100%",
  },
});

const ContentBlock = styled(Box)({
  marginTop: "40px",
  width: "360px", 

  "@media (max-width: 960px)": {
    maxWidth: "360px",
    width: "100%",
  },
  "@media (max-width: 768px)": {
    padding: "0 15px",
  },
});

const ImageComponent = styled(Box)({
  width: "100%", 
  height: "50%",  
  objectFit: "cover",
  backgroundImage: "url(./assets/main.png)", 
  backgroundRepeat: "no-repeat", 
  backgroundSize: "cover", 
  backgroundPosition: "top center",
  "@media (max-width: 960px)": {
    height: "450px",
    backgroundSize: "cover",
  },
})

const TextMainComponent = styled(Box)({
  width: "100%",
  height: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  backgroundColor: "#376BAE",
  "@media (max-width: 960px)": {
    padding: '150px 0'
  },
  "@media (max-width: 768px)": {
    height: "100%",
    padding: '50px 20px',
    width: '95%'
  },
})

const SpaceComponent = styled('br')({
  "@media (max-width: 568px)": {
    display: 'none'
  },
})

const styles = {
  buttonOK: {
    backgroundColor: "#004E9E",
    color: "#FFF", 
    paddingRight: 30, 
    paddingLeft: 30,
    fontWeight: 700
  },
  successText: {
    fontWeight: 600, 
    fontSize: 18, 
    lineHeight: "32px",
    color: "#0F172A",
  }
}
import { icSuccess } from "./assets";
const newTheme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif !important"
      }
    }
  }
});
import { ConfirmModal } from "../../../components/src/utils";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getImage = (type: boolean) => {
    const image = type ? this.state.imgAccess : this.state.imgDecline;
    return image;
  };

  getStyle = (errorType: boolean) => {
    return {
      border: errorType ? "1px solid #F87171" : "",
      marginBottom: errorType ? "0" : "",
    };
  };

  getVisibilityImg = (show: boolean) => {
    return show ? <Visibility /> : <VisibilityOff />;
  };

  errorToast = () => {
    return (this.state.invalidName || this.state.invalidEmail || this.state.invalidPassword || this.state.invalidReTypePassword) &&
      <Box sx={{ width: "100%" }} style={{ backgroundColor: "#FEE2E2", marginTop: 10, position: "relative", borderRadius: 8, marginBottom: '24px' }}>
        <Box style={{ width: 5, height: "100%", background: "#DC2626", position: "absolute", borderRadius: "8px 0 0 8px" }}></Box>
        <Typography style={{ color: "#DC2626", fontSize: 12, marginTop: 5, padding: "12px 16px" }}>
          Por favor, complete todos los campos obligatorios antes de enviar el formulario
        </Typography>
      </Box>
  }
  dialogConfirm = () => {
    return <Dialog fullWidth open={this.state.isSignupSuccess} onClose={this.handleButtonConfirm}>
      <DialogContent dividers>
        <Box style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20 }}>
          <img src={icSuccess} alt="icon success" />
          <Typography align="center" style={{ ...styles.successText, marginTop: 10 }}>
            {config.successMessage}</Typography>
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: 12 }}>
        <Button data-test-id={"btn-ok-signup"} onClick={this.handleButtonConfirm} style={styles.buttonOK} >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={newTheme}>
        <ContainerBox maxWidth={false}>
          <MainWrapper>
            <ContainerBlock>
              <LogoBox>
                <img
                  alt="logo"
                  src={this.state.imgLogo}
                  style={{ width: "100%" }}
                />
              </LogoBox>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "100px",
                  paddingBottom: "133px",
                }}
              >
                <TitleText>{this.signUp}</TitleText>
                <ContentBlock>
                  {this.errorToast()}
                  <LabelInput>{this.labelFullName}</LabelInput>
                  <StyleInput
                    data-test-id="txtInputFullName"
                    fullWidth={true}
                    value={this.state.fullName}
                    onChange={(e) =>
                      this.txtInputFullNamePrpos.onChangeText(e.target.value)
                    }
                    placeholder={this.labelFirstName}
                  />
                  <LabelInput>{this.labelEmail}</LabelInput>
                  <StyleInput
                    data-test-id="txtInputEmail"
                    fullWidth={true}
                    value={this.state.email}
                    onChange={(e) =>
                      this.txtInputEmailPrpos.onChangeText(e.target.value)
                    }
                    placeholder={this.labelEmail}
                    style={this.getStyle(this.state.invalidEmail)}
                  />
                  {this.state.invalidEmail && (
                    <Box
                      sx={{
                        color: "#DC2626",
                        fontSize: "12px",
                        lineHeight: "18px",
                        marginBottom: "16px",
                        marginTop: "2px"
                      }}
                    >
                      {this.labelInvalidEmail}
                    </Box>
                  )}
                  <LabelInput>{this.labelTypePassword}</LabelInput>
                  <StyleInput
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={"Contraseña"}
                    fullWidth={true}
                    value={this.state.password}
                    onChange={(e) =>
                      this.txtInputPasswordProps.onChangeText(e.target.value)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.btnPasswordShowHideProps.onPress}
                          edge="end"
                        >
                          {this.getVisibilityImg(
                            this.state.enablePasswordField
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    style={this.getStyle(this.state.invalidPassword)}
                  />
                  {this.state.invalidPassword && (
                    <Box
                      sx={{
                        color: "#DC2626",
                        fontSize: "12px",
                        lineHeight: "18px",
                        marginBottom: "16px",
                      }}
                    >
                      {this.labelPasswordsNotMatch}
                    </Box>
                  )}
                  <LabelInput>{this.labelConfirmPassword}</LabelInput>
                  <StyleInput
                    data-test-id="txtInputConfirmPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={"Contraseña"}
                    fullWidth={true}
                    value={this.state.reTypePassword}
                    onChange={(e) =>
                      this.txtInputConfirmPasswordProps.onChangeText(
                        e.target.value
                      )
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.btnPasswordShowHideProps.onPress}
                          edge="end"
                        >
                          {this.getVisibilityImg(
                            this.state.enablePasswordField
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    style={this.getStyle(this.state.invalidReTypePassword)}
                  />
                  {this.state.invalidReTypePassword && (
                    <Box
                      sx={{
                        color: "#DC2626",
                        fontSize: "12px",
                        lineHeight: "18px",
                        marginBottom: "16px",
                      }}
                    >
                      {this.labelPasswordsNotMatch}
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {this.state.password && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            lineHeight: "18px",
                            marginBottom: "8px",
                          }}
                        >
                          <img
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "6px",
                            }}
                            src={this.getImage(
                              !this.state.invalidCapitalLetter
                            )}
                            alt="icon"
                          />
                          {this.capitalLetter}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            lineHeight: "18px",
                            marginBottom: "8px",
                          }}
                        >
                          <img
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "6px",
                            }}
                            src={this.getImage(
                              !this.state.invalidLowercaseLetter
                            )}
                            alt="icon"
                          />
                          {this.lowercaseLetter}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            lineHeight: "18px",
                            marginBottom: "8px",
                          }}
                        >
                          <img
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "6px",
                            }}
                            src={this.getImage(!this.state.invalidNumber)}
                            alt="icon"
                          />
                          {this.oneNumber}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            lineHeight: "18px",
                            marginBottom: "8px",
                          }}
                        >
                          <img
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "6px",
                            }}
                            src={this.getImage(!this.state.invalidLength)}
                            alt="icon"
                          />
                          {this.minimumChar}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            lineHeight: "18px",
                            marginBottom: "8px",
                          }}
                        >
                          <img
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "6px",
                            }}
                            src={this.getImage(!this.state.invalidSym)}
                            alt="icon"
                          />
                          {this.oneSym}
                        </Box>
                      </>
                    )}
                    {this.state.invalidEmail && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                          lineHeight: "18px",
                        }}
                      >
                        <img
                          style={{
                            width: "12px",
                            height: "12px",
                            marginRight: "6px",
                          }}
                          src={this.state.imgDecline}
                          alt="icon"
                        />
                        {this.invalidEmailAddres}
                      </Box>
                    )}
                  </Box>
                  <CheckboxContainer>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "40px",
                      }}
                    >
                      <CheckboxInput
                        data-test-id={"btnChecked"}
                        color="primary"
                        onClick={() =>
                          this.setRememberMe(!this.state.btnChecked)
                        }
                        checked={this.state.btnChecked}
                      />
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#0F172A",
                          lineHeight: "20px",
                          marginLeft: "6px",
                        }}
                      >
                        {config.agreeText}
                      </Typography>
                    </Box>
                  </CheckboxContainer>
                  <ButtonSignUp
                    data-test-id={"btnSignUp"}
                    onClick={() => this.btnSignUpProps.onPress()}
                    disabled={!this.state.btnChecked}
                  >
                    {this.signUp}
                  </ButtonSignUp>
                  <BottomContainer>
                    <Typography style={{ fontSize: "16px", marginRight: "8px" }}>
                      {this.haveAccount}
                    </Typography>
                    <Typography
                      data-test-id={"btn-Sign-In"}
                      style={{
                        fontSize: "16px",
                        color: "#004E9E",
                        fontWeight: "bold",
                      }}
                      onClick={() => this.goToLogin()}
                    >
                      {this.logIn}
                    </Typography>
                  </BottomContainer>
                </ContentBlock>
              </Box>
            </ContainerBlock>
            <Grid item md={6} sm={12} style={{ display: "flex", height: "inherit" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "auto", 
                  minHeight: "100vh"
                }}
              >
                <ImageComponent
                  alt="main"
                  component="img" 
                  src={this.state.imgMain}
                />
                <TextMainComponent>
                  <Typography gutterBottom style={{
                      fontSize: 36, 
                      lineHeight: "44px", 
                      fontWeight: 700,
                      color: "#fff",
                      textTransform: "uppercase",
                      textAlign: "center"
                    }}>{config.sloganTitle}</Typography>
                    <Typography
                      style={{
                        fontSize: 18, 
                        fontWeight: 400, 
                        lineHeight: "26px", 
                        color: "#fff",
                        textAlign: "center",
                        maxWidth: "490px"
                      }}>
                        {config.row1}
                        <SpaceComponent/>
                        {' '}
                        {config.row2}
                        <SpaceComponent/>
                        {' '}
                        {config.row3}
                    </Typography>
                </TextMainComponent>
              </Box>
            </Grid>
          </MainWrapper>
        </ContainerBox>
        {this.dialogConfirm()}
        <ConfirmModal open={!!this.state.errorMsg} onClose={() => this.setState({ errorMsg: "" })} confirmText={this.state.errorMsg} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
