// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  emailValue: any;
  emailError: boolean;
  errorMessage: string;
  resendSuccessMsg: any;
}

interface SS {
  navigation: any;
}


export default class VerifyEmailController extends BlockComponent<
  Props,
  S,
  SS
> {
  resendLinkId = '';

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      emailValue: "",
      emailError: false,
      errorMessage: "",
      resendSuccessMsg: ""
    };
  }

  async componentWillMount() {
    const storedEmail = localStorage.getItem("forgotPwEmail");
    this.setState({ emailValue: storedEmail });
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any
  ) => {
    const successCallbackMap = {
      [this.resendLinkId]: this.handleResendLinkResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleResendLinkResponse = (responseJSON: any) => {
    if (responseJSON?.errors) {
      this.setState({ emailError: true, errorMessage: responseJSON?.errors, resendSuccessMsg: '' });
      return;
    }

    this.setState({ emailError: false, errorMessage: '', resendSuccessMsg: configJSON.resendSuccessMsg });
  }

  gotoLogIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  resendLink = () => {
    const body = {
      "data": {
        "attributes": {
          "email": this.state.emailValue
        }
      }
    }

    this.resendLinkId = sendAPIRequest(
      `/bx_block_forgot_password/password_recovery`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }
    )
  }
}
// Customizable Area End
