import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { getDefaultCountryIndex } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openDialog: boolean
  handleCloseDialog: () => void
  loadAddresses: () => void
  editId?: string
  // Customizable Area End
}

// Customizable Area Start
export interface AddressDataType {
  latitude: number;
  longitude: number;
  address: string;
  address_type: string;
}

export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}

export interface AddressValueType {
  value: string;
}

export const AdressTypeData = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua & Deps", label: "Antigua & Deps" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia Herzegovina", label: "Bosnia Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina", label: "Burkina" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Central African Rep", label: "Central African Rep" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Congo {Democratic Rep}", label: "Congo {Democratic Rep}" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "East Timor", label: "East Timor" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland {Republic}", label: "Ireland {Republic}" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Ivory Coast", label: "Ivory Coast" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea North", label: "Korea North" },
  { value: "Korea South", label: "Korea South" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macedonia", label: "Macedonia" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "St Kitts & Nevis", label: "St Kitts & Nevis" },
  { value: "St Lucia", label: "St Lucia" },
  { value: "Saint Vincent & the Grenadines", label: "Saint Vincent & the Grenadines" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome & Principe", label: "Sao Tome & Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad & Tobago", label: "Trinidad & Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];
export const defaultCountryIndex = getDefaultCountryIndex(AdressTypeData);

export interface AddressInfo {
  firstName: string, 
  lastName: string, 
  country: string, 
  city: string, 
  zipCode: string, 
  street: string, 
  buildingNumber: string, 
}

const initInfo = {
  firstName: "",
  lastName: "",
  country: AdressTypeData[defaultCountryIndex].value,
  city: "",
  zipCode: "",
  street: "",
  buildingNumber: "",
}
// Customizable Area End

interface S {
  // Customizable Area Start
  txtInputAddressValue: string;
  txtInputLatValue: string;
  txtInputLngValue: string;
  token: string;
  addressTypeValue: string;
  isOpen: boolean;
  addressInfo: AddressInfo;
  isDefaultAddress: boolean
  invalidZipCode: boolean
  unavailableService: boolean
  emptyFieldError: any,
  disabledButton: boolean
  openSuccess: boolean
  isEdit: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAddAddressCallId: string = "";
  apiGetAddressId: string = ""
  apiUpdateAddressId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
       // Customizable Area Start
      txtInputAddressValue: "",
      txtInputLatValue: "",
      txtInputLngValue: "",
      addressTypeValue: AdressTypeData[0].value,
      token: "",
      isOpen: false,
      addressInfo: initInfo,
      isDefaultAddress: false,
      invalidZipCode: false,
      unavailableService: false,
      emptyFieldError: {},
      disabledButton: true,
      openSuccess: false,
      isEdit: false
       // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiReqId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiReqId === this.apiAddAddressCallId) {
        this.handleAddOrUpdateResponse(resJson, false)
      } 
      if (apiReqId === this.apiUpdateAddressId) {
        this.handleAddOrUpdateResponse(resJson, true)
      } 
      if(apiReqId === this.apiGetAddressId) {
        if(resJson?.data) {
          const {attributes} = resJson.data
          const addressInfo = {
            firstName: attributes.first_name,
            lastName: attributes.last_name,
            country: attributes.country,
            city: attributes.city,
            zipCode: attributes.zip_code,
            street: attributes.street,
            buildingNumber: attributes.building_number,
          }
          this.setState({
            addressInfo: {
             ...addressInfo
            },
            isDefaultAddress: attributes.is_default,
            disabledButton: false
          })
        } else {
          this.handleResponseError(resJson.errors)
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props.editId && this.props.editId !== "" && this.props.openDialog !== prevProps.openDialog && this.props.openDialog) {
      this.getAddress()
    }
  }

  handleCloseDialog = () =>{
    this.props.handleCloseDialog()
    this.setState({addressInfo: initInfo, disabledButton: true})
  }

  handleAddOrUpdateResponse = (responseJson: any, isEdit: boolean ) => {
    if (responseJson?.data) {
      this.props.handleCloseDialog();
      this.handleCreateSuccess();
      this.setState({isEdit, addressInfo: initInfo, disabledButton: true})
    } else {
      this.handleResponseError(responseJson.errors)
    }
  }
  handleToggleSuccess = () => {
    this.setState({openSuccess: !this.state.openSuccess})
  }

  handleCreateSuccess = () => {
    this.handleToggleSuccess();
    this.props.loadAddresses()
  }
  handleResponseError = (err: any) => {
    let list = {};
    err.forEach((item: any) => {
      list = {...list, ...item}
    });
    this.setState({
      invalidZipCode: "zip_code" in list,
      unavailableService: "city" in list
    })
  }
  onChangeDefaultAddress = () => {
    this.setState({isDefaultAddress: !this.state.isDefaultAddress})
  }
  isEmpty = (text: string) => {
    return text === "" || !text
  }
  onChangeInfo = (key: string, value: string) => {
    if(key === "zipCode") this.setState({invalidZipCode: false})
    if(key === "city") this.setState({unavailableService: false})
    this.setState({
      addressInfo: {...this.state.addressInfo, [key]: value},
    }, () => {
      this.handleEmptyValue()
    })
  }
  handleEmptyValue = () => {
    const {firstName, lastName, country, city, zipCode, buildingNumber, street} = this.state.addressInfo
    this.setState({
      disabledButton: this.isEmpty(firstName) || this.isEmpty(lastName) || this.isEmpty(country) || this.isEmpty(city) 
      || this.isEmpty(zipCode) || this.isEmpty(buildingNumber) || this.isEmpty(street) 
    })  
  }
  getToken = () => {
    const getTokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(getTokenMsg);
  };

  txtInputLatWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLatValue: text });
    },
  };

  txtInputLatMobileProps = {
    ...this.txtInputLatWebProps,
  };

  txtInputLatProps = this.isPlatformWeb()
    ? this.txtInputLatWebProps
    : this.txtInputLatMobileProps;

  txtInputLngWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLngValue: text });
    },
  };

  txtInputLngMobileProps = {
    ...this.txtInputLngWebProps,
  };

  txtInputLngProps = this.isPlatformWeb()
    ? this.txtInputLngWebProps
    : this.txtInputLngMobileProps;

  txtInputAddressWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputAddressValue: text });
    },
  };

  txtInputAddressMobileProps = {
    ...this.txtInputAddressWebProps,
  };

  txtInputAddressProps = this.isPlatformWeb()
    ? this.txtInputAddressWebProps
    : this.txtInputAddressMobileProps;


  handleOnchnageAddressType = (item: string)=> this.setState({addressTypeValue: item})
  apiCall = async (config: any) => {
    const token = await getStorageData("authToken");
    const {body, contentType, endpoint, method} = config

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(reqMsg.id, reqMsg);
    return reqMsg.messageId;
  };
  getAddress = async () => {
    this.apiGetAddressId = await this.apiCall({
      method: configJSON.getAddressApiMethod,
      endpoint: `${configJSON.addAddressAPiEndPoint}/${this.props.editId}`,
      contentType: configJSON.getAddressApiContentType
    })
  }

  prepareFormData = () => {
    const {firstName, lastName, country, city, zipCode, buildingNumber, street} = this.state.addressInfo
    const formData = new FormData() 
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)
    formData.append("country", country)
    formData.append("city", city)
    formData.append("zip_code", zipCode)
    formData.append("building_number", buildingNumber)
    formData.append("street", street)
    formData.append("is_default", JSON.stringify(this.state.isDefaultAddress))
    return formData
  }

  createAddress = async () => {
    const formData = this.prepareFormData()
    this.apiAddAddressCallId = await this.apiCall({
      method: configJSON.addAddressApiMethod,
      endpoint: configJSON.addAddressAPiEndPoint,
      body: formData
    });
  }

  updateAddress = async () => {
    const formData = this.prepareFormData()
    this.apiUpdateAddressId = await this.apiCall({
      method: configJSON.updateAddressApiMethod,
      endpoint: `${configJSON.addAddressAPiEndPoint}/${this.props.editId}`,
      body: formData
    });
  }

  handleSavePressed = async () => {
    if(this.props.editId && this.props.editId !== "") this.updateAddress()
    else this.createAddress()
  }
  // Customizable Area End
}
