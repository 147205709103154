Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.addToCartEndpoint = "bx_block_shopping_cart/shopping_cart"
exports.marketText = "SOLUCIONES BASADAS EN EL MERCADO";
exports.marketTextTwo = "FUCHS ofrece una amplia gama de productos para satisfacer sus necesidades de lubricación.";
exports.productText = "Nuestros Productos";
exports.engineOiltext = "Aceite de Motor FUCHS";
exports.titanText = "TITÁN";
exports.descriptiontext = "Descripción: ipsum dolor sit amet,cons";
exports.descriptiontextTwo = "adipiscing elit.";
exports.quantitytext  = "Cantidad";
exports.numberText = "01";
exports.addCartText = "Agregar al Carrito";
exports.desText = "Descripción";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTgsImV4cCI6MTcwOTA5OTE0Mn0.Hr_z2bcn2X77-1pQmsYsLITeqIs09g22bCyp06scWfYN3Q_UJeKpVc4271rLAc9fbt_DXAbUR6kAXJX_KUrRHg";
exports.compherensiveText = "Una amplia gama de lubricantes de alta calidad para casi todas las aplicaciones e industrias";
exports.readMore = "Leer más";
exports.successMessage = 'Elemento agregado al carrito exitosamente';
exports.title2 = "Una amplia gama de lubricantes de alta calidad para casi todas las aplicaciones e industrias";
exports.searchEndpoint = "bx_block_advanced_search/product_filter"
exports.searchResult = "Resultado de la búsqueda para";
exports.emptySearch="No se encontraron resultados. Por favor, intenta con un término de búsqueda diferente.";
// Customizable Area End