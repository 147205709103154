// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { NewOrder } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  isLoading: any;
  orders: NewOrder
  
}

interface SS {
  navigation: any;
}


export default class OrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  getOrderCallId: string=""

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: true,
      orders: {
        id: "1",
        type: "order",
        attributes: {
          id: 1,
          order_number: "1232323",
          delivery_date: "12-03-2024",
          delivery_addresses: " Flat No : 1211 , Shoppers Street Southern Avenue, Nearby HC Multi-speciality Hospital, New York, USA -  10001"
        }
      }
    };
  }

  async componentDidMount() {
    this.getOrder()
  }

  async receive(_from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getOrderCallId === apiRequestCallId) {

        if(responseJSON.data) {
          this.setState({
            isLoading: false,
            orders: {
              id: responseJSON.data.order_id,
              type: "order",
              attributes: {
                id: responseJSON.data.order_id,
                order_number: responseJSON.data.order_id,
                delivery_date: responseJSON.data.delivery_data,
                delivery_addresses: responseJSON.data.delivery_address
              }
            }
          })
        } else {
          this.handleResponseError(responseJSON)
        }
      }
    }
  }

  handleResponseError = (responseJSON: any) => {
    let list = {};

    const isErrArray = Array.isArray(responseJSON.errors)
    if(isErrArray) {
      responseJSON.errors.forEach((err: any) => list = {...list, ...err})
    }
    if("token" in list) this.goLogin()
    else this.goOrderError()
  }

  goHome = () => {
    this.props.navigation.navigate("Catalogue");
  }

  goLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  goOrderError = () => {
    this.props.navigation.navigate("OrderError");
  }

  getOrder = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const reqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderCallId = reqMessage.messageId

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrderEndpoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createOrderMethod
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
    return reqMessage.messageId;
  };

  
}
// Customizable Area End
