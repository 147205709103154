Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Correo electrónico";

exports.labelHeader = "Propuesta de valor: por qué los usuarios deberían registrarse.";
exports.labelFirstName = "Tu nombre completo";
exports.lastName = "Apellido";
exports.labelEmail = "Correo electrónico";
exports.labelPassword = "Contraseña";
exports.labelRePassword = "Vuelve a escribir la contraseña";
exports.errorCountryCodeNotSelected = "Por favor selecciona el código de país";
exports.errorBlankField = "no puede estar en blanco";

exports.labelLegalText =
  "Texto legal: Al registrarte, aceptas nuestros Términos y condiciones y Política de privacidad.";

exports.labelLegalTermCondition = "Términos y condiciones";
exports.labelLegalPrivacyPolicy = "Política de privacidad";
exports.btnTextSignUp = "Registrarse";
exports.errorPasswordNotValid = "Contraseña no válida.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Las contraseñas deben ser iguales.";
exports.errorAllFieldsAreMandatory = "Todos los campos son obligatorios.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.confirmEmail = 'account/accounts/email_confirmation'

exports.apiMethodTypeAddDetail = "POST";
exports.apiGet = "GET";

exports.errorEmailNotValid = "Correo electrónico no válido.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.imgMain = "assets/main.png";
exports.imgLogo = "assets/logo.png";
exports.imgAccess = "assets/img_accessePass.png";
exports.imgDecline = "assets/img_declinePass.png";
exports.labelTitleImage = "ESTAMOS DONDE TÚ ESTÁS"
exports.labelDescImage = "Nuestros 4 sitios de producción y 15 depósitos están estratégicamente ubicados en todo Estados Unidos garantizando a nuestros clientes proximidad de suministro."
exports.signUp = "Registrarse";
exports.labelFullName = 'Nombre completo';
exports.labelInvalidName = 'Nombre inválido';
exports.labelInvalidEmail = 'Correo electrónico inválido';
exports.labelTypePassword = 'Escribe la contraseña';
exports.labelPasswordsNotMatch = 'Las contraseñas no coinciden';
exports.labelConfirmPassword = 'Confirmar contraseña';
exports.capitalLetter = 'Al menos una letra mayúscula';
exports.lowercaseLetter = 'Al menos una letra minúscula';
exports.oneNumber = 'Al menos un número';
exports.minimumChar = 'La longitud mínima es de 8 caracteres';
exports.invalidEmailAddres = 'Dirección de correo electrónico no válida';
exports.haveAccount = '¿Ya tienes una cuenta?';
exports.logIn = 'Iniciar sesión';
exports.oneSym = 'Al menos un símbolo especial';
exports.sloganTitle = "Estamos donde tú estás";
exports.sloganContent= "Nuestros 4 sitios de producción y 15 depósitos están estratégicamente ubicados en todo Estados Unidos garantizando a nuestros clientes proximidad de suministro.";
exports.row1 = "Nuestros 4 sitios de producción y 15 depósitos están";
exports.row2 = "estratégicamente ubicados en todo Estados Unidos";
exports.row3 = "garantizando a nuestros clientes proximidad de suministro.";
exports.agreeText = "Estoy de acuerdo con la Política de privacidad y los Términos y condiciones"
exports.successMessage = "¡Tu cuenta se ha creado correctamente! Por favor, procede a iniciar sesión con tus nuevas credenciales."
// Customizable Area End
