import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { getStorageData } from "../../../framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}

interface ProductlistData {
  id: number,
  type: string,
  qty: number,
  attributes: {
    product_id: number,
    name: string,
    brand: null,
    description: string,
    packaging: null,
    sku_no: null,
    images: [
      {
        file_name: string,
        content_type: string,
        id: number,
        url: string
      }
    ]
    review_count: number,
    review: {
      data: []
    }
  }
}

interface ProductListDataOne {
  data: [
    {
      id: number,
      type: string,
      qty: number,
      attributes: {
        product_id: number,
        name: string,
        brand: null,
        description: string,
        packaging: null,
        sku_no: null,
        images: [
          {
            file_name: string,
            content_type: string,
            id: number,
            url: string
          }
        ]
        review_count: number,
        review: {
          data: []
        }
      }
    },
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  productList:Array<ProductlistData>;
  listData:Object;
  showMore: boolean;
  expandedItemId: number;
  width: number
  isOpenDialogSuccess: boolean
  searchKey: string | null,
  isLoadingSearch: boolean
  isAddToCart: boolean,
  page: number, 
  totalPage: number | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  getProductCallID: string = "";
  addToCartCallId: string = "";
  searchProductCallID: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      productList: [],
      listData:"",
      showMore: false,
      expandedItemId: NaN,
      width: window.innerWidth,
      isOpenDialogSuccess: false,
      searchKey: null,
      isLoadingSearch: true,
      isAddToCart: false,
      page: 1,
      totalPage: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getData()
    
    this.getToken();
    window.addEventListener("resize", () => this.updateWidth())
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.getProductCallID === apiCallId) {
        if (responseJson?.data) {
          window.scrollTo(0,0)
          this.getProductSuccessCallBack(responseJson.data, responseJson.meta)
        } else {
          this.getProductFailureCallBack(responseJson.errors)
        }
      }

      if (this.searchProductCallID === apiCallId) {
        this.handleSearchResponse(responseJson)
      }

      if(this.addToCartCallId === apiCallId) {
        this.addToCartResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getData = async () => {
    const searchKey = await getStorageData("search_key")
    if(window.location.pathname === "/") this.getListProduct();
    else if(searchKey) {
      this.setState({searchKey})
      this.handleSearchProduct(searchKey)
    }
  }

  addToCartResponse = (responseJson: any) => {
    if(responseJson?.data) {
      this.handleAddToCartResponse(responseJson)
    } else {
      this.getProductFailureCallBack(responseJson.errors)
    }
  }

  handleSearchResponse = (responseJson: any) => {
    this.setState({isLoadingSearch: false})
    if (responseJson?.products?.data) {
      this.getProductSuccessCallBack(responseJson.products.data)
    } else if(responseJson.errors) {
      this.getProductFailureCallBack(responseJson.errors)
    } else {
      this.setState({productList: [...[]]})
    }
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  handleSearchProduct = async (searchKeyWord: string) => {
    this.setState({searchKey: searchKeyWord, isLoadingSearch: true})
    this.searchProductCallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.searchEndpoint}/?query=${searchKeyWord}`,
    });
  }

  handleAddToCartResponse = (responseJson: any) => {
    this.toggleDialogSuccess();
    const id = responseJson.data.attributes.productdescription.data.id
    const product = this.state.productList.find(item => item.id === id)

    if (product) {
      const index = this.state.productList.indexOf(product)
      const updatedList = [...this.state.productList]
      product.qty = 1
      updatedList[index] = product
      this.setState({ productList: [...updatedList], isAddToCart: true })
    }
  }

  toggleDialogSuccess = () => {
    this.setState({isOpenDialogSuccess: !this.state.isOpenDialogSuccess})
  }

  handleAddQuantity = (ind: number) => {
    const index = ind; 
    const productToUpdate = this.state.productList[index];
    if (productToUpdate) {
      productToUpdate.qty = (productToUpdate.qty || 0) + 1;
      const updatedProductList = [...this.state.productList];
      updatedProductList[index] = productToUpdate;
      this.setState({
        productList: updatedProductList
      });
    }
  };

  handleRemoveQuantity = (ind: number) => {
    const index = ind ; 
    const productToUpdate = this.state.productList[index];
    if (productToUpdate && productToUpdate.qty > 0) {
      productToUpdate.qty--;
      const updatedProductList = [...this.state.productList];
      updatedProductList[index] = productToUpdate;
      this.setState({
        productList: updatedProductList
      });
    }
  };
  
  handleNavigateProductDescription = (id: number) => {
    this.props.navigation.navigate("ProductDescription", {id})
  }

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessageCatalogueColorCollection = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
      token : await getStorageData("authToken")
    };
    
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageCatalogueColorCollection.id, requestMessageCatalogueColorCollection);
    return requestMessageCatalogueColorCollection.messageId;
  };

  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({page: value}, () => {this.getData()})
  }
  
  getListProduct = async () => {
    this.getProductCallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.productAPiEndPoint}?page=${this.state.page}`,
    });
   };

  getProductSuccessCallBack = (data: any, meta?: any) => {
    let counter = 0;
    const updatedProductList = data.map((item: any) => ({
      ...item, 
      qty: 1, 
      ind: counter++
    }));
    this.setState({ productList: [...updatedProductList], totalPage: meta?.pagination?.total_pages});
  };

  getProductFailureCallBack = (errors: any) => {
    if (errors) {
      let listError = {};
      errors.forEach((err: any) => {
        listError = { ...listError, ...err }
      })
      if ("token" in listError) this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  };

  handleAddToCart = async (id: number) => {
    const product = this.state.productList.find(item => item.id === id);
    if (product) {
      const body = {
        "data": {
          "attributes": {
            "productdescription_id": product.id,
            "quantity": product.qty
          }
        }
      }
      this.addToCartCallId = await this.apiCall({
        contentType: configJSON.productApiContentType,
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.addToCartEndpoint,
        body
      });
}
  }

  // Customizable Area End
}
