Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

// Customizable Area Start
exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Por favor ingresa un correo electrónico válido";
exports.emailIsRequired = "Se requiere un correo electrónico";
exports.phoneNumberIsNotValid = "El número de teléfono no es válido";
exports.phoneNumberIsRequired = "Se requiere un número de teléfono";
exports.otpCodeIsRequired = "Se requiere un código OTP";
exports.pleaseEnterAPassword = "Por favor ingresa una contraseña";
exports.passwordMustBeAtLeast2Characters = "La contraseña debe tener al menos 2 caracteres";
exports.pleaseConfirmYourPassword = "Por favor confirma tu contraseña";
exports.passwordsMustMatch = "Las contraseñas deben coincidir";
exports.invalidEmailAddress = "Dirección de correo electrónico inválida";
exports.invalidPassword = "Contraseña inválida";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Por favor selecciona el código de país";

exports.labelTextIsAccountRecovery = "Recuperación de cuenta";
exports.secondLabelText = "Por favor elige qué tipo de cuenta registraste."
exports.thirdLabelText = "Para restablecer tu contraseña, por favor ingresa el correo electrónico asociado con tu cuenta.";
exports.forthLabelText = "Hemos enviado un código de confirmación al siguiente correo electrónico:";
exports.fifthLabelText = "Para restablecer tu contraseña, por favor ingresa el número de teléfono asociado con tu cuenta."
exports.sixthLabelText = "Hemos enviado un código de confirmación al siguiente número de teléfono:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Correo electrónico";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Siguiente";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Móvil"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Ingresa código OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "Cuenta de SMS (Teléfono)";
exports.buttonTitleIsEmailAccount = "Cuenta de Correo Electrónico";

exports.labelTextIsPleaseEnterYourNewPassword = "Por favor ingresa tu nueva contraseña.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Tu contraseña ha sido cambiada exitosamente";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Vuelve a escribir la contraseña";

exports.buttonTitleIsOk = "Aceptar"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Selecciona País";
// Customizable Area End
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.colorPrimary="#004E9E"
exports.colorError="#DC2626"
exports.btnEmailSendLink = "Enviar un enlace de recuperación";
exports.backTo = "Regresar";
exports.logIn = "Iniciar sesión";
exports.resendEmail = "Reenviar correo electrónico";
exports.title = "Recuperación de contraseña";
exports.title2 = "Nueva contraseña";
exports.newPwLabel = "Nueva contraseña";
exports.passwordNotMatch = "La contraseña de confirmación no coincide con la contraseña";
exports.setNewPwBtn = "Establecer nueva contraseña";
exports.confirmNewPwLabel = "Confirmar nueva contraseña";
exports.subTitle = "¿Olvidaste tu contraseña?";
exports.subTitle2 = "Revisa tu correo electrónico";
exports.instructionText = "No te preocupes, enviaremos un enlace de recuperación a tu correo electrónico.";
exports.instructionText2 = "Hemos enviado un enlace para restablecer la contraseña a ";
exports.instructionText3 = "Ingresa una nueva contraseña para ";
exports.sloganTitle = "Estamos donde tú estás";
exports.sloganContent= "Nuestros 4 sitios de producción y 15 depósitos están <br/> estratégicamente ubicados en todo Estados Unidos <br/> garantizando a nuestros clientes proximidad de suministro."
exports.noAccount = "No hay una cuenta registrada con este correo electrónico"
exports.invalidAccount = "El correo electrónico es inválido"
exports.resendSuccessMsg = "Correo electrónico reenviado exitosamente"
exports.resetSuccessMsg = "Contraseña restablecida exitosamente"
exports.emptyEmailPassword = "Por favor ingresa tu correo electrónico antes de enviar el formulario"
exports.capitalLetter = 'Al menos una letra mayúscula';
exports.lowercaseLetter = 'Al menos una letra minúscula';
exports.oneNumber = 'Al menos un número';
exports.minimumChar = 'La longitud mínima es de 8 caracteres';
exports.invalidEmailAddres = 'Dirección de correo electrónico inválida';
exports.oneSym = 'Al menos un símbolo especial';
// Customizable Area End