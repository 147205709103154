Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.createAccountURL = "account/accounts";
exports.loginAccountURL = "login/login";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";

// Customizable Area Start
exports.clientID =
  "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
exports.facebookButtonText = "Regístrate con Facebook";
exports.googleButtonText = "Regístrate con Google";
exports.signUpButtonText = "Regístrate con correo electrónico y contraseña";

exports.loginFacebookButtonText = "Inicia sesión con Facebook";
exports.loginGoogleButtonText = "Inicia sesión con Google";
exports.loginButtonText = "Inicia sesión con correo electrónico y contraseña";

exports.errorTitle = "Error";
exports.errorDescription = "#Error al obtener datos: ";

exports.signUpTitleText = "Regístrate";
exports.signUpBodyText = "Propuesta de valor: por qué los usuarios deberían registrarse.";

exports.logInTitleText = "Iniciar sesión";
exports.logInBodyText = "La razón por la que requerimos inicio de sesión.";
exports.loginFailed = "¡Algo salió mal!";
exports.orText = "o";
exports.facebookBaseUrl = "https://graph.facebook.com/v2.5/me?fields=email,name,friends&access_token=";
exports.facebookApiMethod = "GET";
exports.facebookAppId = "170982444234877";
// Customizable Area End