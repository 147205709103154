// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  imgPasswordInVisible,
  imgPasswordVisible, 
} from "./assets";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  isLoading: any;
  resetToken: any;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  emailValue: any;
  emailError: boolean;
  errorMessage: string;
  resetSuccessMsg: any;
  password: any;
  reTypePassword: any;
  invalidCapitalLetter: boolean;
  invalidLowercaseLetter: boolean;
  invalidNumber: boolean;
  invalidLength: boolean;
  invalidName: boolean;
  invalidReTypePassword: boolean;
  invalidPassword: boolean;
  invalidSym: boolean;
}

interface SS {
  navigation: any;
}


export default class UpdatePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  resetPasswordId = '';

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);
    this.isEmptyString = this.isEmptyString.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      resetToken: '',
      enablePasswordField: true,
      enableReTypePasswordField: true,
      emailValue: "",
      emailError: false,
      errorMessage: "",
      resetSuccessMsg: "",
      password: '',
      reTypePassword: '',
      invalidCapitalLetter: false,
      invalidLowercaseLetter: false,
      invalidNumber: false,
      invalidLength: false,
      invalidName: false,
      invalidReTypePassword: false,
      invalidPassword: false,
      invalidSym: false,
    };
  }

  async componentWillMount() {
    const storedEmail = localStorage.getItem("forgotPwEmail");
    this.setState({ emailValue: storedEmail });
  }

  async componentDidMount() {
    super.componentDidMount();
    const urlSearchParams = new URLSearchParams(window.location.search)
    const token = urlSearchParams.get('token') || '';
    this.setState({ resetToken: token });
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any
  ) => {
    const successCallbackMap = {
      [this.resetPasswordId]: this.handleResetPasswordResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;

      this.setState({ invalidPassword: false });
      this.setState({ invalidReTypePassword: false });

      if (text) {
        const checkLength = text.length >= 8;
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        const checkExistNumber = /\d/.test(text);
        const checkOneCapital = /[A-Z]/.test(text);
        const checkSym = regex.test(text);
        const checkOneLowercase = /[a-z]/.test(text);

        this.setState({ invalidLowercaseLetter: !checkOneLowercase });
        this.setState({ invalidNumber: !checkExistNumber });
        this.setState({ invalidCapitalLetter: !checkOneCapital });
        this.setState({ invalidLength: !checkLength });
        this.setState({ invalidSym: !checkSym });
      }
    },
    secureTextEntry: true,
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });
      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
      this.setState({ invalidPassword: false });
      this.setState({ invalidReTypePassword: false });
    },
    secureTextEntry: true,
  };

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  // A single method to toggle password visibility
  togglePasswordVisibility(fieldName: 'enablePasswordField' | 'enableReTypePasswordField') {
    this.setState(
      prevState => ({
        [fieldName]: !prevState[fieldName],
      } as any),
      () => {
        const fieldEnabled = this.state[fieldName];
        const secureTextEntry = !fieldEnabled;
        if (fieldName === 'enablePasswordField') {
          this.txtInputPasswordProps.secureTextEntry = secureTextEntry;
          this.imgEnablePasswordFieldProps.source = secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible;
        } else if (fieldName === 'enableReTypePasswordField') {
          this.txtInputConfirmPasswordProps.secureTextEntry = secureTextEntry;
          this.imgEnableRePasswordFieldProps.source = secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible;
        }
      }
    );
  }

  // Properties for the confirm password button
  btnConfirmPasswordShowHideProps = {
    onPress: () => this.togglePasswordVisibility('enableReTypePasswordField'),
  };
  
  // Properties for the password button
  btnPasswordShowHideProps = {
    onPress: () => this.togglePasswordVisibility('enablePasswordField'),
  };

  isEmptyString(str: string) {
    return !str?.length;
  }

  validateInputs = () => {
    let isValid = true;
    if (this.isEmptyString(this.state.password)) {
      this.setState({ invalidPassword: true });
      isValid = false;
    }
    if (this.isEmptyString(this.state.reTypePassword)) {
      this.setState({ invalidReTypePassword: true });
      isValid = false;
    }
    if (this.state.password !== this.state.reTypePassword) {
      this.setState({ invalidReTypePassword: true });
      this.setState({ invalidPassword: true });
      isValid = false;
    }
    if (this.state.invalidCapitalLetter || this.state.invalidLength || this.state.invalidLowercaseLetter || this.state.invalidNumber || this.state.invalidSym) {
      isValid = false;
    }
    return isValid;
  }

  handleResetPasswordResponse = (responseJSON: any) => {
    this.setState({ isLoading: false });
    if (responseJSON?.errors) {
      this.setState({ errorMessage: responseJSON?.errors[0].token || responseJSON?.errors || 'Something went wrong', resetSuccessMsg: '' });
      return;
    }

    this.setState({ errorMessage: '', resetSuccessMsg: configJSON.resetSuccessMsg });
    this.props.navigation.navigate("EmailAccountLoginBlock"); 
  }

  gotoLogIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  onSubmitReset = () => {
    const isAllValid = this.validateInputs();

    if (isAllValid) {
      this.doResetPassword();
    }
  }

  doResetPassword = () => {
    this.setState({ isLoading: true });

    const body = {
      "data": {
        "password": this.state.password
      }
    }

    this.resetPasswordId = sendAPIRequest(
      `/account_block/reset_password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.resetToken
        },
        body,
      }
    )
  }
}
// Customizable Area End
