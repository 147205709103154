// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest, formatDateString } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

type ErrorType = string | { token: string }[];

type CartItem = {
  id: string;
  type: string;
  attributes: {
    id: number;
    quantity: number;
    productdescription: {
      data: ProductDescription | null;
    };
  };
};

interface ProductDescription {
  type: string;
  id: string;
  attributes: {
    product_id: null;
    name: string;
    brand: string;
    packaging: string;
    description: string;
    sku_no: string;
    images: Array<{
      url: string;
      file_name: string;
      content_type: string;
      id: number;
    }>;
    review_count: number;
    review: {
      data: Array<unknown>;
    };
  };
}

interface ProductImage {
  url: string;
}

interface Item {
  product_id: number;
  name: string;
  brand: string;
  description: string;
  quantity: number;
  sku: string; 
  itemNumber: number;
  uom: string;
  image: ProductImage[];
}

interface OrderData {
  sapOrderNumber: string;
  shipToAddress: string;
  requestedDeliveryDate: string;
  purchaseOrder: string;
  purchaseOrderDate: string;
}

interface ApiResponse {
  data: OrderData[];
  message: string;
  status_code: string;
}

interface PastOrder {
  orderId: string;
  addressName: string;
  orderPlacedDate: string;
  orderDeliveredDate: string;
  sapNumber: string;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isLoading: boolean;
  token: string;
  errorMessage: string;
  successMessage: string;
  cartProductIds: number[];
  pastOrders: PastOrder[];
  }

interface SS {
  id: any;
}
interface INavigateTo {
  id?: number | string;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
export default class OrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchOrderHistoryId = '';
  fetchOrderDetailsId = '';

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      token: '',
      errorMessage: '',
      successMessage: '',
      cartProductIds: [],
      pastOrders: [],
          };
  }

  get showToast() {
    const { errorMessage } = this.state;
    return errorMessage !== '';
  }

  get toastMessage() {
    const { errorMessage } = this.state;
    return errorMessage;
  }

  get toastModifyClass() {
    const { errorMessage } = this.state;
    return errorMessage ? 'error' : 'success';
  }

  isAlreadyInCart(productId: number) {
    return this.state.cartProductIds.includes(productId);
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await getStorageData('authToken');
    this.setState({ token });

    this.fetchOrderDetails();
    this.fetchOrderHistory();
  }

  fetchOrderDetails = () => {
    this.setState({ isLoading: true });

    this.fetchOrderDetailsId = sendAPIRequest(
      `/bx_block_shopping_cart/shopping_cart`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        }
      }
    )
  }

  fetchOrderHistory = () => {
    this.setState({ isLoading: true });

    this.fetchOrderHistoryId = sendAPIRequest(
      configJSON.orderApi,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        }
      }
    )
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.fetchOrderDetailsId]: this.handleFetchOrderDetailsResponse,
      [this.fetchOrderHistoryId]: this.handleFetchOrderHistoryResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
  
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      const errors = possibleErrors as ErrorType;
      if (Array.isArray(errors) && errors[0].token) {
        localStorage.clear();
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else {
        this.setState({ errorMessage: typeof errors === 'string' ? errors : 'Something went wrong' , isLoading: false });
      }
      return true; // Indicates that there was an error
    }
  
    return false; // Indicates that there was no error
  }

  handleFetchOrderDetailsResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState({ errorMessage: '' });

    let cartIds: (number | null)[] = [];
    const data  = responseJSON.data as CartItem[];
    cartIds = data.map((item: {
      id: string;
      type: string;
      attributes: {
        id: number;
        quantity: number;
        productdescription: {
          data: ProductDescription | null;
        };
      };
    }) => {
      if (!item.attributes.productdescription?.data) {
        return null; 
      }
      return +item.attributes.productdescription.data.id;
    });
    
    // Filter out any null values from the cartIds array
    const filteredCartItems = (cartIds.filter(item => item !== null)) as number[];
    filteredCartItems.sort((idOne, idTwo) => idOne - idTwo);

    this.setState({
      cartProductIds: filteredCartItems,
    });
  }

  handleFetchOrderHistoryResponse = (responseJSON: Record<string, unknown>) => {
if (this.handleErrorResponse(responseJSON)) return;
    
    this.setState({ errorMessage: '' });

    const response = responseJSON as unknown as ApiResponse;
    const pastOrders = response.data.map((apiResponse) => {
      const orderData = apiResponse;
 
      return {
        orderId: orderData.purchaseOrder,
        addressName: orderData.shipToAddress,
        orderPlacedDate: formatDateString(orderData.purchaseOrderDate),
        orderDeliveredDate: formatDateString(orderData.requestedDeliveryDate),
        sapNumber: orderData.sapOrderNumber,
      };
    });
    this.setState({ pastOrders,isLoading:false });
  }

    navigateTo = ({
    id,
    props,
    screenName,
    raiseMessage,
    }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
    }
  }

// Customizable Area End