import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { ShallowWrapper } from "enzyme";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}

export function sendAPIRequest(endpoint: string, params?: RequestParams) {
  const { method = "GET", headers, body } = params || {}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  const callId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )
  if (body)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body?.append ? body : JSON.stringify(body)
    )

  if (headers)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  return callId
}

export function findByDataTest(wrapper: ShallowWrapper, dataTestValue: string): ShallowWrapper {
  // The returned value will be another shallow wrapper
  return wrapper.find(`[data-test="${dataTestValue}"]`);
}

export function truncateTextToTenWords(text: string): string {
  const words = text.split(/\s+/); // Split the text by any whitespace
  if (words.length > 10) {
    const firstTenWords = words.slice(0, 10); // Take the first 10 words
    return firstTenWords.join(' ') + '...'; // Join the words back into a string and add an ellipsis
  }
  return text; // Return the original text if it's 10 words or less
}

export function getDefaultCountryIndex(countryArr: any) {
  const idx = countryArr.findIndex((country: any) => country.value === "United States");
  return idx !== -1 ? idx : 0;
}

export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

interface IModalProps {
  open: boolean;
  onClose: () => void;
  confirmText: string;
  customStyles?: { text: {} };
  dataTest?: string;
}

const webStyle = {
  dialogButton: {
    color: "#FFF",
    borderRadius: 8,
    textTransform: "none",
    fontWeight: 700,
    fontFamily: "Arial, sans-serif",
    padding: 10, 
    width: 120,
  },
  navItem: {
    fontWeight: 700,
    color: "#334155",
    fontSize: 12, 
    lineHeight: "18px",
    cursor: "pointer",
    fontFamily: "Arial, sans-serif"
  },
};

export const ConfirmModal: React.FC<IModalProps> = ({ dataTest = 'confirm-modal', open, onClose, confirmText, customStyles = { text: {} } }) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose} data-test={dataTest}>
      <DialogContent dividers>
        <IconButton style={{ position: "absolute", right: 0, top: 0 }} aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Typography align="center" style={{ ...webStyle.navItem, fontSize: 18, ...customStyles.text }}>
            {confirmText}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button style={{ ...webStyle.dialogButton as React.CSSProperties, backgroundColor: "#004E9E" }}
          onClick={onClose} >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
interface INavigateTo {
  id?: number;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}

export const navigateTo = ({
  id,
  props,
  screenName,
  raiseMessage,
  }: INavigateTo) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
  id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
  raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  runEngine.sendMessage(message.id, message);
  }