//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images:{url:string}[] };
  isloading: boolean;
  focusedImageIndex: number;
  quantity: number,
  isLoading: boolean,
  openDialogSuccess: boolean
  isAddtoCart: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  addToCartId: string = "" 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      productData: { name: "", price: 1, description: "", images: [{url:""}] },
      focusedImageIndex:1,
      quantity: 1,
      isLoading: false,
      openDialogSuccess: false,
      isAddtoCart: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
   
      if (apiRequestCallId === this.productApiItemCallId) {
        if (responseJson?.data) {
          this.setState({ productData: responseJson.data.attributes });
        } else {
          this.handleResponseError(responseJson.errors)
        }
      }
      if (apiRequestCallId === this.addToCartId) {
        if (responseJson?.data) {
          this.setState({ isLoading: false, openDialogSuccess: true, isAddtoCart: true });
        } else {
          this.handleResponseError(responseJson.errors)
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount() {
    this.getPostData()
  }

  handleToggleSuccess = () => {
    this.setState({openDialogSuccess: !this.state.openDialogSuccess})
  }

  handleResponseError = (errors: any) => {
    let listError = {};
    errors.forEach(err => {
      listError = {...listError, ...err}
    })
    if("token" in listError) this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };

  goHome = () => {
    this.props.navigation.navigate("Catalogue")
  }

  onChangeQuantity = (key: "add" | "reduce") => {
    let quantity = key === "add" ? this.state.quantity + 1 : this.state.quantity - 1;
    if(quantity < 0) quantity = 0
    this.setState({quantity})
  }

  handleClickAddToCart = () => {
    this.setState({
      isLoading: true
    });
    this.addToCart()
  }

  addToCart = async () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToCartId = requestMessage.messageId;
    const id = this.props.navigation.getParam("id");
    const body = {
      "data": {
        "attributes": {
          "productdescription_id": id,
          "quantity": this.state.quantity
        }
      }
    }
    let api_url = `${configJSON.addToCartEndpoint}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      api_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getPostData = async () =>  {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productApiItemCallId = requestMessage.messageId;
    const id = this.props.navigation.getParam("id")
    let api_url = `${configJSON.productAPiEndPoint}/${id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      api_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  // Customizable Area End
}
