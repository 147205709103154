import React from "react";
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from "../../../framework/src/IBlock";

export interface Props {
  isLoggedIn: boolean;
  navigation: any;
}

export interface S {

}

export default class SessionGate extends BlockComponent<Props, S, {}> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ]

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  receive(from: string, message: Message): void {
    const messageError = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (messageError === 'An error has occuured. Please try again later.') {
      this.props.navigation.history.replace('/login');
    }
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    )
  }

}