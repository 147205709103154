Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.addAddressAPiEndPoint = "bx_block_order_management/addresses";
exports.addAddressApiMethod = "POST";
exports.updateAddressApiMethod = "PATCH";
exports.addAddressApiContentType = "multipart/form-data";
exports.getAddressApiEndPoint = "bx_block_order_management/addresses";
exports.getAddressApiMethod = "GET";
exports.getAddressApiContentType = "application/json";
exports.txtAddAddressTitle = "Agregar Dirección";
exports.txtLabelAddress = "Dirección";
exports.txtLabelAddressType = "Tipo de Dirección";
exports.txtLabelLat = "Latitud";
exports.txtLabelLng = "Longitud";
exports.errorTitle = "Error";
exports.btnAdd = "Agregar";
exports.addressListLabel = "Lista de Direcciones";
exports.successMsgTitle = "Agregar Dirección";
exports.successMsg = "¡Dirección agregada exitosamente!";
exports.addressTitle = "Dirección: ";
exports.addressTypeTitle = "Tipo de Dirección: ";
exports.latitudeTitle = "Latitud: ";
exports.longitudeTitle = "Longitud: ";
exports.title = "Agregar Nueva Dirección";
exports.firstName= "Nombre";
exports.lastName = "Apellido";
exports.country = "País";
exports.city = "Ciudad";
exports.zipCode = "Código Postal";
exports.street = "Calle";
exports.buildingNum = "Número de Edificio";
exports.setDefault = "Establecer como dirección de envío predeterminada";
exports.checkout = "Pagar";
exports.selectShipmentAddress= "Seleccionar Dirección de Envío";
exports.selectAddress =" Seleccionar Dirección";
exports.addNewAddress="Agregar nueva dirección";
exports.reviewOrder = "Revisar Orden";
exports.successMsg= "Tu dirección ha sido agregada exitosamente";
exports.updateSuccessMsg= "Tu dirección ha sido actualizada exitosamente";
exports.serviceNotAvailable = "Servicio no disponible";
exports.invalidCZipode = "Código Postal inválido";
exports.dummyText = "Some dummy text";
// Customizable Area End
