// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  isLoading: boolean;
  token: string;
  errorMessage: string;
  successMessage: string;
  emailValue: string;
  isActivated: boolean;
}

interface SS {
  navigation: any;
}

export default class EmailConfirmationController extends BlockComponent<
  Props,
  S,
  SS
> {
  confirmEmailId = "";
  resendLinkId = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      token: "",
      errorMessage: "",
      emailValue: "",
      isLoading: false,
      successMessage: "",
      isActivated: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token") ?? "";
    const email = urlSearchParams.get("email") ?? "";
    this.setState({ token, emailValue: email }, () => {
      this.callConfirmEmail();
    });
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any
  ) => {
    const successCallbackMap = {
      [this.confirmEmailId]: this.handleConfirmEmailResponse,
      [this.resendLinkId]: this.handleResendLinkResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleConfirmEmailResponse = (responseJSON: any) => {
    this.setState({ isLoading: false });
    if (responseJSON?.errors) {
      this.setState({
        errorMessage:
          responseJSON?.errors[0].token ||
          responseJSON?.errors ||
          "Something went wrong",
        successMessage: "",
      });
      return;
    }

    this.setState({ errorMessage: "", isActivated: true });
  };

  handleResendLinkResponse = (responseJSON: any) => {
    this.setState({ isLoading: false });
    if (responseJSON?.errors) {
      this.setState({
        successMessage: "The email confirmation has been sent successfully!",
        errorMessage:
          responseJSON?.errors[0].token ||
          responseJSON?.errors ||
          "Something went wrong",
      });
      return;
    }

    this.setState({ errorMessage: "" });
  };

  gotoLogIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  callConfirmEmail = () => {
    this.confirmEmailId = sendAPIRequest(
      `/account_block/accounts/email_confirmation`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.state.token,
        },
      }
    );
    this.setState({ isLoading: true });
  };

  resendLink = () => {
    const body = {
      data: {
        attributes: {
          email: this.state.emailValue,
        },
      },
    };

    this.resendLinkId = sendAPIRequest(`/account_block/accounts/email_resend`, {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.setState({ isLoading: true });
  };
}
// Customizable Area End
