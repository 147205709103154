import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  selectSearchItem?: (item:any) => void
  isAddtoCart?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  width: number;
  searchValue: string
  listSuggestions: Array<any>
  isEmptyCart: boolean
  openDialog: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiGetSuggestionId: string = ""
  searchTimeOut: ReturnType<typeof setTimeout>
  getShoppingCartId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      width: window.innerWidth,
      searchValue: "",
      listSuggestions: [],
      isEmptyCart: true,
      openDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.searchTimeOut = setTimeout(()=>{}, 100)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.apiGetSuggestionId === apiRequestCallId) {
        if (responseJson?.suggestions) {
          this.setState({ listSuggestions: [...responseJson.suggestions] })
        } else if(responseJson?.errors) {
          this.handleResponseErrors(responseJson.errors)
        }
      } 
      if(this.getShoppingCartId === apiRequestCallId) {
        if(responseJson?.data) {
          this.setState({isEmptyCart: responseJson.data.length === 0})
        }
      }
    }
  
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (config: any) => {
      const token = await getStorageData("authToken");
      if(!token) this.goLogin()
      const {body, contentType, endpoint, method} = config
  
      const header = {
        "Content-Type": contentType,
        token: token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    };

  getShoppingCartItem = async () => {
    this.getShoppingCartId = await this.apiCall({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.methodGet,
      endpoint: configJSON.getShoppingCartEndpoint
    })
  }

  goHome = () => {
    this.props.navigation.navigate("Catalogue")
  }

  goOrderHistory = () => {
    this.props.navigation.navigate("OrderHistory");
  }

  handleResponseErrors = async (error: any) => {
    let list = {};
    error.forEach((err: any) => list = {...list, ...err})
    if("token" in list) {
      await this.handleLogout()
      this.goLogin()
    }
  }

  goLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  gotoSettings = () => {
    this.props.navigation.navigate("Settings2")
  }

  gotoCart = () => {
    this.props.navigation.navigate("ShoppingCartOrders")
  }

  onChangeSearchValue = (value: string | null) => {
    clearTimeout(this.searchTimeOut)
    this.searchTimeOut = setTimeout(() => {
      this.setState({searchValue: value ?? ""})
      this.getSearchSuggestions(value ?? "")
    }, 500)
  }

  isLastOption = (option: string) => {
    const index = this.state.listSuggestions.indexOf(option);
    return index === this.state.listSuggestions.length-1
  }

  selectSearchItem = (item: any) => {
    this.props.selectSearchItem?.(item)
    setStorageData("search_key", item)
    this.props.navigation.navigate("Search")

  }
  handleEnterSearch = (event: any) => {
    if(event.key==="Enter" && event.target.value !== "") {
      event.stopPropagation()
      this.selectSearchItem(event.target.value)
    }
  }

  getSearchSuggestions = async (value: string) => {
    this.apiGetSuggestionId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endpoint: `${configJSON.getSuggestionEndpoint}?query=${value}`,
      method: configJSON.methodGet
    })
  }
 
  toggleDrawer = (event: any) => {
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    let tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (!this.isPlatformWeb()) {
        Linking.openURL(path);
      } else {
        window.open(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getSearchSuggestions("@")
    this.getShoppingCartItem()
    window.addEventListener("resize", () => this.changeSize())
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevProps.isAddtoCart !== this.props.isAddtoCart) {
      this.getShoppingCartItem()
    }
  }

  changeSize = () => {
    this.setState({width: window.innerWidth})
  }

  handleToggleDialog = () => {
    this.setState({openDialog: !this.state.openDialog})
  }

  handleLogout = async () => {
    await removeStorageData(configJSON.authToken);
    await removeStorageData(configJSON.rememberMe);
    await removeStorageData(configJSON.refreshToken);
    await removeStorageData(configJSON.searchKey);
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  // Customizable Area End
}
