import React from "react";
// Customizable Area Start
import { Box,Button,Container,Dialog,DialogActions,DialogContent,Grid,styled,Typography} from "@material-ui/core";
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import { configJSON } from "./CatalogueController";
import { 
  backgroundImg,
  subImg,
  addImg,
  shopImg,
  backgroundImgSecond
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import OfflinePinSharpIcon from '@material-ui/icons/OfflinePinSharp';
import FooterWeb from "../../../components/src/Footer.web";
import { Pagination } from "@material-ui/lab";
const newTheme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif !important"
      }
    }
  }
})
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  
  renderButtonQuantity = (index: number, icon: any, testId: string, cb: (index: number) => void) => {
    return <Box className="subBox" style={{ width: this.state.width > 1100 ? 40 : 30 }} data-test-id={testId}
      onClick={() => cb(index)}>
      <img src={icon} alt="" />
    </Box>
  }
  renderDialogSuccess = () => {
    return <Dialog fullWidth open={this.state.isOpenDialogSuccess}>
      <DialogContent dividers>
        <Box style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20 }}>
          <OfflinePinSharpIcon style={{ fontSize: 50, color: "#34D399" }} />
          <Typography align="center" style={{ ...webStyle.title }}>
            {configJSON.successMessage}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.toggleDialogSuccess} style={{ ...webStyle.btnOk }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  }
  renderImage = (item: any) => {
    return <img src={item.attributes?.images?.[0]?.url}
      className="productImg"
      alt="" />
  }
  renderEmptySearchResult = () => {
    return this.state.productList.length === 0 && !this.state.isLoadingSearch &&
      <Typography style={{...webStyle.title, fontWeight: 400}}>
        {configJSON.emptySearch}
      </Typography>
  }
  renderProductContainer = () => {
    return (
      <Grid container spacing={3} style={{ marginTop: 30 }}>
        {
          this.state.productList.map((item, index) => (
            <Grid item md={3} sm={6} xs={12} data-test-id={`productListTestID-${index}`} key={item.id}>
              <Box className="productView">
                <Box data-test-id={`addProductId-${index}`} onClick={() => this.handleNavigateProductDescription(item.id)} >
                  {this.renderImage(item)}
                </Box>

                <Box className="textContainerTwo">
                  <Box className="engineOilView">
                    <Typography className="engineOilText" data-test-id="nameTestID">{item.attributes.name}</Typography>
                    <Typography className="titanText">{item.attributes.brand}</Typography>
                  </Box>
                  <Box width={"100%"}>
                    <Typography className="descriptionText">
                      {configJSON.desText}: {item.attributes.description} {" "}
                    </Typography>
                    <Typography data-test-id={`showMoreTestID-${index}`}
                      onClick={() => this.handleNavigateProductDescription(item.id)}
                      className="readMoreText">
                      {configJSON.readMore}
                    </Typography>
                  </Box>
                  <Grid container className="quantityBox">
                    <Typography className="quantityText">{configJSON.quantitytext}</Typography>
                    <Box className="productNumberBox">
                      {this.renderButtonQuantity(index, subImg, `removePorductTestID-${index}`, this.handleRemoveQuantity)}
                      <Typography className="numberText">{item.qty < 10 && item.qty > 0 ? `0${item.qty}` : item.qty}</Typography>
                      {this.renderButtonQuantity(index, addImg, `addPorductTestID-${index}`, this.handleAddQuantity)}
                    </Box>
                  </Grid>
                  <AddCardButton data-test-id="add-to-cart" onClick={() => { this.handleAddToCart(item.id) }}>
                    <img src={shopImg} alt="" />
                    <Typography className="addCartText">{configJSON.addCartText}</Typography>
                  </AddCardButton>
                </Box>

              </Box>
            </Grid>
          ))
        }
        {this.renderDialogSuccess()}
      </Grid>
    )
  }
  Pagination = () => {
    return this.state.totalPage && 
     <CustomPagination 
      data-test-id="catalogue-pagination"
      onChange={this.handleChangePage} 
      count={this.state.totalPage} 
      shape="rounded" 
      hideNextButton hidePrevButton />
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={newTheme}>
        <NavigationMenu isAddtoCart={this.state.isAddToCart} selectSearchItem={this.handleSearchProduct} navigation={this.props.navigation} id={"home-navigation"} />
        <Box style={{backgroundColor: "#f5f5f5", paddingBottom: 100}}>
          <Box>
            <BackgroundImg className="background1">
              <Box className="textContainer">
                <Typography className="marketTextHeading">{configJSON.marketText}</Typography>
                <Typography className="marketTextHeadingTwo">{configJSON.marketTextTwo}</Typography>
              </Box>
            </BackgroundImg>
          </Box>
          <Container maxWidth="xl">
            <ProductBox >
              {this.state.searchKey ?
                <Typography component={"span"} className="ourProductText">
                  {configJSON.searchResult}: "<Typography component={"span"} className="ourProductText search">{this.state.searchKey}</Typography>" 
                </Typography> :
                <Typography className="ourProductText">
                  {configJSON.productText}</Typography>}
              {this.renderProductContainer()}
              {this.renderEmptySearchResult()}
              {this.Pagination()}
            </ProductBox>
          </Container>
          {!this.state.searchKey && 
            <BackgroundImg className="background2">
              <Box className="textContainer" style={{width: "93%"}}>
                <Typography className="marketTextHeading">{configJSON.title2}</Typography>
              </Box>
            </BackgroundImg>
          }
        </Box>
        <FooterWeb />
      </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productImg: {
    width: "100%",
    height: "100%"
  },
  btnOk: {
    backgroundColor: "#004E9E",
    width: 120,
    borderRadius: 8,
    fontWeight: 700, 
    fontSize: 18, 
    color: "#FFF"
  },
  title: {
    color: "#0F172A",
    fontWeight: 700, 
    fontSize: 20,
  }
};

const BackgroundImg = styled(Box)({
 
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundRepeat: " no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  "&.background1" : {
    backgroundImage: `url(${backgroundImg})`,
    height: "475px",
  },
  "&.background2": {
    backgroundImage: `url(${backgroundImgSecond})`,
    minHeight: 300,
    height: "fit-content"
  },

  "& .textContainer": {
    width: "100%",
    background: "#004E9ECC",
    padding: "50px 25px 50px 25px",
    flexDirection: "column"
  },

  "& .marketTextHeading": {
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "44px",
    color: "#FFF"
  },

  "& .marketTextHeadingTwo": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFF",
    top: "5px"
  },
});

const CustomPagination = styled(Pagination)({
  "& .MuiPagination-ul": {
    justifyContent: "center",
    marginTop: 30,
    "& .MuiPaginationItem-page": {
      backgroundColor: "#93C5FD",
      color: '#fff',
      borderRadius: 8,
      "&.Mui-selected": {
        backgroundColor: "#004E9E"
      }
    }
  }
})

const ProductBox = styled(Box)({
  padding: "30px 20px",
  backgroundColor: "#f5f5f5",

  "& .ourProductText": {
    fontSize: "30px",
    fontWeight: "bold",
    lineHeight: "40px",
    color: "#0F172A",
    "&.search" : {
      color: "#004E9E"
    }
  },

  "& .productView": {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    background: "#fff",
    boxShadow: "0px 2px 5px 0px rgb(227,227,227)",
    marginBottom: 20
  },

  "& .productImg": {
    width: "100%",
    cursor: "pointer",
    objectFit: "contain", 
    height: 220
  },

  "& .textContainerTwo": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "15px",
    padding: 15
  },

  "& .engineOilView": {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  "& .engineOilViewSecond":{
    height: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  "& .quantityBox": {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "Center",
    justifyContent: "space-between"
  },

  "& .quantityText": {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
  },

  "& .subBox": {
    height: "40px",
    width: "40px",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#004E9E",
    cursor: "pointer"
  },

  "& .productNumberBox": {
    display: "flex",
    alignItems: "center"
  },

  "& .engineOilText": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "5px"
  },

  "& .titanText": {
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: "uppercase"
  },

  "& .readMoreText":{
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "5px",
    color:"rgba(100, 116, 139, 1)",
    cursor: "pointer"
  },

  "& .descriptionText": {
    color: "#64748B",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: "5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: 44,
    lineClamp: 2,
    WebkitLineClamp: 2,
    display: "-webkit-box", 
    WebkitBoxOrient: "vertical",
    height: 44
  },

  "& .numberText": {
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    padding: "0 10px"
  }
});

const AddCardButton = styled(Button)({
  height: "50px",
  width: "100%",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#004E9E",
  marginBottom:"30px",
  "&:hover" : {
    backgroundColor: "#033C77"
  },
  "& .addCartText": {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px"
  }
});
// Customizable Area End
