import React from 'react';
import { Box, withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { facebookIcon, linkedInIcon, twitterIcon, instagramIcon, companyLogo } from './assets';

// Define styles using withStyles
const styles: any = (theme: Theme) => createStyles({
  footerContainer: {
    backgroundColor: '#376BAE',
    width: '100%',
    padding: '80px 0',
    boxSizing: 'border-box',
  },
  maxWidthContent: {
    margin: '0 auto',
    maxWidth: 1440,
    padding: '0 38px',
    boxSizing: 'border-box',
  },
  divider: {
    border: 'none',
    height: 1,
    backgroundColor: '#fff',
    margin: '0 -9999rem',
    padding: 0,
  },
  socialMediaRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 77,
    marginBottom: 42,
  },
  followUsText: {
    marginRight: 15,
    color: '#fff',
    fontFamily: '"Arial", sans-serif',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '26px',
  },
  socialLink: {
    color: '#fff',
    marginRight: 24,
    textDecoration: 'none',
    '&:last-child': {
      marginRight: 0,
    },
  },
  companyLogo: {
    display: 'block',
    marginTop: theme.spacing(2.5),
    maxWidth: '100%',
    height: 'auto',
  },
});

interface Props extends WithStyles<typeof styles> { }

// Footer component
const Footer: React.FC<Props> = ({ classes }) => {
  return (
    <Box className={classes.footerContainer}>
      <Box className={classes.maxWidthContent}>
        <hr className={classes.divider} />
        <Box className={classes.socialMediaRow}>
          <span className={classes.followUsText}>Síguenos</span>
          <a target="_blank" href="https://www.facebook.com/fuchslubricantsco" className={classes.socialLink}>
            <img className={classes.socialMediaIcon} src={facebookIcon} alt="Facebook icon" />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/fuchs-lubricants/" className={classes.socialLink}>
            <img className={classes.socialMediaIcon} src={linkedInIcon} alt="Linkedin icon" />
          </a>
          <a target="_blank" href="https://twitter.com/fuchs_us" className={classes.socialLink}>
            <img className={classes.socialMediaIcon} src={twitterIcon} alt="Twitter icon" />
          </a>
          <a target="_blank" href="https://www.instagram.com/fuchslubricantsus/" className={classes.socialLink}>
            <img className={classes.socialMediaIcon} src={instagramIcon} alt="Instagram icon" />
          </a>
        </Box>
        <img src={companyLogo} alt="Company Logo" className={classes.companyLogo} />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Footer);