Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.methodPost = "POST";
exports.methodGet = "GET"
exports.getSuggestionEndpoint = "bx_block_advanced_search/search_suggestions"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Abrir Cajón";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Cerrar sesión";

exports.home = "Inicio";
exports.orderHistory = "Historial de Pedidos";
exports.products = "Productos";

exports.colorNeutral = "#334155"

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.getShoppingCartEndpoint = "bx_block_shopping_cart/shopping_cart"
exports.logout = "Cerrar sesión";
exports.settings = "Configuración";
exports.cart = "Carrito";
exports.confirmLogoutText = "¿Estás seguro de que quieres cerrar sesión de tu cuenta?";
exports.authToken = "authToken";
exports.refreshToken = "refresh_token";
exports.searchKey = "search_key";
exports.rememberMe = "remember_me"
// Customizable Area End