import React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import OfflinePinSharpIcon from '@material-ui/icons/OfflinePinSharp';

interface IModalProps {
  open: boolean;
  onClose: () => void;
  confirmText: string;
}

const webStyle = {
  dialogButton: {
    color: "#FFF",
    borderRadius: 8,
    textTransform: "none",
    fontWeight: 700,
    fontFamily: "Arial, sans-serif",
    width: 120,
    backgroundColor: "#004E9E",
    fontSize: 18,
  },
  navItem: {
    fontFamily: "Arial, sans-serif",
    color: "#0F172A",
    fontWeight: 700, 
    fontSize: 20,
  },
};

const SuccessModal: React.FC<IModalProps> = ({ open, onClose, confirmText }) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent dividers>
        <div style={{ marginTop: 20, display: "flex", alignItems: "center", flexDirection: "column", }}>
          <OfflinePinSharpIcon style={{ color: "#34D399", fontSize: 50, }} />
          <Typography style={{ ...webStyle.navItem, }}>
            {confirmText}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button style={{ ...webStyle.dialogButton as React.CSSProperties, backgroundColor: "#004E9E" }}
          onClick={onClose} >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModal;