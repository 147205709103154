Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Correo electrónico";
exports.labelHeader =
  "Este es tu perfil. Aquí puedes ver y actualizar tu información personal.";
exports.labelFirstName = "Nombre";
exports.lastName = "Apellido";
exports.labelArea = "Área";
exports.labelMobile = "Móvil";
exports.labelEmail = "Correo electrónico";
exports.labelCurrentPassword = "Contraseña actual";
exports.labelNewPassword = "Nueva contraseña";
exports.labelRePassword = "Vuelve a escribir la contraseña";
exports.btnTextCancelPasswordChange = "Cancelar";
exports.btnTextSaveChanges = "Guardar cambios";
exports.btnTextChangePassword = "Cambiar contraseña";
exports.errorCountryCodeNotSelected = "Por favor selecciona el código de país";
exports.errorMobileNoNotValid = "El número de teléfono no es válido.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Las contraseñas deben coincidir.";
exports.errorCurrentNewPasswordMatch = "La nueva contraseña no puede coincidir con la contraseña actual.";
exports.errorCurrentPasswordNotValid = "La contraseña actual no es válida.";
exports.errorNewPasswordNotValid = "La nueva contraseña no es válida.";
exports.errorReTypePasswordNotValid = "La contraseña vuelta a escribir no es válida.";
exports.hintCountryCode = "Selecciona país";
exports.errorBlankField = "no puede estar en blanco";
exports.errorEmailNotValid = "Correo electrónico no válido.";
// Customizable Area End

