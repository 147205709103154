import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import { backgroundImg, logo } from "./assets";
const config = require("./config")

const styles = {
  pe0: {
    paddingRight: 0
  },
  ps0: {
    paddingLeft: 0
  },
  container: {
    display: "flex", 
    height: "auto",
    minHeight: "100vh"
  },
  logo: {
    position: "relative", 
    top: 10, 
    left: 20
  },
  sloganTitle: {
    fontSize: 36, 
    lineHeight: "44px", 
    fontWeight: 700,
    color: "#fff",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Arial, sans-serif"
  },
  sloganContent: {
    fontSize: 18, 
    fontWeight: 400, 
    lineHeight: "26px", 
    color: "#fff",
    textAlign: "center",
    fontFamily: "Arial, sans-serif"
  },
  labelTitle: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: 20
  },
  formLabel: {
    fontWeight: 700,
    lineHeight: "24px", 
    fontSize: 16,
    color: "#334155",
  },
  normalText: {
    fontWeight: 400, 
    fontSize: 16,
    lineHeight: "22px"
  },
  btnLogin: {
    backgroundColor: config.colorPrimary,
    padding: 16,
    borderRadius: 8,
    textTransform: "none",
    fontWeight: 700, 
    color: "#fff",
    fontSize: 16
  },
  inputStyle: {
    border: "1px solid #CBD5E1", 
    padding: 10, 
    borderRadius: 8,
    marginTop: 10
  },
  errorText: {
    color: config.colorError,
    fontSize: 12, 
    marginTop: 5,
    fontFamily: "Arial, sans-serif"
  }
}


const newTheme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
  errorToast = () => {
    return this.state.emailError &&
      <Box sx={{ width: "100%" }} style={{ backgroundColor: "#FEE2E2", marginTop: 10, position: "relative", borderRadius: 8 }}>
        <Box style={{ width: 5, height: "100%", background: config.colorError, position: "absolute", borderRadius: "8px 0 0 8px" }}></Box>
        <Typography style={{ ...styles.errorText, marginTop: 0, padding: "12px 16px" }}>
          {this.state.errorMessage}
        </Typography>
      </Box>
  }
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={newTheme}>
        <Container maxWidth={false} style={{...styles.pe0, ...styles.ps0, ...styles.container}}>
          <Grid container style={{ ...styles.container }}>
            <Grid item md={6} sm={12} style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Box sx={{ width: "100%" }}>
                <img src={logo} alt="" style={styles.logo as React.CSSProperties} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                  margin: "0 auto",
                  width: this.state.width > 576 ? "50%" : "80%"
                }}
                style={this.state.width < 960 ? {
                  position: "relative",
                  top: 0,
                  transform: "none",
                  marginBottom: 20
                } : {
                  position: "relative",
                }
                }
              >
                <Typography style={styles.labelTitle}>
                  {this.labelTitle}
                </Typography>
                {this.errorToast()}

                <Box sx={{ width: "100%" }}>
                  <Box sx={{ padding: "20px 0px" }}>
                    <Typography style={styles.formLabel}>{config.placeHolderEmail}</Typography>
                    <Input
                      data-test-id="txtInputEmail"
                      placeholder={"Tu correo electrónico"}
                      fullWidth={true}
                      value={this.state.email}
                      onChange={(e) => {this.handleChangeEmail(e.target.value)}}
                      style={{ ...styles.inputStyle }}
                      disableUnderline
                    />
                  </Box>
                  <Box sx={{ padding: "5px 0px" }}>
                    <Typography style={styles.formLabel}>{config.placeHolderPassword}</Typography>
                    <Input
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      placeholder={"Tu contraseña"}
                      fullWidth={true}
                      value={this.state.password}
                      onChange={(e) => this.handleChangePassword(e.target.value)}
                      style={{ ...styles.inputStyle, borderColor: this.state.passwordError ? config.colorError : "#CBD5E1" }}
                      disableUnderline
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            data-test-id="btn-show-hide-password"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <VisibilityOutlined style={{ color: "#94A3B8" }} />
                            ) : (
                              <VisibilityOffOutlined style={{ color: "#94A3B8" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {this.state.passwordError && <Typography style={{ ...styles.errorText }}>{config.wrongPassword}</Typography>}
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 40, marginTop: 5 }}>
                    <Box sx={{ display: "flex", alignItems: "ceenter" }}>
                      <Checkbox
                        data-test-id={"btnRememberMe"}
                        onClick={() =>
                          this.setRememberMe(!this.state.checkedRememberMe)
                        }
                        checked={this.state.checkedRememberMe}
                        style={{ padding: "0 5px 0 0", color: this.state.checkedRememberMe ? config.colorPrimary : "#64748B" }}
                        inputProps={{ color: "transparent" }}
                      />{" "}
                      <Typography style={{ ...styles.normalText, fontSize: 14, color: "#0F172A", alignSelf: "center" }}>
                        Recuérdame
                      </Typography>
                    </Box>
                    <Box
                      data-test-id={"btnForgotPassword"}
                      onClick={() => this.goToForgotPassword()}
                    >
                      <Typography style={{ ...styles.normalText, fontSize: 14, color: config.colorPrimary, fontWeight: 700, cursor: "pointer" }}>{config.forgotPassword}</Typography>
                    </Box>
                  </Box>


                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px 0px",
                      marginBottom: 40
                    }}
                  >
                    <Button
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      style={{ ...styles.btnLogin } as React.CSSProperties}
                      fullWidth
                      onClick={() => this.login()}
                    >
                      {config.btnLogin} {/*UI Engine::From Sketch*/}
                    </Button>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography style={{ ...styles.normalText, marginRight: 10 }}>
                      {config.newMember}
                    </Typography>
                    <Typography 
                      data-test-id="btn-signup"
                      onClick={this.gotoSignUp} style={{
                      ...styles.normalText,
                      cursor: "pointer",
                      fontWeight: 700, color: config.colorPrimary
                    }}>
                      {config.signUp}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} style={{ ...styles.container }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    width: "100%", height: "50%", backgroundImage: `url(${backgroundImg})`,
                    backgroundColor: "rgb(55, 107, 174)",
                    backgroundRepeat: " no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center"
                  }}></div>
                <Box sx={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
                  style={{ backgroundColor: "#376BAE" }} >
                  <Typography gutterBottom style={styles.sloganTitle as React.CSSProperties}>{config.sloganTitle}</Typography>
                  <Typography dangerouslySetInnerHTML={{ __html: config.sloganContent }} style={styles.sloganContent as React.CSSProperties}></Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

        </Container>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
