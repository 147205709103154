// Customizable Area Start
import React from "react";
import { facebookIcon, twitterIcon, instagramIcon, linkedInIcon, welcomeEmailBanner, productPhoto } from "./assets";
import OrderEmailTemplateController, {
  Props,
} from "./OrderEmailTemplateController";

export default class WelcomeEmailTemplate extends OrderEmailTemplateController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const productListSample = [
      {
        imgUrl: productPhoto, // absolute url when actually sending email
        name: 'FUCHS Engine Oil',
        type: 'TITAN',
        quantity: '03',
        orderDate: '05-03-2024',
        deliveryDate: '06-03-2024',
      },
      {
        imgUrl: productPhoto,
        name: 'FUCHS Engine Oil 2',
        type: 'TITAN',
        quantity: '02',
        orderDate: '05-03-2024',
        deliveryDate: '09-03-2024',
      },
    ];
    const { userName = 'John Doe', orderNumber = '2111211', productList = productListSample } = this.props;

    return (
      <div>
        <style type="text/css">
          {`
            @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')
            .product-row {
              border-top: 1px solid #f3f3f3;
              padding: 12px 5px;
            }
            .product-photo {
              width: 130px;
            }
            .product-details {
              text-align: left;
              padding-left: 15px;
            }
            .product-name {
              color: #0F172A;
              font-family: Arial;
              font-size: 14px;
              line-height: 22px;
              font-weight: bold;
              margin: 0;
              margin-bottom: 10px;
            }
            .product-info {
              color: #0F172A;
              font-family: "Arial", sans-serif;
              font-size: 16px;
              line-height: 24px;
              margin: 0;
              margin-bottom: 10px;
            }
            .product-info:last-child {
              margin-bottom: 0 !important;
            }
            @media only screen and (max-width: 600px) {
              .table-container {
                width: 100% !important;
                max-width: none !important;
              }
              .button {
                padding: 16px 10% !important;
              }
              .instagram-icon img {
                padding: 3 !important;
                width: 19px !important;
              }
              .social-icon {
                margin-right: 25px !important;
              }
              .social-icon:last-child {
                margin-right: 0 !important;
              }
            }
          `}
        </style>
        <table className="table-container" style={{ borderSpacing: '0', borderCollapse: 'collapse', width: '100%', padding: '0', }}>
          <tr>
            <td align="center">
              {/* Image Banner */}
              <table className="table-container" style={{ padding: '0', borderSpacing: '0', width: '100%', maxWidth: "600px", }}>
                <tr>
                  <td style={{ padding: 0 }}>
                    <img src={welcomeEmailBanner} alt="FUCHS Banner" style={{ display: 'block', width: '100%', maxWidth: '600px' }} />
                  </td>
                </tr>
              </table>

              {/* Welcome Text & Button */}
              <table className="table-container" style={{ maxWidth: "600px", width: '100%', padding: '0', borderSpacing: '0', borderCollapse: 'collapse', backgroundColor: '#ffffff' }}>
                <tr>
                  <td style={{ padding: '25px' }} align="center">
                    <p style={{ fontFamily: 'Arial, sans-serif', color: '#525F7F', fontSize: '16px', fontWeight: 400, lineHeight: "24px", marginBottom: '25px' }}>
                      Hi {userName}, Thank you for your purchase!
                    </p>
                    <p style={{ fontWeight: 700, color: '#1C1E23', letterSpacing: "-0.15px", fontFamily: 'Arial, sans-serif', lineHeight: "40px", marginBottom: '25px', fontSize: '30px' }}>
                      We've received your Order
                    </p>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 400, lineHeight: "24px", color: '#525F7F', fontSize: '16px', marginBottom: '0' }}>
                      Your Order No : {orderNumber}
                    </p>
                  </td>
                </tr>
              </table>

              <table className="table-container" style={{ padding: '0', borderSpacing: '0', width: '100%', maxWidth: "600px", borderCollapse: 'collapse', }}>
                <tr>
                  <td align="left" style={{ padding: '9px 19px 33px 19px' }}>
                    <p style={{ color: '#0F172A', fontWeight: 700, letterSpacing: "-0.12px", fontFamily: '"Arial", sans-serif', lineHeight: "32px", marginBottom: '12px', fontSize: '24px' }}>Orders</p>

                    {productList.map(product => (
                      <table key={product.name} className="product-row" style={{ borderTop: "1px solid #f3f3f3", padding: "12px 5px", width: "100%", borderSpacing: 0 }}>
                        <tr>
                          <td className="product-photo" style={{ width: "130px", padding: 0 }}>
                            <img
                              src={product.imgUrl}
                              alt="Product"
                              style={{ width: "100%", maxWidth: "130px", height: "auto", display: "block" }} />
                          </td>
                          <td className="product-details" style={{ textAlign: "left", paddingLeft: "15px" }}>
                            <p className="product-name" style={{ fontFamily: "'Arial', sans-serif", fontSize: "14px", color: "#0F172A", lineHeight: "22px", fontWeight: "bold", marginBottom: "10px" }}>{product.name}</p>
                            <p className="product-info" style={{ color: "#0F172A", fontFamily: "'Arial', sans-serif", fontSize: "16px", margin: 0, lineHeight: "24px", marginBottom: "10px", }}>{product.type}</p>
                            <p className="product-info" style={{ color: "#0F172A", fontSize: "16px", fontFamily: "'Arial', sans-serif", margin: 0, marginBottom: "10px", lineHeight: "24px" }}>Qty - {product.quantity}</p>
                            <p className="product-info" style={{ lineHeight: "24px", fontSize: "16px", margin: 0, marginBottom: "10px", fontFamily: "'Arial', sans-serif", color: "#0F172A", }}>Order Date : {product.orderDate}</p>
                            <p className="product-info" style={{ fontSize: "16px", lineHeight: "24px", margin: 0, color: "#0F172A", fontFamily: "'Arial', sans-serif", }}>Delivery Date : {product.deliveryDate}</p>
                          </td>
                        </tr>
                      </table>
                    ))}
                  </td>
                </tr>
              </table>

              <table className="table-container" style={{ padding: '0', borderSpacing: '0', width: '100%', maxWidth: "600px", borderCollapse: 'collapse', backgroundColor: '#376BAE' }}>
                <tr>
                  <td align="center" style={{ padding: '24px' }}>
                    <p style={{ color: '#FFF', fontSize: '12px', fontWeight: 400, fontFamily: 'Arial, sans-serif', lineHeight: "20px", marginBottom: '15px' }}>
                      &copy; FUCHS. All rights reserved.
                    </p>
                    <p style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Arial, sans-serif', color: '#FFF', lineHeight: "20px", marginBottom: '15px' }}>
                      If you have any questions please contact us <a style={{ textDecoration: "none", fontWeight: "bold", color: "#fff" }} href="mailto:Inquiry@fuchs.com" target="_blank">Inquiry@fuchs.com</a>
                    </p>

                    <p>
                      <a style={{ marginRight: '24px', display: 'inline-block', }} href="https://www.facebook.com/fuchslubricantsco" className="social-icon" target="_blank">
                        <img src={facebookIcon} alt="Facebook Link" width="24" />
                      </a>
                      <a href="https://www.linkedin.com/company/fuchs-lubricants/" className="social-icon" style={{ display: 'inline-block', marginRight: '24px' }} target="_blank">
                        <img alt="LinkedIn Link" src={linkedInIcon} width="24" />
                      </a>
                      <a href="https://twitter.com/fuchs_us" target="_blank" className="social-icon" style={{ display: 'inline-block', marginRight: '24px' }}>
                        <img src={twitterIcon} alt="Twitter Link" width="24" />
                      </a>
                      <a className="instagram-icon social-icon" style={{ display: 'inline-block' }} target="_blank" href="https://www.instagram.com/fuchslubricantsus/">
                        <img width="18" src={instagramIcon} alt="Instagram Link" style={{ padding: '3px', display: 'inline-block',  }} />
                      </a>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

// Customizable Area End
