import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  Container,
  ThemeProvider,
  Typography,
  createTheme,
} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { propStyle } from "./assets";
import {configJSON} from "./AddAddressController";
import AddressManagementController, {AddressType, Props} from "./AddressManagementController";
import AddAddress from "./AddAddress.web";
import FooterWeb from "../../../components/src/Footer.web";
// Customizable Area End



export default class AddressManagement extends AddressManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  AddressItem = (address: AddressType) => {
    return <Box
      key={address.id}
      style={{
        ...styles.flexBox,
        padding: 5,
        borderRadius: 8,
        backgroundColor: this.state.addressSelected.id === address.id ? "#73A7EA" : "transparent"
      }}
    >
      <Checkbox
        data-test-id="checkbox-select-address"
        onClick={() => { this.handleSelectAddress(address) }}
        checked={this.state.addressSelected.id === address.id} style={{ color: "#0F172A" }}
        checkedIcon={<CheckBoxOutlinedIcon />} />
      {!address.attributes.mulesoft_address ? <Typography style={{ ...propStyle.commonText }}>
        {address.attributes.building_number}, {address.attributes.street}
        , {address.attributes.city}, {address.attributes.country} - {address.attributes.zip_code}
      </Typography> :
        <Typography style={{ ...propStyle.commonText }}>
          {address.attributes.mulesoft_address} </Typography>
      }
    </Box>
  }
  Button = (testId: string, Icon: any, title: string, onClick: () => void) => {
    return <Button data-test-id={testId} onClick={onClick} style={{ ...propStyle.button, ...propStyle.bgColorPrimary, width: 362, padding: 10 } as React.CSSProperties}>
      <Icon style={{ marginRight: 10 }} />
      {title}
    </Button>
  }
    
  render() {
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu navigation={this.props.navigation} id="navigation-menu-address" />
        <Container maxWidth={false} style={styles.contentContainer}>
          <Container maxWidth="xl">
            <Typography style={styles.title}>{configJSON.checkout}</Typography>
            <Box style={styles.content}>
              <Box style={{ ...styles.flexBox, justifyContent: "space-between" }}>
                <Typography style={{ ...styles.title, fontSize: 24, lineHeight: "32px" }}>{configJSON.selectShipmentAddress}</Typography>
                {!this.state.isMulesoftUser && this.Button("btn-add", AddCircleOutlineIcon, configJSON.addNewAddress, this.handlePressAdd)}
              </Box>
              <Box style={{ ...styles.selectHeader, ...styles.flexBox, justifyContent: "space-between" }}>
                <Typography style={{ ...styles.title, fontSize: 16, lineHeight: "24px" }}>{configJSON.selectAddress}</Typography>
                <ExpandMoreIcon />
              </Box>
              <Box style={{ ...styles.selectHeader, marginTop: 10, padding: 0, marginBottom: 70 }}>
                {this.state.listAddress.map((address: any) => this.AddressItem(address)
                )}
              </Box>
              {this.Button("btn-review-order", ShoppingCartOutlinedIcon, configJSON.reviewOrder, this.handleClickReviewOrder)}
            </Box>
          </Container>

        </Container>
        <AddAddress
          id="add-address"
          navigation={this.props.navigation}
          openDialog={this.state.openAddAddress}
          handleCloseDialog={this.handlePressAdd}
          loadAddresses={this.loadAddresses} />
        <FooterWeb />
      </ThemeProvider>
      
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Arial, sans-serif"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Arial, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Arial, sans-serif"
      }
    }
  }
})

const styles = {
  contentContainer: { 
    backgroundColor: "#F7F7F7",
    minHeight: "100vh", 
    height: "auto", 
    padding: "50px 30px" 
  },
  title: {
    fontWeight: 700, 
    fontSize: 30, 
    lineHeight: "40px",
    color: "#0F172A"
  },
  content: {
    margin: "70px auto"
  },
  flexBox: {
    display: "flex",
    alignItems: "center"
  },
  selectHeader: {
    padding: 15,
    boxShadow: "rgba(162, 158, 158, 0.4) 0px 3px 3px 0px",
    backgroundColor: "#FFF",
    marginTop: 20,
    borderRadius: 8
  },
}
// Customizable Area End
