// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {}

interface SS {
  id: any;
}

export default class OrderErrorController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  constructor(props: Props) {
    super(props);
  }

  goHome = () => {
    this.props.navigation.navigate("Catalogue")
  }
}

// Customizable Area End