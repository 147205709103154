
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Ingresa Texto";
exports.labelTitleText = "Título del Producto";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.addToCartEndpoint = "bx_block_shopping_cart/shopping_cart"
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.authToken = "authToken"
exports.post = "POST"
exports.successMsg = "Artículo agregado al carrito exitosamente";
// Customizable Area End
