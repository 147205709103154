// Customizable Area Start
import React from "react";

import { Box, Typography, styled } from "@material-ui/core";
const config = require("./config");

import EmailConfirmationController, {
  Props,
} from "./EmailConfirmationController";
import ForgotPasswordUIContainer from "../../forgot-password/src/ForgotPasswordUIContainer";

export default class EmailConfirmation extends EmailConfirmationController {
  constructor(props: Props) {
    super(props);
  }

  errorToast = () => {
    if (!this.state.errorMessage && !this.state.successMessage) {
      return null;
    }

    let content: any;
    let mofifyClass: any;
    if (this.state.errorMessage) {
      content = this.state.errorMessage;
      mofifyClass = "error";
    } else {
      content = this.state.successMessage;
      mofifyClass = "success";
    }

    return (
      <Box sx={{ width: "100%" }} className={`msgBg ${mofifyClass}`}>
        <Box className={`msgPipe ${mofifyClass}`}></Box>
        <Typography
          data-test="error-msg"
          className={`msgText ${mofifyClass}`}
          style={{ marginTop: 0, padding: "12px 16px" }}
        >
          {content}
        </Typography>
      </Box>
    );
  };
  render() {
    return (
      <ForgotPasswordUIContainer>
        <EmailConfirmationContainer>
          <Box className="wrapper">
            <Typography className="labelTitle">{config.title3}</Typography>

            {this.errorToast()}

            {!this.state.isLoading && !this.state.errorMessage && (
              <Typography className="subTitle3">{config.subTitle3}</Typography>
            )}

            <Box className="links">
              {!this.state.isLoading && !this.state.isActivated && (
                <Box sx={{ display: "flex" }}>
                  <Typography
                    onClick={this.resendLink}
                    className="normalText"
                    style={{
                      cursor: "pointer",
                      fontWeight: 700,
                      color: config.colorPrimary,
                    }}
                    data-test="resend-email"
                  >
                    {config.resendEmail}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex" }}>
                <Typography className="normalText" style={{ marginRight: 10 }}>
                  {config.backTo}
                </Typography>
                <Typography
                  className="normalText"
                  data-test="go-to-login"
                  onClick={this.gotoLogIn}
                  style={{
                    fontWeight: 700,
                    color: config.colorPrimary,
                    cursor: "pointer",
                  }}
                >
                  {config.logIn}
                </Typography>
              </Box>
            </Box>
          </Box>
        </EmailConfirmationContainer>
      </ForgotPasswordUIContainer>
    );
  }
}

const EmailConfirmationContainer = styled(Box)(({ theme }) => ({
  "& .wrapper": {
    marginTop: 120,
    position: "relative",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
    padding: "10px 0px",
    width: "50%",

    [theme.breakpoints.down(960)]: {
      top: 0,
      marginBottom: 20,
      transform: "none",
    },
  },
  "& .normalText": {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "22px",
  },
  "& .labelTitle": {
    fontSize: 24,
    marginBottom: 40,
    lineHeight: "32px",
    fontWeight: 700,
  },
  "& .subTitle3": {
    marginTop: 8,
    alignSelf: "start",
    color: "#0F172A",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "26px" /* 144.444% */,
    marginBottom: 40,
  },
  "& .links": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20,
  },
  "& .msgPipe": {
    position: "absolute",
    width: 5,
    height: "100%",
    borderRadius: "8px 0 0 8px",
    "&.error": {
      background: config.colorError,
    },
    "&.success": {
      background: config.colorPrimary,
    },
  },
  "& .msgBg": {
    "&.error": {
      backgroundColor: "#FEE2E2",
    },
    borderRadius: 8,
    marginTop: 10,
    position: "relative",
    "&.success": {
      backgroundColor: "#E2E5FE",
    },
  },
  "& .msgText": {
    "&.error": {
      color: config.colorError,
    },
    marginTop: 5,
    fontSize: 12,
    "&.success": {
      color: config.colorPrimary,
    },
  },
}));
// Customizable Area End
