import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const initData = {
  id: "",
  type: "",
  attributes: {
    first_name: "",
    last_name: "",
    building_number: 0,
    address: "",
    street: "",
    city: "",
    country: "",
    zip_code: "",
    is_default: false,
    mulesoft_address: "",
    mulesoft_id: ""
  }
}
export interface AddressDataType {
  first_name: string,
  last_name: string,
  building_number: number,
  address: string,
  street: string,
  city: string,
  country: string,
  zip_code: string,
  is_default: boolean
  mulesoft_address: string,
  mulesoft_id: string
}

export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  listAddress: Array<AddressType>,
  addressSelected: AddressType
  openAddAddress: boolean
  openSuccess: boolean
  isLoading: boolean,
  isMulesoftUser: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddressManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAllAddressCallId: string = "";
  apiSetAsDefaultId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      listAddress: [],
      addressSelected: initData,
      openAddAddress: false,
      openSuccess: false,
      isLoading: true,
      isMulesoftUser: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.loadAddresses()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.apiGetAllAddressCallId) {
        this.handleGetAddressResponse(responseJson)
      }

      if(apiRequestCallId === this.apiSetAsDefaultId) {
        if(responseJson.data) {
          this.loadAddresses()
        } else {
          this.handleValidateToken(responseJson.errors)
        }
      }
     
    } 
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetAddressResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const {data} = responseJson;
      const defaultAddress = data.find((item: AddressType) => item.attributes.is_default);
      let {addressSelected} = this.state
      if(defaultAddress) addressSelected = defaultAddress
      this.checkMulesoftUser(data)

      this.setState({ listAddress: data, addressSelected, isLoading: false });
    } else {
      this.handleValidateToken(responseJson.errors || responseJson.error);
    }
  }
  checkMulesoftUser = (data: any) => {
    let muleSoftUser = data.find((item: any) =>  !!item.attributes.mulesoft_id )
    this.setState({isMulesoftUser: muleSoftUser})
  }
  handleValidateToken = (errors: any) => {
    let listErrors = {};
    if (Array.isArray(errors)) {
      errors.forEach((item: any) => {
        listErrors = { ...listErrors, ...item }
      })
      if ('token' in listErrors) this.props.navigation.navigate("EmailAccountLoginBlock");
    } else if (errors.toLowerCase().includes('no address')) {
      this.setState({ isLoading: false, isMulesoftUser: false });
    }
  }

  callApi = async (params: any) => {
    const token = await this.getToken();
    const {contentType, body, method, endPoint} = params
    const header = {
      "Content-Type": contentType ?? configJSON.getAddressApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  };

  loadAddresses = async () => {
    this.apiGetAllAddressCallId = await this.callApi({
      contentType: configJSON.getAddressApiContentType,
      endPoint: configJSON.getAddressApiEndPoint,
      method: configJSON.getAddressApiMethod
    })};

  getToken = async () => {
    const token = await getStorageData("authToken")
    this.setState({token: token})
    return token;
  };

  handlePressAdd = () => {
    this.setState({openAddAddress: !this.state.openAddAddress})
  };

  handleClickReviewOrder = () => {
    this.props.navigation.navigate("OrderReview");
  }

  handleSelectAddress = (address: AddressType) => {
    this.setState({addressSelected: address})
    this.handleSetAsDefault(address.id)
  }

  handleSetAsDefault = async (id: string) => {
    this.apiSetAsDefaultId = await this.callApi({
      contentType: configJSON.getAddressApiContentType,
      body: JSON.stringify({is_default: true}),
      endPoint: `${configJSON.getAddressApiEndPoint}/${id}`,
      method: configJSON.updateAddressApiMethod
    })
  };
  // Customizable Area End
}
